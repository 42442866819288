import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { decryptResponse } from '../../utils/encryptionDecryption.js';
import TableSkeletonLoader from './components/tableSkeletonLoader.js';
import { GET_FAVOURITE_SUPPLIER_LIST } from '../../services/user.services.js';
import UndoList from './components/undoBlackList.js';
import { LStorage } from '../../utils/localStorage.Helper.js';
import { ReactComponent as RIghtArrow } from '../../assests/right.svg';
import { ReactComponent as FavMaterial } from "./assets/favorite.svg";
import DetailModal from './components/detailModal.js';
import STRINGS from '../../helpers/staticContentHelper.js';
import { useSearchParams } from 'react-router-dom';
import NoDataFound from './components/noDataFound.js';

function FavoriteList({ type }) {
    const userData = LStorage.getUserData()
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [selectedCarddata, setSelectedCardData] = useState({});
    const [openUndoPopUp, setOpenUndoPopUp] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams()
    let favoriteId = searchParams.get('favId')

    const setSelectedLocationdata = (tableData) => {
        let currentHistory = tableData.find(history => history.favourite_id === favoriteId);
        setIsDetailsModalOpen(prev => true)
        setSelectedCardData(currentHistory)
    }

    const getListOfHistroty = async () => {
        try {
            setIsLoading(true)
            let res = await GET_FAVOURITE_SUPPLIER_LIST(!userData.org_id ? 'individual' : type);
            const parsedResponse = JSON.parse(decryptResponse(res))
            setIsLoading(false)
            setTableData(prev => {
                if (favoriteId) {
                    setSelectedLocationdata(parsedResponse)
                }
                return parsedResponse
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getListOfHistroty()
    }, [type])

    useEffect(() => {
        if (tableData.length > 0 && favoriteId) {
            setSelectedLocationdata(tableData)
        }
        console.log(favoriteId, 'favoriteId__')
    }, [favoriteId])

    const columns = [
        {
            id: 'created_at', label: 'DATE', align: 'start', minWidth: "110px", format: (value, row) => {
                return <span style={{ fontSize: '14px', fontWeight: "500", fontFamily: 'Plus Jakarta Sans' }}>{moment(value).format('DD/MM/YYYY')}</span>
            }
        },
        {
            id: 'supplier_name', label: 'SUPPLIER NAME', align: 'start', minWidth: "110px", format: (value, row) => {
                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div className='material-search-address-table-view'>
                            <p>{value?.substring(0, 20)} {value?.length > 20 && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'address', label: 'ADDRESS', align: 'start', format: (value, row) => {
                let destination = row?.destination_details?.formatted_address;
                let supplier = row?.supplier_address;
                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div className='material-search-address-table-view'>
                            <span>Supplier:</span>
                            <p>{supplier?.substring(0, 25)} {supplier?.length > 25 && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'Vendor_marked_favorite_by', label: 'MARKED BY', align: 'start', format: (value, row) => {
                return (
                    <div className='material-search-address-table-view'>
                        <p>{row.user.email}</p>
                    </div>
                )
            }
        },
        {
            id: 'materials', label: 'MATERIALS', align: 'start',
            format: (value, row) => {
                const stringToRender = row?.materials?.slice(0, 2).join(", ");
                let remainingFilters = row?.materials?.length;

                if (stringToRender?.length > 22) {
                    remainingFilters++
                }

                if (row.favourite_facility) {
                    return (
                        <div style={{ display: 'flex', flexDirection: "row", gap: "0.5rem" }}>
                            <div className='material-search-address-table-view'>
                                <p>All materials</p>
                            </div>
                        </div>
                    )
                }

                return (
                    <div style={{ display: 'flex', flexDirection: "row", gap: "0.5rem" }}>
                        <div className='material-search-address-table-view'>
                            <p>{stringToRender?.substring(0, 22)} {(stringToRender?.length > 22 || remainingFilters > 2) && '...'}</p>
                        </div>
                        {remainingFilters > 2 &&
                            <div className='materials-more-chip'>+{remainingFilters - 2}</div>
                        }
                    </div>
                )
            }
        },
        {
            id: 'action', label: '', align: 'start',
            format: (value, row) => {
                return <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '1.2rem' }}>
                    {(userData.email === row.user.email || userData?.role === 'admin') &&
                        <div style={{ textDecoration: "underline", color: "#006D6B" }} onClick={(e) => {
                            e.stopPropagation()
                            setOpenUndoPopUp(true)
                            setSelectedCardData(row)
                        }}>
                            <a>{STRINGS.EDIT}</a>
                        </div>
                    }
                    <div className='location-card-options'
                        style={{ backgroundColor: "#ABF5D180", display: "inline-flex" }} >
                        <FavMaterial />
                    </div>
                    <RIghtArrow />
                </div>
            }
        },
    ];

    return (
        <>
            <DetailModal type={type} setIsModalOpen={setIsDetailsModalOpen} isModalOpen={isDetailsModalOpen} carddata={selectedCarddata} />
            {openUndoPopUp && <UndoList type={type} fetchList={getListOfHistroty} open={openUndoPopUp} setOpen={setOpenUndoPopUp} cardData={selectedCarddata} />}
            {isLoading ? <TableSkeletonLoader /> :
                tableData.length === 0 ? <NoDataFound
                    title={STRINGS.NO_FAVORITES_YET}
                    subTitle={STRINGS.NO_FAVORITES_YET_SUB_TITLE}
                /> :
                    <TableContainer>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                padding: "0.4rem 0.1rem",
                                                color: '#6D6D6D',
                                                fontFamily: 'Plus Jakarta Sans'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData.length > 0 && tableData.map((row) => {
                                    return (
                                        <TableRow hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={row.code}
                                            style={{ cursor: "pointer", height: "62px" }}
                                            onClick={() => {
                                                setIsDetailsModalOpen(prev => !prev)
                                                setSelectedCardData(row)
                                            }}
                                        >
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}
                                                        sx={{
                                                            position: 'relative', padding: '0.5rem 0.1px',
                                                            paddingRight: '1rem',
                                                            fontFamily: 'Plus Jakarta Sans',
                                                            maxWidth: column.maxWidth && column.maxWidth
                                                        }}>
                                                        {column.format
                                                            ? column.format(value, row)
                                                            : value}

                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
        </>
    )
}

export default FavoriteList

