import React, { useEffect, useState } from 'react';
import STRINGS from '../../../helpers/staticContentHelper';
import scannerImg from '../../../assests/sonarWebImages/scanner.svg';
import searchThroughImg from '../../../assests/sonarWebImages/searchThrough.svg';
import dataBaseImg from '../../../assests/sonarWebImages/dataBase.svg';
import ReactPlayer from 'react-player/vimeo';
const OPTIONS_DATA = [
    {
        title: STRINGS.UPLOAD_OR_SYNC_YOUR_FILES,
        number: 0,
        buttonName: STRINGS.UPLOAD_OR_SYNC_YOUR_FILES_BUTTON_TEXT
    },
    {
        title: STRINGS.ASK_QUESTIONS_TO_SONAR,
        number: 1,
        buttonName: STRINGS.ASK_QUESTIONS_TO_SONAR_BUTTON_TEXT
    },
    {
        title: STRINGS.FAST_AND_SECURE_SCANNING,
        number: 2,
        buttonName: STRINGS.FAST_AND_SECURE_SCANNING_BUTTON_TEXT
    },
    {
        title: STRINGS.PRECISE_RESULTS_AND_CREATED_SOURCES,
        number: 3,
        buttonName: STRINGS.PRECISE_RESULTS_AND_CREATED_SOURCES_BUTTON_TEXT
    },
]

const SOLUTION_FOOTER_DATA = [
    {
        img: scannerImg,
        title: STRINGS.SCANNED_IMAGES_AND_DOCUMENTS
    },
    {
        img: searchThroughImg,
        title: STRINGS.CONSTRUCTION_DRAWINGS_AND_DETAILS
    },
    {
        img: dataBaseImg,
        title: STRINGS.INFORMATION_HIDDEN_WITHIN_COMPLEX_FILES
    },
]
const VIDEO_LINKS = ['https://vimeo.com/1006673850', 'https://vimeo.com/1006674896', 'https://vimeo.com/1006675495', 'https://vimeo.com/1006676164']

function SolutionSection({ posthog }) {
    const [activeTab, setActiveTab] = useState(0);

    const handleVideoEnd = () => {
        setActiveTab(prev => prev === 3 ? 0 : prev + 1)
    };
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

    const config = {
        file: {
            forceHLS: !isSafari,
            forceVideo: true,
            hlsVersion: '0.12.4',
        }
    }

    return (
        <div className='web-workflow-section-full-width' id='feature_section'>
            <div className='web-solution-section-container'>
                <p className='web-section-sub-title' style={{ color: 'white' }}>{STRINGS.SOLUTION}</p>
                <h3 className='web-section-title' style={{ color: 'white', margin: '0.8rem 0 0 0' }}>
                    {STRINGS.SOLVING_CONSTRUCTION_SEARCH_DILEMMA}
                </h3>
                <p className='web-solution-description' >
                    {STRINGS.WITH_SONAR_RECALL_LOST_HOURS}
                </p>

                <div className='web-solution-content-component'>

                    <div className='select-active-solution' >
                        {OPTIONS_DATA.map(item => {
                            return (
                                <div onClick={() => {
                                    posthog.capture('solution_section_clicked_' + item.buttonName)
                                    setActiveTab(item.number)
                                }} className={`select-solution-option ${activeTab === item.number && 'select-solution-option-active'
                                    }`}>
                                    <div className='active-rounded-status-active'>
                                        {item.number + 1}
                                    </div>
                                    <span >{item.title}</span>
                                </div>
                            )
                        })}
                    </div>

                    <div className='selected-solution selected-solution-image-mobile'>
                        <ReactPlayer
                            url={VIDEO_LINKS[activeTab]}
                            className="react-player"
                            playing={true}   // Autoplay the video
                            controls={false} // Hide controls
                            muted={true}     // Mute the video to allow autoplay
                            autoplay={true}
                            playsinline={true}
                            onEnded={handleVideoEnd}
                            width='100%'
                            config={config}
                        />
                    </div>

                </div>

                <div className='web-solution-content-component solution-card-footer-info-main' style={{ padding: '0' }}>

                    <div className='select-active-solution footer-select-active-solution' >
                        <p className='select-active-solution-control-f'>
                            {STRINGS.NO_MORE} <span style={{ fontWeight: '300', fontStyle: 'italic' }}> Ctrl+F,</span> <br />
                            {STRINGS.SONAR_CAN_SEARCH_THROUGH}
                        </p>
                    </div>

                    <div className='selected-solution solution-card-footer-info-container'>
                        {SOLUTION_FOOTER_DATA.map(footerData => {
                            return (
                                <div className='solution-control-f-card'>
                                    <img src={footerData.img} alt='no' />
                                    <p className='solution-control-f-card-info'>{footerData.title}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>

            </div>
        </div>
    )
}

export default SolutionSection