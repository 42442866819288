import React, { useState } from 'react';
import '../project/fileUpload.css'
import uploadFile from '../../assests/uploadFile.svg'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import pdfImg from '../../assests/pdfIcon.svg'
import modalCross from '../../assests/modalCrossWhite.svg'
import modalCrossBlack from '../../assests/modalCross.svg'
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from "../../assests/editIcon.svg"
import TextField from '@material-ui/core/TextField';
import IndexingFileModal from '../projectDetails/fileIndexingModal';
import STRINGS from '../../helpers/staticContentHelper';
import { ADD_PROJECT } from '../../services/index'
import { encryptPayload } from '../../utils/encryptionDecryption';
import Modal from '@mui/material/Modal';
import OutlinedInput from '@mui/material/OutlinedInput';
import { baseTypography, buttonStyle, popUpStyle, underLinePrimaryTextStyle } from './components';

export default function AddFIlesToProjects({ open, setOpen, fetchProjectsList }) {
    const drop = React.useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [sizeLimit, setSizeLimit] = useState(false)
    const [projectName, setProjectName] = useState('');
    const [activeStep, setActiveStep] = useState('createProject');
    const [isEditable, setIsEditable] = useState(false);
    const [creatingProject, setCreatingProject] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [editedFileName, setEditedFileName] = useState([]);

    const handleClose = () => {
        setActiveStep('createProject');
        setUploadedFiles([])
        setProjectName('');
        setOpen(false)
    };

    const onUpload = (files) => {
        let totalFileSize = 0
        const numberOfFiles = [...uploadedFiles, ...files]
        if (numberOfFiles.length > 5) {
            setSizeLimit(false)
            setIsModalOpen(true)
        }
        setUploadedFiles(numberOfFiles.slice(0, 5))
        numberOfFiles.forEach(file => {
            totalFileSize += file?.size
            if (totalFileSize > 1024 * 1024 * 1024) {
                setSizeLimit(true)
                setIsModalOpen(true)
                setUploadedFiles([])
            }
        })
    };

    const addProject = (skip = false) => {
        setCreatingProject(true);
        const encryptedFileNames = []
        const encryptedDirs = []
        const filesList = []
        uploadedFiles?.forEach((file, index) => {
            const updatedFileName = editedFileName[index] ? editedFileName[index] : file?.name
            const fileName = encryptPayload(updatedFileName, true)
            encryptedFileNames.push(fileName)
            const encryptedDir = encryptPayload('temp', true)
            encryptedDirs.push(encryptedDir)
            filesList.push(file)
        })

        const formdata = new FormData();

        if (!skip) {
            formdata.append('file_type', 'pdf')
            formdata.append('dirs', JSON.stringify(encryptedDirs));
            formdata.append('file_names', JSON.stringify(encryptedFileNames));

            uploadedFiles.forEach(file => {
                formdata.append('files', file);
            });
        }

        const encryptedProjectName = encryptPayload(projectName, true)
        formdata.append('project_name', encryptedProjectName);

        ADD_PROJECT(formdata).then(res => {
            fetchProjectsList();
            setProjectName('')
            handleClose()
            setCreatingProject(false)
        })
    }

    const fileInputRef = React.useRef(null);
    const [selectedFileIndex, setSelectedFileIndex] = React.useState(0);

    const handleCancel = (index) => {
        fileInputRef.current.value = null;
    }

    const handleEditClick = (index) => {
        setSelectedFileIndex(index)
        const newEditList = [...editedFileName]
        uploadedFiles?.forEach((file, index) => {
            const fileToEdit = editedFileName[index] ? editedFileName[index] : file?.name
            newEditList[index] = fileToEdit
        })
        setEditedFileName(newEditList);
        setIsEditable(true);
    };

    const handleChange = (event, index) => {
        const newEditList = [...editedFileName]
        newEditList[index] = event.target.value
        setEditedFileName(newEditList);
    };
    React.useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);
    });

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer;

        if (files && files.length) {
            onUpload(files);
        }
    };

    const truncateFileName = (fileName) => {
        if (fileName.length > 25) {
            return fileName.slice(0, 25) + '...'
        }
        return fileName
    }

    const handleFileSelect = (event) => {
        const files = event.target.files;
        onUpload(files);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={popUpStyle}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCrossBlack} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={handleClose} />
                </Box>
                <div ref={drop} className='FilesDragAndDrop' style={{ display: 'flex', flexDirection: 'column', paddingBottom: "0px" }}>
                    <IndexingFileModal open={isModalOpen}
                        setOpen={setIsModalOpen}
                        title={STRINGS.UPLOAD_LIMIT_REACHED}
                        description={sizeLimit ? STRINGS.FILE_SIZE_LIMIT_REACHED : STRINGS.YOU_CAN_UPLOAD_MAXIMUM_5_FILES} />

                    {activeStep === 'createProject' &&
                        <>
                            <Typography id="modal-modal-title" variant="h6" component="h2"
                                sx={{ textAlign: 'center', ...baseTypography }}>
                                {STRINGS.CREATE_PROJECT}
                            </Typography>
                            <Typography id="modal-modal-description" sx={{
                                textAlign: 'center',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#6D6D6D',
                                ...baseTypography
                            }}>
                                {STRINGS.ADD_FILES_TO_PROJECT}
                            </Typography>
                            <OutlinedInput size='small'
                                placeholder="Enter Project Name"
                                fullWidth
                                required
                                sx={{
                                    borderRadius: '0.5rem',
                                    mt: 3,
                                    ...baseTypography
                                }}
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                            />
                        </>
                    }

                    {activeStep === 'uploadFiles' &&
                        <div className='FilesDragAndDrop__area'>
                            <input type="image" src={uploadFile} alt='fileUpload' height='80px' htmlFor="fileInput"
                                onClick={(e) => {
                                    e.preventDefault();
                                    document.getElementById("fileInput").click();
                                }} />
                            <p style={{ ...baseTypography }}>Drag and drop your files or <span />
                                <label htmlFor="fileInput" style={{
                                    color: "#006D6B", cursor: "pointer",
                                    ...baseTypography, textDecoration: "underline"
                                }}>
                                    {STRINGS.BROWSE_FILES}
                                </label>
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleFileSelect}
                                    ref={fileInputRef}
                                    multiple
                                />
                            </p>
                        </div>
                    }
                    {/* files list  */}
                    {activeStep === 'uploadFiles' &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            flexDirection: 'column',
                            gap: '0.1rem',
                            backgroundColor: "#DBDEE0",
                            maxHeight: '200px',
                            overflow: 'scroll',
                            marginTop: '1rem'
                        }}>
                            {uploadedFiles?.length > 0 && uploadedFiles?.map((uploadedFile, index) => {
                                if (isEditable && selectedFileIndex !== index) return <></>
                                return (
                                    <Box sx={{
                                        p: 1, display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        backgroundColor: "white",
                                        width: '100%',
                                    }} fullWidth >
                                        <Box sx={{ display: 'flex', gap: '0.8rem', px: 2, overflow: 'hidden' }}>
                                            <img src={pdfImg} alt='imagenofound' />
                                            {isEditable ?
                                                <>
                                                    <TextField
                                                        value={editedFileName[index]}
                                                        onChange={(event) => handleChange(event, index)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: { width: '190px', fontSize: '14px', padding: '1rem' }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <Typography id="modal-modal-description" sx={{
                                                    textAlign: 'center',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#6D6D6D'
                                                }}>
                                                    {truncateFileName(editedFileName[index] ? editedFileName[index] : uploadedFile?.name)}
                                                </Typography>
                                            }
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', px: 1, gap: '0.9rem' }}>
                                            {!isEditable ? (
                                                <>
                                                    <img src={EditIcon} alt='editIcon'
                                                        style={{
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() => handleEditClick(index)} />

                                                    <img src={modalCross} alt='crossIcon'
                                                        style={{
                                                            backgroundColor: "#006D6B",
                                                            padding: '0.5rem',
                                                            borderRadius: '1rem',
                                                            cursor: "pointer",
                                                        }} onClick={() => {
                                                            handleCancel()
                                                            setIsEditable(false)

                                                            const uploadedFilesList = uploadedFiles?.filter((file, idx) => {
                                                                return idx !== index
                                                            })
                                                            const editedFileNameList = editedFileName?.filter((file, idx) => {
                                                                return idx !== index
                                                            })

                                                            setUploadedFiles(uploadedFilesList)

                                                            setEditedFileName(editedFileNameList)
                                                        }
                                                        } />
                                                </>
                                            )
                                                :
                                                (<>
                                                    <Typography id="modal-modal-description" sx={{
                                                        textAlign: 'center',
                                                        fontSize: '15px',
                                                        paddingTop: '0.1rem',
                                                        fontWeight: 600,
                                                        color: '#006D6B',
                                                        cursor: 'pointer',
                                                        ...baseTypography

                                                    }} onClick={() => setIsEditable(false)}>
                                                        {STRINGS.SAVE}
                                                    </Typography>
                                                    <img src={modalCross} alt='crossIcon'
                                                        style={{
                                                            backgroundColor: "#006D6B",
                                                            padding: '0.5rem',
                                                            borderRadius: '1rem',
                                                            cursor: "pointer",
                                                        }} onClick={() => {
                                                            const newEditList = [...editedFileName]
                                                            newEditList[index] = uploadedFile?.name
                                                            setEditedFileName(newEditList);
                                                            setIsEditable(false)
                                                        }
                                                        } />
                                                </>
                                                )
                                            }
                                        </Box>
                                    </Box>
                                )
                            })
                            }
                            {isFileUploading && <Box sx={{ width: '100%' }}>
                                <LinearProgress color="success" />
                            </Box>}
                        </Box>
                    }
                    {activeStep !== 'uploadFiles' && <Button type='submit' variant="outlined" size="large" fullWidth
                        sx={{ ...buttonStyle, ...baseTypography, backgroundColor: !projectName ? 'white' : '#1B2E31', color: "white", mt: 2, mb: 2 }}
                        onClick={() => {
                            // if (!projectName) {
                            //     alert('project name is required')
                            //     return
                            // }
                            // setActiveStep('uploadFiles')
                            addProject(true)
                        }}
                        disabled={!projectName || creatingProject}
                    >
                        {STRINGS.CREATE_PROJECT}
                    </Button>}

                    {/* {!isFileUploading && !isEditable && activeStep === 'uploadFiles' &&
                        <Button variant="outlined" size="large" fullWidth
                            sx={{
                                ...buttonStyle, ...baseTypography,
                                backgroundColor: uploadedFiles.length === 0 ? 'white' : '#1B2E31',
                                color: "white", mt: 2
                            }}
                            onClick={addProject}
                            disabled={uploadedFiles.length === 0}
                        >
                            {STRINGS.CREATE_PROJECT}
                        </Button>
                    } */}

                    {activeStep === 'uploadFiles' &&
                        <Box sx={{ display: 'flex', justifyContent: "space-between", alignItems: "center", mt: 2, ...baseTypography }}>
                            <p style={underLinePrimaryTextStyle} onClick={() => {
                                setActiveStep('createProject')
                            }}>{STRINGS.BACK}</p>
                            <p style={underLinePrimaryTextStyle}
                                onClick={() => {
                                    addProject(true)
                                }}>{STRINGS.UPLOAD_FILES_LATER}</p>
                        </Box>
                    }
                </div>
            </Box>
        </Modal>
    );
}
