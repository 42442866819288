import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import STRINGS from '../../helpers/staticContentHelper'
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { DELETE_FILE } from '../../services';
import CircularProgress from '@mui/material/CircularProgress';

function DeleteModal({ setOpenDeleteModal, openDeleteModal, rowData, fetchList, celeryStatus }) {

    const [isLoading, setIsLoading] = React.useState(false)

    const handleClose = (e) => {
        setOpenDeleteModal(false);
    }


    const deleteOption = (e) => {
        setIsLoading(true)
        const reqData = {
            file_id: rowData?.file_id
        }
        DELETE_FILE(reqData).then(res => {
            if (res?.message) {
                celeryStatus()
                fetchList()
                setOpenDeleteModal(false);
            }
            setIsLoading(false)
        })
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 420,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        px: 4,
        borderRadius: '0.5rem'
    };


    const buttonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
            background: '#FF0000',
            opacity: '0.9',
            color: "white",
        },

    };
    const cancelButtonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
        },

    };

    return (
        <Modal
            open={openDeleteModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setOpenDeleteModal(false)} />
                </Box>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '24px',
                    fontWeight: 400,
                    p: 1
                }}>
                    Are you sure?
                </Typography>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 400,
                    color: '#6D6D6D',
                    pb: 1
                }}>
                    <span >You want to delete </span>
                    <span style={{ fontWeight: 600 }}>{rowData?.file_name}.</span>
                    <span >This action cannot be undone.</span>

                </Typography>

                {isLoading ?
                    <CircularProgress color="success" sx={{ color: '#124429', margin: 'auto', display: 'flex', marginTop: '0.2rem' }} />
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'space-around', my: 2 }}>
                        <Button
                            type='submit'
                            variant="outlined"
                            size="large"
                            sx={{ ...cancelButtonStyle, backgroundColor: 'white', color: "black", width: "180px" }}
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            type='submit'
                            variant="outlined"
                            size="large"
                            sx={{ ...buttonStyle, backgroundColor: '#FF2400', color: "white", width: "180px" }}
                            onClick={deleteOption}
                        >
                            Delete
                        </Button>
                    </Box>

                }
            </Box>
        </Modal>
    )
}

export default DeleteModal
