export const SHIPPING_POLICY = `
<style>
    .subTitleStyle {
        font-size: 1rem;
        margin-top: 0rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
    }

    .titleStyle {
        font-size: 20px;
        margin-top: 1rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
        margin-bottom: 1.2rem;
        color: black;
    }

    span {
        font-weight: 600;
        color: black;
        font-family: 'Satoshi';
    }

    a{
    font-weight: 600;
    color: 'black';
    text-direction: underline;
    cursor: pointer;
          font-family: 'Satoshi';
    }

    ul{
        padding-left:0.8rem;
        margin:0;
        margin-bottom:1rem;
        font-family: 'Satoshi';
    }
</style>

<p class='subTitleStyle' style='margin-top:4rem'>
Sonar Labs provides digital services and software solutions for the construction industry.
 As a software company, we do not sell or ship any physical products. All products and services are delivered electronically, 
and access to our platform is provided through secure online accounts.
</p>

<h5 class="titleStyle">Digital Delivery Only</h5>

<p class='subTitleStyle'>
  - Sonar Labs exclusively offers digital products and services. There is no physical shipment of goods or products associated with our services.<br/>
   - Upon successful subscription or purchase, users will receive an email confirmation, and access to our platform will be provided through their registered account.
</p>

<h5 class="titleStyle"> Account Activation</h5>


<p class='subTitleStyle'>
   - Once payment is processed and confirmed, access to Sonar Labs services will be activated immediately or within 24 hours, depending on the type of service purchased.<br/>
   - New users will receive an email with login instructions and details for accessing the Sonar Labs platform.
</p>

<h5 class="titleStyle">Refund and Cancellation Policy</h5>

<p class='subTitleStyle'>
   - Since we do not ship physical products, there is no shipping-related refund or return policy. <br/>
   - For information on our cancellation and refund policies regarding digital subscriptions, please refer to our Cancellation and Refund Policy 
  <a href=${window.location.origin+"/refund-and-cancellation-policy"} target='__blank' >here</a>.
</p>

<h5 class="titleStyle"> Contact Us  </h5>

<p class='subTitleStyle'>
If you have any questions about our Cancellation and Refund Policy, please contact us:
</p>

<p class='subTitleStyle'>
Email: support@sonarlabs.ai <br/>
Phone: +16505594914  <br/> <br/>
Address:<br/>
#4 Bellary Road,   <br/>
3rd Floor Vaibhava Center,  <br/>
Bangalore 560080 India.  
</p>

<p class='subTitleStyle' style='margin-bottom:4rem'>
Thank you for choosing Sonar Labs! We appreciate your business and are here to support you.
</p>
`