import React, { useEffect, useState } from "react";
import "./App.css";
import { CiSearch } from "react-icons/ci";
// import { AiOutlineEnter } from "react-icons/ai";
import { MdOutlineArrowOutward } from "react-icons/md";
import Typewriter from "./typewriter";
// import { BsArrowUpRightCircleFill } from "react-icons/bs";
import sonarAi from '../../assests/sonarAi.svg';
import send from '../../assests/send.svg';
import { ASK_QUESTIONS_AUTH, SUGGESTION_QUESTIONS_AUTH, HIGHLIGHTED_PDF } from "../../services/home.services";
import { useSearchParams } from 'react-router-dom';
import PdfViewer from "../../components/pdfViewer/PdfViewer";
import Header from '../../components/header'
import chevronLeft from '../../assests/chevronLeft.svg';
import { useNavigate } from "react-router-dom";
import { decryptResponse } from "../../utils/encryptionDecryption";
import STRINGS from '../../helpers/staticContentHelper'
const PERCENTAGE_COLORS = {
    PRIMARY: '#ABF5D1 ',
    SECONDARY: '#FFF0B3',
    WARNING: '#FFBDAD'
}

export default function Dashboard() {
    const fileUrl = localStorage.getItem('s3File')
    const [searchParams] = useSearchParams();
    const [searchValue, setSearchValue] = useState('');
    const [searchValueRender, setSearchValueRender] = useState(false);
    const [imageData, setImageData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [boundingBoxHighlight, setBoundingBoxHighlight] = useState([])
    const [currentPage, setCurrentPage] = useState(0);
    const [suggestions, setSuggestions] = useState([]);
    const [activePage, setActivePage] = useState({})
    const [pdfFileUrl, setPdfFileUrl] = useState('')
    const navigate = useNavigate()


    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            fetchQueryReq();
        }
    }

    useEffect(() => {
        if (searchParams.get('fileid')) {
            getSuggestions();
        }

    }, [searchParams.get('fileid')]) //eslint-disable-line

    const getSuggestions = () => {
        const reqQuery = {
            "file_url": fileUrl,
            "file_id": searchParams.get('fileid')
        }
        SUGGESTION_QUESTIONS_AUTH(reqQuery).then(res => {
            if (res?.questions) {
                const decryptedQuestions = decryptResponse(res.questions)
                const suggestionsList = JSON.parse(decryptedQuestions)
                if (suggestionsList?.length > 0) {
                    setSuggestions(suggestionsList)
                }

            }
        })
    }

    const fetchQueryReq = (suggestion = '') => {
        setIsLoading(true)
        setActivePage({})
        setCurrentPage(0)
        const reqData = {
            "user_question": suggestion ? suggestion : searchValue,
            "file_url": fileUrl,
            "file_id": searchParams.get('fileid')
        }
        ASK_QUESTIONS_AUTH(reqData).then(res => {

            if (res && res?.result_data) {
                const decryptedResults = decryptResponse(res?.result_data)
                const resultData = decryptedResults ? JSON.parse(decryptedResults) : null
                const decryptedResponse = decryptResponse(res.response)
                resultData?.sort((a, b) => b.match_percentage - a.match_percentage)
                fetchHighlightedPdf(resultData)

                const decryptedImageData = {
                    response: decryptedResponse,
                    result_data: resultData,
                }
                setImageData(decryptedImageData)
                setSearchValueRender(true); // when we get positive result making loading disabled 
            } else {
                if (res?.response) {
                    const decryptedImageData = {
                        response: res?.response,
                        result_data: [],
                    }
                    setImageData(decryptedImageData)
                }
            }
            getSuggestions();
            setIsLoading(false)
        })
    }

    const fetchHighlightedPdf = (textInstances) => {
        const reqData = {
            "file_url": fileUrl,
            "file_id": searchParams.get('fileid'),
            "text_instances": textInstances
        }
        HIGHLIGHTED_PDF(reqData).then(res => {
            if (res) {
                if (res?.response) {
                    const decryptedImageBuffer = decryptResponse(res?.response)
                    const fileUrl = `data:application/pdf;base64,${decryptedImageBuffer}`
                    let currentPageValue = 0;
                    setCurrentPage(prev => {
                        currentPageValue = prev
                    })
                    setPdfFileUrl(fileUrl)
                    setTimeout(() => {
                        setCurrentPage(currentPageValue)
                    }, 1000);
                }
            }
        })
    }
    // To convert into rpv boxes
    const convertToRPVBoxes = (boundingBox, pageIndex) => {

        const height = Math.abs(boundingBox?.bottom - boundingBox?.top)
        // const width = Math.abs(boundingBox.right - boundingBox.left)
        const width = 85
        const left = boundingBox?.left
        const top = boundingBox?.top
        // const right = boundingBox.right

        const top_percent = (top / 727) * 100
        let left_percent = (left / 654) * 100
        const leftBuffer = left_percent + width

        if (leftBuffer >= 95) {
            left_percent = 10
        }
        return {
            pageIndex: pageIndex - 1,
            height: height,
            width: width,
            left: left_percent,
            top: top_percent
        }
    }

    // To format bounding boxes
    const formatPdfBoundingBox = (resultData) => {
        const rpvFormat = resultData?.map(result => {
            const formattedBoundingBox = convertToRPVBoxes(result?.bounding_boxes[0], result?.page_num)
            return formattedBoundingBox
        })
        setBoundingBoxHighlight(rpvFormat)
    }

    const NewImageContainer = (imageData, index) => {
        return (
            <div className={`result-card ${activePage.index === index && 'active-card'}`}
                key={imageData?.page_num}
                onClick={() => {
                    setCurrentPage(() => imageData.page_num - 1)
                    setActivePage({ ...imageData, index })
                }}>
                <div className="result-status" >
                    <div className="match-status" style={{
                        backgroundColor: imageData?.match_percentage > 50 ?
                            PERCENTAGE_COLORS.PRIMARY :
                            imageData?.match_percentage > 30 ?
                                PERCENTAGE_COLORS.SECONDARY : PERCENTAGE_COLORS.WARNING
                    }}>
                        Match - {imageData?.match_percentage}%
                    </div>
                    <span className="page-number">Page - {imageData?.page_num}</span>
                </div>
                {/* <p style={{ lineHeight: '1.5rem', fontSize: '0.9rem' }}>
                    Sed do eiusmod tempor ncididunt ut labore et
                    <strong>Ut enim ad minim veniam</strong>,
                    quis ate esse cillum dolore eu fugiat
                </p> */}
                <img src={`data:image/png;base64,${imageData.image}`} alt="not-found" />
            </div>
        )
    }

    return (
        <>
            <Header />
            <div className="mob-alert">Our demo is currently being optimized for phones and and tablets</div>
            <div className="dashboard-main">
                <div className="statisticsDiv">
                    <img src={chevronLeft}
                        alt='noImageFound'
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            cursor: "pointer",
                            paddingLeft: "10px",
                            paddingTop: "10px",
                            height: '25px',
                            zIndex: 1
                        }}
                        onClick={() => navigate(-1)}
                    />
                    <PdfViewer boundingBoxHighlight={boundingBoxHighlight} fileUrl={pdfFileUrl ? pdfFileUrl : fileUrl} pageNumber={currentPage} />
                </div>
                <div className="simulationDiv">
                    <div className="search-conteiner">

                        {searchValueRender && imageData?.result_data &&
                            imageData?.result_data.length === 0 &&
                            <>
                                <div className="result-images" style={{
                                    width: "96%",
                                    minWidth: '96%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column'
                                }}>
                                    <div>
                                        <p style={{ lineHeight: '1rem', textAlign: 'center', color: "gray" }}>
                                            {STRINGS.COULD_NOT_FOUND_ANY_INFORMATION}
                                        </p>
                                        <p style={{ lineHeight: '0px', textAlign: "center", color: "gray" }}>
                                            {STRINGS.WOULD_YOU_LIKE_TO_ASK_ANOTHER_QUESTION}
                                        </p>
                                    </div>
                                </div>
                            </>
                        }

                        {!searchValueRender
                            &&
                            <>
                                {isLoading ?
                                    <div class="loading-dots">
                                        <div class="dot"></div>
                                        <div class="dot"></div>
                                        <div class="dot"></div>
                                    </div>
                                    :
                                    <img style={{ padding: '0.5rem', backgroundColor: '#006D6B0D', borderRadius: '0.5rem' }}
                                        src={sonarAi} alt='databaseImg' />
                                }
                                <h5 className="titleStyle">
                                    {
                                        suggestions.length > 0 ?
                                            STRINGS.ASK_QUESTIONS_TO_PDF :
                                            STRINGS.LOADING_SUGGESTIONS
                                    }</h5>
                            </>
                        }
                        {searchValueRender && imageData?.result_data &&
                            imageData?.result_data.length > 0 &&
                            <>
                                <div style={{
                                    display: 'flex',
                                    justifyContent: "flex-start",
                                    backgroundColor: "#F6F7FA",
                                    fontSize: '1rem',
                                    fontWeight: 500,
                                    width: '100%',
                                    minWidth: '100%',
                                    paddingLeft: '2rem',
                                    paddingTop: '1rem'

                                }}>
                                    {imageData?.result_data.length} mentions found
                                </div>
                                <div className="result-images" style={{ width: "96%", minWidth: '96%', }}>
                                    {imageData?.result_data && imageData?.result_data?.map((imageData, index) => {
                                        return NewImageContainer(imageData, index)
                                    })}
                                </div>
                            </>
                        }
                        {imageData?.response &&
                            <p style={{ alignSelf: 'flex-start', lineHeight: '1.5rem', fontSize: '0.9rem', paddingLeft: '2rem' }}>
                                {!isLoading &&
                                    < Typewriter
                                        text={imageData?.response} delay={20} />
                                }
                            </p>
                        }
                        {searchValueRender && isLoading &&
                            <div class="loading-dots">
                                <div class="dot"></div>
                                <div class="dot"></div>
                                <div class="dot"></div>
                            </div>
                        }
                        {!isLoading && suggestions.length > 0 &&
                            <div className="search-box" style={{ width: searchValueRender ? "90%" : '400px' }}>
                                <CiSearch size={30} />
                                <input type="text"
                                    placeholder="specifications related to basement "
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    onKeyDown={_handleKeyDown}
                                    value={searchValue}
                                />
                                <img style={{
                                    backgroundColor: "#F6F7FA",
                                    padding: '0.4rem',
                                    borderRadius: "0.5rem",
                                    cursor: "pointer"
                                }} src={send} alt="send"
                                    onClick={() => {
                                        fetchQueryReq()
                                    }}
                                />
                            </div>
                        }
                        {suggestions.length > 0 && !isLoading &&
                            <div className="search-suggation"
                                style={{
                                    marginTop: '1rem',
                                    overflow: 'scroll',
                                    gap: '0.5rem',
                                    overflowX: 'hidden',
                                    padding: '0rem 2rem',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    alignItems: "center",
                                }}
                            >
                                {suggestions.slice(0, 2).map(suggestion => {
                                    return (
                                        <div className="suggetion-text" style={{ padding: '0px 1rem' }} onClick={() => {
                                            setSearchValue(suggestion);
                                            fetchQueryReq(suggestion)
                                        }}>
                                            <p style={{ marginRight: '0.5rem' }}>
                                                {/* {suggestion.length > 20 ? suggestion.substring(0, 20) + "..." : suggestion} */}
                                                {suggestion}
                                            </p>
                                            <MdOutlineArrowOutward size={15} color="gray" />
                                        </div>
                                    )
                                })}
                            </div>
                        }
                        {/* <div style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            maxWidth: '600px',
                            overflowX: 'scroll',
                            gap: '1rem',
                            flexDirection: 'row',
                            flexWrap: 'nowrap',
                        }} className="suggetion-tab">
                            <p>Sample</p>
                            <p>Sample</p>
                            <p>Sample</p>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

// const ImageContainer = (imageData) => {
//     return (
//         <div className="image-card" key={imageData.pageNo}>
//             <BsArrowUpRightCircleFill className="hover-img" style={{ position: 'absolute', top: '-0.6rem', right: '-0.4rem', backgroundColor: 'white' }} color="rgb(126, 255, 126)" size={25} />
//             <img src={imageData.image_url} alt="not-found" />
//             <div className="image-details">
//                 <div>
//                     <span style={{
//                         backgroundColor: 'rgb(126, 255, 126)',
//                         padding: '0.2rem 0.4rem',
//                         fontSize: '0.7rem',
//                         borderRadius: '50%',
//                         marginRight: '0.2rem'
//                     }}>1</span>
//                     <span style={{
//                         border: '1px solid rgb(227, 227, 227)',
//                         fontSize: '0.7rem',
//                         padding: '0.2rem 0.4rem',
//                         borderRadius: '0.3rem'
//                     }}>{imageData.file_name}</span>
//                 </div>
//                 <span style={{ fontSize: '0.7rem', }}>Page {imageData.pageNo}</span>
//             </div>
//         </div>
//     )
// }
