import React, { useEffect, useState } from 'react'
import { popUpStyle } from '../../newProjectDetails/components/modal/addUser.modal'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { buttonSecondaryStyle } from '../../newProjectDetails/components/modal/addUser.modal';
import { Checkbox } from '@mui/material';
import { LANDFILL_MARK_FAVORITE } from '../../../services';
import { checkHasBloackListAggrigates, checkHasFavAggrigates } from '../../../utils/encryptionDecryption';
import { CircularProgress } from "@mui/material";
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { ReactComponent as CompanyImg } from '../../materialSearch/assets/newMaterialCard.svg';
import { ReactComponent as FacilityImg } from '../../materialSearch/assets/newFacilityCard.svg';
import { ReactComponent as SelectedImg } from '../../materialSearch/assets/newSelectedCard.svg';
import { ReactComponent as PersonalStartImg } from '../../materialSearch/assets/newPersonalCard.svg';
import { ReactComponent as CompanyStartImg } from '../../materialSearch/assets/newCompanyCard.svg';
import { ReactComponent as RightImg } from '../../../assests/right.svg'
import STRINGS from '../../../helpers/staticContentHelper';
import { ReactComponent as ModalCross } from '../../../assests/modalCross.svg'

function LandfillAddToFavList({ open, setOpen, cardData, locationsData,
    setLoactionsData, isIndividualUser = true }) {
    const [isLoading, setIsLoading] = useState(false)
    const [filterOptions, setFilterOptions] = useState([]);
    const [optionsToAddToFav, setOptionsToAddToFav] = useState([]);
    const [facilityType, setFacilityType] = useState('')
    const [markFacilityFor, setMarkFacilityFor] = useState('')
    const [stepOne, setShowStepOne] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);

    const handleClose = () => {
        setOpen(prev => !prev)
    }

    const handleAddToFav = async () => {
        setIsLoading(true)
        let reqPayload = {
            "landfill_name": cardData.companyName,
            "landfill_address": cardData.formattedAddress,
            "landfill_id": cardData?.landfillId,
            "landfill_location": cardData.location,
            "materials": filterOptions,
            "materials_accepted": cardData?.categories,
            "type": isIndividualUser ? 'individual' : markFacilityFor,
            "favourite_facility": facilityType === 'The facility' ? true : false
        }
        const res = await LANDFILL_MARK_FAVORITE(reqPayload)
        setIsLoading(false)
        handleClose()

        try {
            if (!res.response) {
                const decryptedRes = decryptResponse(res)
                const parsedResponse = JSON.parse(decryptedRes)
                let locationsDataCopy = JSON.parse(JSON.stringify(locationsData))
                console.log(parsedResponse , 'parsed_response')
                if (parsedResponse.marked_materials) {
                    const finalData = locationsDataCopy.map(cardLocation => {
                        if (cardLocation.landfillId === cardData.landfillId) {
                            cardLocation.markedMaterials = parsedResponse.marked_materials || []
                            cardLocation.favouriteFacility = parsedResponse.favourite_facility || {}
                        }
                        return cardLocation
                    })
                    setLoactionsData(finalData)
                }
            } else {
                console.log(res.response.data, 'error')
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (cardData.categories) {
            console.log(checkHasFavAggrigates(cardData.markedMaterials), 'fav_list_after_filter', checkHasBloackListAggrigates(cardData.markedMaterials), 'blaklist_list_after filter')
            let finalSortedList = [...checkHasFavAggrigates(cardData.markedMaterials), ...checkHasBloackListAggrigates(cardData.markedMaterials)];
            let listToRender = []
            cardData.categories.forEach(material => {
                if (!finalSortedList.includes(material)) {
                    listToRender.push(material)
                }
            })
            setOptionsToAddToFav(listToRender)
        }
        if (isIndividualUser) {
            setMarkFacilityFor('individual')
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Box sx={{ ...popUpStyle, width: '680px', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1.5rem', overflowY: "scroll" }}>
            <div className='alter-heaader'>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: '100%' }}>
                        <p style={{ lineHeight: "1.8rem", margin: '0px', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                            {STRINGS.ADD_TO_FAVOURITE}
                        </p>
                        <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center' }}>
                            <ModalCross onClick={() => {
                                handleClose()
                            }} style={{ padding: "0.6rem", backgroundColor: "#f6f7fa", borderRadius: "50%", cursor: "pointer", boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px' }} />
                        </div>
                    </div>
                    <div className='devider' style={{ marginTop: "1rem" }} />
                </div>
                {stepOne &&
                    <>
                        <p className='section-title-for-card'>{STRINGS.WHAT_WOULD_YOU_LIKE_TO_FAVORITE}</p>
                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem', }}>

                            <div
                                className={`${facilityType === 'Select from materials' && 'select-type-new-card-active'} select-type-new-card`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setFacilityType('Select from materials')
                                }}>
                                {facilityType === 'Select from materials' ?
                                    <SelectedImg /> : <CompanyImg />}
                                <div className='select-type-new-card-info'>
                                    <p>{STRINGS.MATERIALS}</p>
                                    <span>{STRINGS.ADD_SPECIFIC_MATERIALS_AT_THE_FACILITY}</span>
                                </div>
                            </div>
                            <div className={`${facilityType === 'The facility' && 'select-type-new-card-active'} select-type-new-card`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setFacilityType('The facility')
                                }}>
                                {facilityType === 'The facility' ?
                                    <SelectedImg /> : <FacilityImg />}
                                <div className='select-type-new-card-info'>
                                    <p>{STRINGS.FACILITY}</p>
                                    <span>{STRINGS.ADD_THE_SUPPLIER_TO_YOUR_FAVOURITE_LIST}</span>
                                </div>
                            </div>
                        </div>
                        {/* select fav */}
                        {markFacilityFor !== 'individual' &&
                            <>
                                <p className='section-title-for-card' style={{ marginTop: "2rem" }}>{STRINGS.WHERE_WOULD_YOU_LIKE_TO_SAVE}</p>
                                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem', }}>

                                    <div
                                        className={`${markFacilityFor === 'personal' && 'select-type-new-card-active'} select-type-new-card`}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setMarkFacilityFor('personal')
                                        }}>
                                        {markFacilityFor === 'personal' ?
                                            <SelectedImg />
                                            : <PersonalStartImg />}
                                        <div className='select-type-new-card-info'>
                                            <p>{STRINGS.PERSONAL}</p>
                                            <span>{STRINGS.IT_WILL_ONLY_BE_SEEN_BY_YOU}</span>
                                        </div>
                                    </div>
                                    <div className={`${markFacilityFor === 'company' && 'select-type-new-card-active'} select-type-new-card`}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setMarkFacilityFor('company')
                                        }}>
                                        {markFacilityFor === 'company' ?
                                            <SelectedImg /> : <CompanyStartImg />}
                                        <div className='select-type-new-card-info'>
                                            <p>{STRINGS.COMPANY}</p>
                                            <span>{STRINGS.SHARED_WITH_ORG}</span>
                                        </div>
                                    </div>
                                </div>
                            </>}
                    </>
                }

                {stepTwo &&
                    <>
                        <p className='section-title-for-card'>{STRINGS.SELECT_MATERIALS}</p>
                        {optionsToAddToFav.length > 1 &&
                            <div style={{ display: 'flex', gap: "0.5rem", padding: '0.3rem', paddingBottom: "1.5rem" }}>
                                <span style={{ color: "#006D6B", fontSize: "14px", fontWeight: "400", cursor: "pointer", textDecoration: "underLine" }} onClick={() => setFilterOptions(cardData?.categories)}>Select all</span>
                                <span style={{ color: "#1B2E31", fontSize: "14px", fontWeight: "400", cursor: 'pointer', textDecoration: "underLine" }} onClick={() => setFilterOptions([])}>Clear</span>
                            </div>
                        }
                        {optionsToAddToFav.map(option => {
                            return (
                                <div className="option-for-mile-selection option-for-mile-selection-hover" style={{ width: "fit-content", padding: "0.3rem", }} onClick={() => {
                                    if (filterOptions.includes(option)) {
                                        const filteredList = filterOptions.filter(i => i !== option);
                                        setFilterOptions(filteredList)
                                    } else {
                                        setFilterOptions(prev => [...prev, option])
                                    }
                                }}>
                                    <Checkbox
                                        sx={{
                                            color: '#1F5D5E',
                                            '&.Mui-checked': {
                                                color: '#1F5D5E',
                                            },
                                            padding: '0px',
                                        }}
                                        id="myCheckbox"
                                        checked={filterOptions.includes(option)}
                                    />
                                    <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{option}</span>
                                </div>
                            )
                        })}
                    </>
                }

                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "2rem", alignItems: 'center' }}>
                    {stepTwo ?
                        <div className='card-back-button' onClick={() => {
                            setStepTwo(false)
                            setShowStepOne(true)
                        }}>
                            <RightImg className='rotate-image' />
                            {STRINGS.BACK}
                        </div> : <div></div>}

                    <Button variant="outlined"
                        size="large"
                        sx={{ ...buttonSecondaryStyle, width: '150px' }}
                        onClick={() => {
                            if (stepOne && facilityType === 'Select from materials') {
                                setStepTwo(true)
                                setShowStepOne(false)
                            } else {
                                handleAddToFav()
                            }
                        }}
                        disabled={stepOne ? !facilityType || !markFacilityFor || isLoading : filterOptions.length === 0 || isLoading}
                    >
                        {isLoading ?
                            <CircularProgress color="success" size="1rem" />
                            : stepOne && facilityType === 'Select from materials' ? 'Next' : 'Save'}
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default LandfillAddToFavList;