import React, { useState } from 'react'
import { popUpStyle } from '../../newProjectDetails/components/modal/addUser.modal'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import STRINGS from '../../../helpers/staticContentHelper';
import { buttonSecondaryStyle } from '../../newProjectDetails/components/modal/addUser.modal';
import { Checkbox } from '@mui/material';
import { UNDO_LANDFILL_BLACKLIST, UNDO_LANDFILL_FAVORITE } from '../../../services';
import { CircularProgress } from "@mui/material";

const buttonStyle = {
    border: "1px solid #E6E8EA",
    borderRadius: "12px",
    color: "black",
    textTransform: 'none',
    "&:hover": {
        border: "1px solid #E6E8EA",
    },
    fontFamily: "Plus Jakarta Sans"
};

function LandfillUndoList({ open, setOpen, cardData, isBlackListPopup = false, fetchList, type }) {
    const [filterOptions, setFilterOptions] = useState([]);
    const [isLoading, setIsLoading] = useState(false)

    const handleClose = () => {
        setOpen(prev => !prev)
    }

    const handleUndoBlockList = async () => {
        setIsLoading(true)
        let reqPayload = {
            "materials_accepted": (!cardData?.blacklist_facility && isBlackListPopup || (!cardData?.favourite_facility && !isBlackListPopup)) ? filterOptions : [],
            type: type
        }
        if (isBlackListPopup) {
            reqPayload["blacklist_id"] = cardData.blacklist_id
            const res = await UNDO_LANDFILL_BLACKLIST(reqPayload)
        } else {
            reqPayload["favourite_id"] = cardData.favourite_id
            const res = await UNDO_LANDFILL_FAVORITE(reqPayload)
        }
        handleClose()
        setIsLoading(false)
        fetchList()
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Box sx={{ ...popUpStyle, width: '600px', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1.5rem', overflowY: "scroll" }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>

                    <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', justifyContent: 'center', flex: 1 }}>
                        <p style={{ lineHeight: "1.8rem", margin: '0px', textAlign: 'center', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                            {isBlackListPopup ? STRINGS.UNBLOCK_MATERIALS_FROM : STRINGS.UNDO_FAVORITE_MATERIALS_FROM}
                        </p>
                        <span style={{ color: '#6D6D6D', fontSize: '14px' }}>{isBlackListPopup ? STRINGS.SELECT_MATERIAL_TO_UNBLOCK : STRINGS.SELECT_MATERIAL_TO_UNDO_FAVORITE}</span>
                    </div>
                </div>
                {(!cardData?.blacklist_facility && isBlackListPopup || (!cardData?.favourite_facility && !isBlackListPopup)) ?
                    <div className="grid-container" style={{ gridTemplateColumns: "1fr 1fr", marginLeft: "1.5rem", marginTop: "2rem" }}>
                        {cardData?.materials_accepted.map(option => {
                            return (
                                <div className="grid-item" style={{ cursor: "pointer" }} onClick={() => {
                                    if (filterOptions.includes(option)) {
                                        const filteredList = filterOptions.filter(i => i !== option);
                                        setFilterOptions(filteredList)
                                    } else {
                                        setFilterOptions(prev => [...prev, option])
                                    }
                                }}>
                                    <Checkbox
                                        sx={{
                                            color: '#1F5D5E',
                                            '&.Mui-checked': {
                                                color: '#1F5D5E',
                                            },
                                            padding: '0px',
                                        }}
                                        id="myCheckbox"
                                        checked={filterOptions.includes(option)}
                                    />
                                    <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{option}</span>
                                </div>
                            )
                        })}
                    </div>
                    :
                    <div className="grid-container" style={{ gridTemplateColumns: "1fr 1fr", marginLeft: "1.5rem", marginTop: "2rem" }}>
                        <div className="grid-item" style={{ cursor: "pointer" }}>
                            <Checkbox
                                sx={{
                                    color: '#1F5D5E',
                                    '&.Mui-checked': {
                                        color: '#1F5D5E',
                                    },
                                    padding: '0px',
                                }}
                                id="myCheckbox"
                                checked={true}
                            />
                            <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{STRINGS.THE_FECILITY}</span>
                        </div>
                    </div>
                }
                <div style={{ textAlign: 'end', display: 'flex', justifyContent: 'end' , marginTop:"1rem"}}>
                    <Button variant="outlined" size="large"
                        sx={{ ...buttonStyle, marginRight: '1rem', width: '150px' }}
                        onClick={handleClose}
                    >
                        {STRINGS.CANCEL}
                    </Button>
                    <Button variant="outlined"
                        size="large"
                        sx={{ ...buttonSecondaryStyle, width: '150px' }}
                        onClick={() => {
                            handleUndoBlockList()
                        }}
                        disabled={(!cardData?.blacklist_facility && isBlackListPopup || (!cardData?.favourite_facility && !isBlackListPopup)) ? isLoading || filterOptions.length === 0 : isLoading}
                    >
                        {isLoading ?
                            <CircularProgress color="success" size="1rem" /> :
                            'Submit'
                        }
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default LandfillUndoList;