import React from 'react'

function OhHoverLocationDetails({mark}) {
    return (
        <div className="details-of-location" style={{ flexDirection: 'column', overflow: "hidden", height: "auto", padding: "0.6rem" }}>
            <p style={{ fontSize: "13px", margin: '0px', width: "auto", whiteSpace: "nowrap", fontWeight: 500, maxWidth: "250px", overflow: "hidden", textOverflow: "ellipsis" }}>{mark.name}</p>
            <div className='location-search-more-details' style={{ marginTop: "0.5rem" }}>
                {mark?.categories.length > 0 &&
                    mark?.categories.map(category => {
                        return (
                            <div className='location-search-detail-card' style={{ padding: "0.2rem 0.5rem" }}>
                                <p style={{ fontSize: "9px" }}>{category}</p>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default OhHoverLocationDetails