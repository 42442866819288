import React, { useState, useRef, useEffect } from 'react'

function DropDownButtonWithIcon({ icon, title, onOptionChange, options = [], onChange = () => { } }) {
    const [dropDownActive, setDropDownActive] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (dropDownActive) {
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setDropDownActive(false)
                }
            }
            document.addEventListener("click", handleClickOutside);
        }
    }, [dropDownActive]);

    return (
        <div className='button-with-icon'
            onClick={(e) => {
                e.stopPropagation();
                setDropDownActive(prev => !prev)
                onChange()
            }} ref={wrapperRef}>
            <img src={icon} alt='sortImg' />
            {title}
            {options.length > 0 && dropDownActive &&
                <div className='drop-down-options-list'>
                    {options.map((option, index) => {
                        return (
                            <div className='drop-down-option' key={option + index}>
                                {
                                    option.icon &&
                                    <img src={option.icon} alt='not-found' />
                                }
                                {option.title}
                            </div>
                        )
                    })}
                </div>
            }
        </div>
    )
}

export default DropDownButtonWithIcon