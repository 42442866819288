import React, { useEffect, useState } from 'react'
import LogoWithName from '../../../assests/sonar.png';
import SonarLogo from '../../../assests/SonarNewLogo.svg';
import NavBarToggler from '../../../assests/navbarToggler.svg';
import HistoryInActiveImg from '../../../assests/historyInActive.svg';
import HistoryActiveImg from '../../../assests/historyActive.svg';
import FileFolderActiveImg from '../../../assests/fileFolderActive.svg';
import FileFolderInactiveImg from '../../../assests/fileFolderInactive.svg';
import MoreDotsImg from '../../../assests/moreDots.svg';
import HelpImg from '../../../assests/help.svg';
import BackImg from '../../../assests/back.svg';
import AddImg from '../../../assests/add.svg';
import { useNavigate } from 'react-router-dom';
import STRINGS from '../../../helpers/staticContentHelper';
import { useParams } from 'react-router-dom';
import { FETCH_PROJECT_HISTORY } from '../../../services';
import moment from 'moment';
import { decryptResponse, IS_USER_ADMIN } from '../../../utils/encryptionDecryption';
import { LStorage } from '../../../utils/localStorage.Helper';
import AddUserModal from './modal/addUser.modal';

function SideBar({ isInviteUserModalOpen, setIsInviteUserModalOpen, activeTab, setUserSelectedAnswer, setSearchParams, searchParams, setIsProjectSearchActive, setSelectedFile, isFetchTriggered }) {
    const userData = LStorage.getUserData()
    const params = useParams();
    const { projectId } = params;
    let threadId = searchParams.get('threadId')
    const navigate = useNavigate();
    const [isTogglerOn, setIsTogglerOn] = useState(true)
    const [threads, setThreads] = useState([])

    useEffect(() => {
        FETCH_PROJECT_HISTORY(projectId).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData) {
                    parsedDecryptedData.sort((left, right) => {
                        return moment.utc(right.updated_at).diff(moment(left.updated_at))
                    })
                    setThreads(parsedDecryptedData)
                }
            }
        })
    }, [isFetchTriggered]) //eslint-disable-line

    return (
        <div className={`project-details-navbar-main  ${!isTogglerOn && 'toggler-off'}`}>
            <AddUserModal
                open={isInviteUserModalOpen}
                setOpen={setIsInviteUserModalOpen}
                userData={userData} />
            <div className='project-details-navbar'>
                <div className='navbar-header'>
                    {isTogglerOn ?
                        <>
                            <img src={LogoWithName} alt='not-found' height={'32px'} />
                            <img src={NavBarToggler}
                                alt='not-found'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                                className='toggler-icon'
                            />
                        </> :
                        <>
                            <img src={SonarLogo} alt='not-found' className='oh-hover-hide' />
                            <div className='navbar-option on-hover-show'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                            >
                                <img src={NavBarToggler}
                                    alt='not-found'
                                />
                            </div>
                        </>
                    }
                </div>
                <div className='divider-hor'></div>
                <div className='new-thread' onClick={() => setIsProjectSearchActive(true)}>
                    <img src={AddImg} alt='new-thread' className='thread-icon rotate-icon' height='18px' />
                    <span>Search within project</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>Search within project</p>
                    }
                </div>
                <div className={`navbar-option ${activeTab === 'Files and folders' && 'active-tab'}`}
                    onClick={() => {
                        searchParams.set("activeTab", STRINGS.FILES_AND_FOLDERS);
                        searchParams.delete("threadId");
                        setSearchParams(searchParams);
                    }}>
                    <img src={`${activeTab === 'Files and folders' ? FileFolderActiveImg : FileFolderInactiveImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.FILES_AND_FOLDERS}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.FILES_AND_FOLDERS}</p>
                    }
                </div>
                <div className={`navbar-option ${activeTab === 'History' && 'active-tab'}`}
                    onClick={() => {
                        searchParams.set("activeTab", STRINGS.HISTORY);
                        searchParams.delete("threadId");
                        setSearchParams(searchParams)
                    }}>
                    <img src={`${activeTab === 'History' ? HistoryActiveImg : HistoryInActiveImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.HISTORY}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.HISTORY}</p>
                    }
                </div>
                {isTogglerOn &&
                    <div style={{ marginLeft: '1.2rem', display: 'flex', gap: '0.1rem', flexDirection: "column", borderLeft: "1.5px solid #c9c6c6", paddingLeft: '0.2rem' }}>
                        {threads.length > 0 &&
                            threads.slice(0, 5).map(threadData => {
                                return (
                                    <div key={threadData.id} className='navbar-option'
                                        style={{ padding: '4px 5px', fontSize: '0.75rem', overflow: "hidden", color: 'gray', backgroundColor: threadId === threadData.thread_id && "#ECF0F3" }}
                                        onClick={() => {
                                            searchParams.set("threadId", threadData?.thread_id);
                                            searchParams.set("activeTab", threadData.is_multi_search ? 'MultiPDF' : 'Home');
                                            setSearchParams(searchParams)
                                            setUserSelectedAnswer({})
                                            if (threadData.file) {
                                                setSelectedFile(threadData.file)
                                            }
                                        }}
                                    >
                                        {threadData.thread_name.substring(0, 32)}
                                        {threadData.thread_name.length > 32 && '...'}
                                    </div>
                                )
                            })}
                    </div>
                }
            </div>
            {/* navbar button  */}
            <div className='project-details-navbar'>

                <div className='project-info' onClick={() => navigate('/projects')}>
                    <img src={BackImg} alt='not-found' className='thread-icon on-hover-move' width='20px' />
                    <div className='navbar-option-no-hover'>
                        <img src={BackImg} alt='not-found' width='20px' height='20px' className='on-hover-move' />
                        <span className='back-to-project'>
                            {STRINGS.BACK_TO_PROJECTS}
                        </span>
                    </div>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.BACK_TO_PROJECTS}</p>
                    }
                </div>
                <div className='divider-hor'></div>
                {userData?.org_id ?
                    <div className='navbar-option-profile'>
                        <img src={(userData?.org_id && userData.org_logo) ? userData.org_logo : SonarLogo} alt='not-found' />
                        {isTogglerOn &&
                            <span>{userData?.org_id ? userData.org_name : userData.first_name + " " + userData.last_name}</span>
                        }
                    </div> :
                    <div className='navbar-option-between' onClick={() => {
                        window.open('https://cal.com/sonar/15min?month=2024-06&date=2024-06-04')
                    }}>
                        <span>{STRINGS.HELP_AND_SUPPORT}</span>
                        <img src={HelpImg} alt='not-found' width='20px' height='20px' />
                        {!isTogglerOn &&
                            <p className='navbar-tooltip'>{STRINGS.HELP_AND_SUPPORT}</p>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default SideBar;