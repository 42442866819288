import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import STRINGS from '../../helpers/staticContentHelper'
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { CREATE_NEW_FOLDER } from '../../services';
import { useParams, useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { decryptResponse } from '../../utils/encryptionDecryption';

export default function CreateFolderModal({ openFolderModal, setOpenFolderModal, title, loadDocumentsList }) {
    const handleClose = () => setOpenFolderModal(false);
    const [folderName, setFolderName] = React.useState('')
    const [isFolderCreating, setIsFolderCreating] = React.useState(false)

    const params = useParams();
    const { projectId } = params;
    const [searchParams] = useSearchParams()

    const validateFolderName = () => {
        if (folderName.trim().length >= 1)
            return true
        return false
    }
    const createFolder = () => {
        const isValidFolderName = validateFolderName()
        if (isValidFolderName) {
            setIsFolderCreating(true)
            const folderDir = searchParams.get('folderDir')
            const reqData = {
                project_id: projectId,
                folder_name: folderName,
                dir: `${folderDir ? folderDir : projectId}`

            }

            CREATE_NEW_FOLDER(reqData).then(res => {
                if (res) {
                    const decryptedResponse = decryptResponse(res)
                    const parsedDecryptedResponse = JSON.parse(decryptedResponse)
                    if (parsedDecryptedResponse) {
                        setOpenFolderModal(false)
                        loadDocumentsList()
                        setIsFolderCreating(false)
                        setFolderName('')
                    }
                }
            })
        }
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 2,
        borderRadius: '0.5rem'
    };
    const baseTyphography = {
        fontFamily: 'Plus Jakarta Sans'
    }
    const buttonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
            background: validateFolderName() ? '#1B2E31' : '#e6e6e6',
            opacity: '0.9',
            color: validateFolderName() ? 'white' : '#6D6D6D',
        },
        ...baseTyphography
    };
    return (
        <Modal
            open={openFolderModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={{ ...style, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer",
                        }} onClick={() => setOpenFolderModal(false)} />
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', ...baseTyphography }}>
                    {title}
                </Typography>
                <OutlinedInput size='small'
                    placeholder="Enter folder name"
                    fullWidth
                    required
                    sx={{
                        borderRadius: '0.5rem',
                        mt: 3,
                        ...baseTyphography
                    }}
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                />
                {isFolderCreating ?
                    <CircularProgress color="success" sx={{ color: '#1B2E31', margin: 'auto', display: 'flex', marginTop: '1rem' }} /> :
                    <Button type='submit' variant="outlined" size="large" fullWidth
                        sx={{ ...buttonStyle, backgroundColor: validateFolderName() ? '#1B2E31' : '#e6e6e6', color: validateFolderName() ? 'white' : '#6D6D6D', my: 2, disabled: !validateFolderName() }}
                        onClick={createFolder}
                    >
                        {STRINGS.CREATE}
                    </Button>
                }
            </Box>
        </Modal>
    );
}