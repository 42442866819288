import React, { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import './projectDetails.css';
import SideBar from './components/sidebar';
import ProjectDetailsHeader from './components/projectDetailsHeader';
import { CELERY_PENDING_TASKS_STATUS, GET_FILES_LIST } from '../../services';
import { decryptResponse } from '../../utils/encryptionDecryption';
import moment from 'moment';
import { FETCH_FOLDER_FILES } from '../../services';
import DoubleArrow from '../../assests/doubleArrow.svg'
import STRINGS from '../../helpers/staticContentHelper';
import Modal from '@mui/material/Modal';
import ViewSDKClient from '../dashboard/viewSdkClient';
import ProjectSearchModal from './components/modal/projectSearchModal';
import ProjectsFilesList from './components/projectFilesList';
import FileUploadStatus from './components/modal/FileUploadStatus';
const HistoryOfProject = React.lazy(() => import('./components/historyOfProject'));
const AnswerComponent = React.lazy(() => import('./stremWithWebSocket/answerComponent'));
const MultiPdfAnswerComponent = React.lazy(() => import('./stremWithWebSocket/multiPdfSearch'));
const MULTIPLE_SEARCH_OPTIONS = ["detail", "drawing"];

function NewProjectDetails() {
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams()
    const { projectId } = params;
    let folderDir = searchParams.get('folderDir')
    let currentActiveTab = searchParams.get('activeTab')
    let threadId = searchParams.get('threadId')
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [activeTab, setActiveTab] = useState('Files and folders');
    const [isPdfViewOpen, setIsPdfViewOpen] = useState(false);
    const [fetchingResult, setFetchingResult] = useState(false);
    const [isProjectSearchActive, setIsProjectSearchActive] = useState(false);
    const [activeFolderName, setActiveFolderName] = useState('');
    const [multiSearchValue, setMultiSearchValue] = useState('');
    const [selectedFile, setSelectedFile] = useState({});
    const [userSelectedAnswer, setUserSelectedAnswer] = useState({});
    const [highlightedPdf, setHighlightedPdf] = useState(null);
    const [isFetchTriggered, setIsFetchTriggered] = useState(1);
    const [pdfApis, setApis] = useState(null);
    const [lastPdfUrl, setLastPdfUrl] = useState(null);
    const [deleteList, setDeleteList] = useState([])
    const [taskStatusChange, setTaskStatusChange] = useState(0)
    const [isFocusModeOn, setIsFocusModeOn] = useState(false);
    const [actAnimation, setActAnimation] = useState(false);
    const [fileUploadingStatus, setFileUploadingStatus] = useState([]);

    const [isInviteUserModalOpen, setIsInviteUserModalOpen] = useState(false);

    const [documentType, setDocumentType] = useState([]);

    useEffect(() => {
        if (currentActiveTab && activeTab !== currentActiveTab) {
            setActiveTab(currentActiveTab)
        }
        if (threadId) {
            setFetchingResult(true)
        }
    }, [currentActiveTab, threadId])

    useEffect(() => {
        if (!selectedFile?.s3_url) {
            const localStorageFileData = localStorage.getItem('fileDetails')
            if (localStorageFileData) {
                setSelectedFile(JSON.parse(localStorageFileData))
            }
        }
    }, [])

    useEffect(() => {
        if (projectId && folderDir) {
            fetchFolderFiles(folderDir)
        }
        else {
            fetchFilesList()
        }
    }, [projectId, folderDir, taskStatusChange]) //eslint-disable-line

    const celeryStatus = () => {
        const reqPayload = {
            'project_id': projectId
        }

        CELERY_PENDING_TASKS_STATUS(reqPayload).then(statusRes => {
            if (statusRes) {
                const decryptedResponse = decryptResponse(statusRes)
                const parsedDecryptedResponse = JSON.parse(decryptedResponse)

                const taskList = parsedDecryptedResponse

                if (Array.isArray(taskList) && taskList.length > 0) {
                    const hasSuccess = taskList.some(task => {
                        const { task_response } = task || {};
                        return task_response?.state === "SUCCESS";
                    });
                    const deleteList = taskList.filter(task => task?.task_type === "DELETE")
                        .map(task => task.entity_id[0])
                    setDeleteList(deleteList)
                    if (hasSuccess) {
                        setTaskStatusChange(prevCount => prevCount + 1)
                        // window.location.reload()
                    }
                }
            }

        }).catch(err => {
            console.error("Error in CELERY_PENDING_TASKS_STATUS:", err)
        })
    }
    useEffect(() => {
        celeryStatus()
        const celeryInterval = setInterval(celeryStatus, 40000)
        return () => clearInterval(celeryInterval);
    }, []);

    const fetchFilesList = (reload = true) => {
        if (reload) {
            setIsLoading(true)
        }
        let finalData = []
        GET_FILES_LIST(projectId).then(res => {
            try {
                if (res) {
                    const decryptedResponse = decryptResponse(res)
                    const parsedDecryptResponse = JSON.parse(decryptedResponse)
                    if (parsedDecryptResponse.current_dir_name) {
                        setActiveFolderName(parsedDecryptResponse.current_dir_name)
                    }
                    if (parsedDecryptResponse?.files) {
                        finalData = parsedDecryptResponse?.files
                    }
                    if (parsedDecryptResponse?.folders) {
                        finalData = [...finalData, ...parsedDecryptResponse?.folders]
                    }
                    finalData.sort((left, right) => {
                        return moment.utc(right.created_at).diff(moment(left.created_at))
                    })
                    setTableData(finalData)
                    setIsLoading(false)
                }

            } catch (error) {
                console.error(error)
            }
        })
    }

    const fetchFolderFiles = (folderDir, folderId = '', reload = true) => {
        if (reload) {
            setIsLoading(true)
        }
        const directory = folderId ? `${folderDir}/${folderId}` : folderDir
        const reqData = {
            dir: directory
        }
        FETCH_FOLDER_FILES(reqData).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData.current_dir_name) {
                    setActiveFolderName(parsedDecryptedData.current_dir_name)
                }
                let parsedFiles = []
                let parsedFolders = []
                if (parsedDecryptedData.files) {
                    parsedFiles = parsedDecryptedData.files
                }
                if (parsedDecryptedData.folders) {
                    parsedFolders = parsedDecryptedData.folders
                }
                [...parsedFiles, ...parsedFolders].sort((left, right) => {
                    return moment.utc(right.created_at).diff(moment(left.created_at))
                })
                setTableData([...parsedFiles, ...parsedFolders])
                setIsLoading(false)
            }

        })
    }

    // this is an file based utility function
    const getFolderIdAndDir = () => {
        if (folderDir) {
            const finalString = folderDir.split("/");
            const folderId = finalString[finalString.length - 1]

            let rowDir = ''

            const folderD = finalString.slice(0, finalString.length - 1)

            folderD.map((str, index) => {
                if (index === 0) {
                    rowDir = rowDir + str
                } else {
                    rowDir = rowDir + "/" + str
                }
            })
            return { rowDir, folderCount: folderD.length, folderId }
        } else {
            return { rowDir: null, folderCount: null, folderId: null }
        }
    }

    const loadDocumentsList = () => {
        if (folderDir) {
            const { folderId, rowDir } = getFolderIdAndDir();
            fetchFolderFiles(rowDir, folderId, false)
        } else {
            fetchFilesList()
        }
    }

    useEffect(() => {
        const pffUrl = highlightedPdf ? highlightedPdf : selectedFile?.s3_url
        if (selectedFile?.s3_url && (pffUrl !== lastPdfUrl)) {
            setLastPdfUrl(pffUrl)
            const config = {
                embedMode: "FULL_WINDOW",
                clientId: process.env.REACT_APP_ADOBE_KEY,
                showAnnotationTools: false,
                showPrintPDF: false,
                showFullScreen: true,
                showPageControls: false,
                showDownloadPDF: false,
                fileUrl: pffUrl,
                fileName: selectedFile?.file_name,
                ignoreEncryption: true
            }

            const viewSDKClient = new ViewSDKClient();

            viewSDKClient.ready().then(async () => {
                /* Invoke the file preview and get the Promise object */
                const viewer = await viewSDKClient.previewFile("pdf-div", config);

                const newApis = await viewer.getAPIs();
                newApis.getZoomAPIs().setZoomLevel(0.7)
                setApis(newApis)
                if (selectedFile.bypassPageNumber) {
                    newApis.gotoLocation(selectedFile.page_no)
                } else {
                    newApis.gotoLocation(userSelectedAnswer.page_no ? userSelectedAnswer.page_no : 1);
                }
            });

        } else if (pdfApis) {
            if (selectedFile && selectedFile.bypassPageNumber) {
                pdfApis.gotoLocation(selectedFile.page_no)
            } else {
                pdfApis.gotoLocation(userSelectedAnswer.page_no ? userSelectedAnswer.page_no : 1);
            }
        }
    }, [selectedFile, userSelectedAnswer, highlightedPdf]); //eslint-disable-line

    useEffect(() => {
        if (selectedFile?.s3_url) {
            localStorage.setItem('fileDetails', JSON.stringify(selectedFile))
        }
    }, [selectedFile])

    useEffect(() => {
        setDocumentType(isFocusModeOn ? MULTIPLE_SEARCH_OPTIONS : []);
    }, [isFocusModeOn])

    return (
        <div className='projects-details-main'>
            {/* left nav bar  */}
            <SideBar
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                setSearchParams={setSearchParams}
                searchParams={searchParams}
                setIsProjectSearchActive={setIsProjectSearchActive}
                setSelectedFile={setSelectedFile}
                isFetchTriggered={isFetchTriggered}
                setUserSelectedAnswer={setUserSelectedAnswer}
                setIsInviteUserModalOpen={setIsInviteUserModalOpen}
                isInviteUserModalOpen={isInviteUserModalOpen}
            />

            {fileUploadingStatus.length > 0 && <FileUploadStatus fileUploadingStatus={fileUploadingStatus} />}

            {/* project details */}
            <div className='project-files-details' style={{ marginRight: isPdfViewOpen && '-0px' }}>
                {activeTab !== 'MultiPDF' &&
                    <ProjectDetailsHeader
                        selectedFile={selectedFile}
                        setActiveTab={setActiveTab}
                        isPdfViewOpen={isPdfViewOpen}
                        hederTitle={activeTab}
                        setIsPdfViewOpen={setIsPdfViewOpen}
                        fetchingResult={fetchingResult}
                        searchParams={searchParams}
                        setSearchParams={setSearchParams}
                        setIsInviteUserModalOpen={setIsInviteUserModalOpen}
                    />
                }

                {activeTab === 'Files and folders' &&
                    <div className='project-details-table'>
                        <ProjectsFilesList
                            folderDir={folderDir}
                            tableData={tableData}
                            setTableData={setTableData}
                            projectsListLoading={isLoading}
                            fetchFilesList={fetchFilesList}
                            projectId={projectId}
                            fetchFolderFiles={loadDocumentsList}
                            setActiveTab={setActiveTab}
                            setSelectedFile={setSelectedFile}
                            setFetchingResult={setFetchingResult}
                            getFolderIdAndDir={getFolderIdAndDir}
                            projectName={searchParams.get('projectName')}
                            activeFolderName={activeFolderName}
                            selectedFile={selectedFile}
                            setHighlightedPdf={setHighlightedPdf}
                            setSearchParams={setSearchParams}
                            searchParams={searchParams}
                            setUserSelectedAnswer={setUserSelectedAnswer}
                            deleteList={deleteList}
                            celeryStatus={celeryStatus}
                            fileUploadingStatus={fileUploadingStatus}
                            setFileUploadingStatus={setFileUploadingStatus}
                        />
                    </div>
                }

                {activeTab === 'Home' &&
                    <AnswerComponent
                        setUserSelectedAnswer={setUserSelectedAnswer}
                        fileData={selectedFile}
                        fetchingResult={fetchingResult}
                        setFetchingResult={setFetchingResult}
                        userSelectedAnswer={userSelectedAnswer}
                        setIsPdfViewOpen={setIsPdfViewOpen}
                        setSelectedFile={setSelectedFile}
                        setHighlightedPdf={setHighlightedPdf}
                        threadId={threadId}
                        // setBlobUrl={setBlobUrl}
                        setIsFetchTriggered={setIsFetchTriggered}
                        documentType={documentType}
                        setDocumentType={setDocumentType}
                        actAnimation={actAnimation}
                        setActAnimation={setActAnimation}
                        isFocusModeOn={isFocusModeOn}
                        setIsFocusModeOn={setIsFocusModeOn}
                    />
                }

                {activeTab === 'History' &&
                    <HistoryOfProject
                        setSearchParams={setSearchParams}
                        searchParams={searchParams}
                        setSelectedFile={setSelectedFile}
                        // setBlobUrl={setBlobUrl}
                        setUserSelectedAnswer={setUserSelectedAnswer}
                    />
                }

                {isProjectSearchActive &&
                    <Modal
                        open={isProjectSearchActive}
                        onClose={() => setIsProjectSearchActive(false)}
                        aria-labelledby="modal-modal-title"
                        onClick={(e) => e.stopPropagation()}
                        style={{ display: "flex", justifyContent: "center", alignItems: 'center', backdropFilter: "blur(3px)" }}
                        // overlayStyle={{ backgroundColor: 'red' }}
                        slotProps={{ backdrop: { sx: { background: 'rgba(0,0 ,0 ,0.8 )' } } }}
                    >
                        <ProjectSearchModal
                            fetchingResult={fetchingResult}
                            setFetchingResult={setFetchingResult}
                            searchValue={multiSearchValue}
                            setSearchValue={setMultiSearchValue}
                            projectName={searchParams.get('projectName')}
                            setIsProjectSearchActive={setIsProjectSearchActive}
                            documentType={documentType}
                            setDocumentType={setDocumentType}
                            isFocusModeOn={isFocusModeOn}
                            setIsFocusModeOn={setIsFocusModeOn}
                            actAnimation={actAnimation}
                            setActAnimation={setActAnimation}
                        />
                    </Modal>
                }

                {activeTab === 'MultiPDF' &&
                    <MultiPdfAnswerComponent
                        isPdfViewOpen={isPdfViewOpen}
                        setIsPdfViewOpen={setIsPdfViewOpen}
                        setSelectedFile={setSelectedFile}
                        selectedFile={selectedFile}
                        multiSearchValue={multiSearchValue}
                        setMultiSearchValue={setMultiSearchValue}
                        threadId={threadId}
                        projectName={searchParams.get('projectName')}
                        setHighlightedPdf={setHighlightedPdf}
                        setIsFetchTriggered={setIsFetchTriggered}
                        documentType={documentType}
                        setDocumentType={setDocumentType}
                        isFocusModeOn={isFocusModeOn}
                        setIsFocusModeOn={setIsFocusModeOn}
                        actAnimation={actAnimation}
                        setActAnimation={setActAnimation}
                    />
                }
            </div>


            {/* modal for the pdf file */}

            <div className='pdf-side-view-modal' style={{ width: isPdfViewOpen && (activeTab === 'Home' || activeTab === 'MultiPDF') ? '40%' : '0px' }}>
                {isPdfViewOpen && (activeTab === 'Home' || activeTab === 'MultiPDF') && <div className='pdf-side-view-modal-header'>
                    <div className='double-arrow-with-title'>
                        <img src={DoubleArrow} alt='not-found' onClick={() => setIsPdfViewOpen(false)} />
                        <p>{STRINGS.PDF_VIEW}</p>
                    </div>
                    <p className='records-found-text' style={{ position: 'relative' }}>
                        {selectedFile?.file_name?.substring(0, 35)}
                        {selectedFile?.file_name?.length > 35 && '...'}
                    </p>
                </div>}
                <div id='pdf-div'></div>
            </div>
        </div>
    )
}

export default NewProjectDetails;