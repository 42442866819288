import { createContext, useContext, useState } from 'react';

const StoreLocationHistoryContext = createContext();

export const StoreLocationHistoryValuesProvider = ({ children }) => {
    const [locationHistoryValues, setLocationHistoryValues] = useState({});

    return (
        <StoreLocationHistoryContext.Provider value={{ locationHistoryValues, setLocationHistoryValues }}>
            {children}
        </StoreLocationHistoryContext.Provider>
    );
};

export const useStoreLocationHistoryContext = () => useContext(StoreLocationHistoryContext);
