import React, { useEffect, useState } from 'react'
import { ReactComponent as FocusModeImg } from '../../../assests/focusMode.svg';
import STRINGS from '../../../helpers/staticContentHelper';
function FocusMode({ setIsFocusModeOn, setActAnimation, isFocusModeOn, actAnimation, position = 'bottom' }) {

    return (
        <div className='select-search-type' onClick={() => {
            setIsFocusModeOn(prev => !prev)
            setActAnimation(prev => true)
            setTimeout(() => {
                setActAnimation(prev => false)
            }, [2300])
        }} style={{ backgroundColor: isFocusModeOn && '#b1e6d0', position: 'relative', zIndex: 1 }}>
            <FocusModeImg className='select-search-type-img' />
            <span className={isFocusModeOn && actAnimation ? 'focus-mode-on' : 'hide-focus-mode-text'} key='Focus-mode-on'>
                Focus: on
            </span>
            <span className={!isFocusModeOn && actAnimation ? 'focus-mode-on' : 'hide-focus-mode-text'} key='Focus-mode-on'>
                Focus: off
            </span>
            {!actAnimation && !isFocusModeOn && <div onClick={(e) => e.stopPropagation()} className='on-hover-tool-bar' style={{ ...(position === 'top' ? { bottom: '2.5rem' } : { top: "2rem", }) }}>
                <p className='on-hover-tool-bar-title' style={{ fontSize: "1rem", margin: '0px', marginBottom: "0.8rem" }}>{STRINGS.FOCUS} <span>{STRINGS.SEARCH}</span> <span style={{ fontSize: '0.7rem', paddingLeft: '0.3rem', fontWeight: "normal" }}>{'( powered by Dexter v1 )'}</span></p>
                <span className='on-hover-tool-bar-desc' style={{ fontSize: '0.8rem' }}>{STRINGS.FOCUS_MODE_DESCRIPTION}<span style={{ fontWeight: 'bold' }}> {STRINGS.DRAWINGS}</span></span>
            </div>}
        </div>
    )
}

export default FocusMode