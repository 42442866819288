import React, { useEffect, useState } from "react";
import "./assets/material.styles.css";
import SearchImg from "./assets/search.svg";
import { ReactComponent as RightArrow } from "./assets/rightArrow.svg";
import { ReactComponent as BackToSearchImg } from "./assets/backToSearch.svg";
import { ReactComponent as ArrowDown } from "./assets/arrowDown.svg";
import LocationCrad from "./components/locationCrad";
import CheckboxDropdown from "./components/checkboxDropdown";
import LocationListDropdown from "./components/locationListDropdown";
import MapsComponent from "./components/mapsComponent";
import LocationsLoader from "./components/locationsLoader";
import { FIND_MATERIAL_PLACE } from "../../services";
import { decryptResponse } from "../../utils/encryptionDecryption";
import { MATERIAL_SEARCH_HISTORY } from "../../services";
import STRINGS, { PROXIMITY_OPTIONS } from "../../helpers/staticContentHelper";
import { FETCH_LOCATION_DATA } from "./googleSerive";
import { GET_REQUEST_PAYLOAD } from "./googleSerive";
import { FETCH_LOCATION_ROUTES } from "./googleSerive";
import { useStoreLocationHistoryContext } from "../../services/storeMaterialSearchContext";
import { ReactComponent as FilterImg } from './assets/filter.svg';
import { Checkbox } from '@mui/material';
import { CircularProgress } from "@mui/material";
import { areObjectsEqual } from "../../utils/encryptionDecryption";
import { GET_SEARCH_COUNT_LEFT } from "../../services";
import PopupForm from './../formPage/popupForm'
export function checkType(value) {
    if (typeof value === 'function') {
        return 'function'
    } else {
        return 'number'
    }
}

const CREATE_LOCATION_CARD_DATA = (address) => {
    return {
        ...address,
        name: address?.companyName,
        address: address?.formattedAddress,
        phone: address["Phone (M)"] || '',
        categories: address?.materialsAvailable,
        lat: address?.location?.latitude,
        lng: address?.location?.longitude,
        id: address?.materialPlaceId,
        contact_name: address?.Contact || '',
        email: address?.Email || '',
        officePhone: address?.nationalPhoneNumber || '',
        website: address["websiteUri"] || ''
    }
}
function SearchMaterial() {
    const [pointerValue, setPointerValue] = useState(null)
    const { locationHistoryValues, setLocationHistoryValues } = useStoreLocationHistoryContext();
    const [isSearchActive, setIsSearchActive] = useState(true)
    const [isUserFormOpen, setIsUserFormOpen] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const [activeLocation, setActiveLoaction] = useState(null)
    const [response, setResponse] = useState(null); //this response is used to add track between two loactions 
    const [searchValue, setSearchValue] = useState('');
    const [isFilterOptionEnabled, setIsFilterOptionEnabled] = useState(false);
    const [isFilterLoading, setIsFilterLoading] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const [isFilterActive, setisFilterActive] = useState(false);
    const [activeDropDown, setActiveDropDown] = useState('') //for active dropdown
    // locations data after serach 
    const [autoCompleteLocationData, setAutoCompleteLoactionsData] = useState([]);
    const [locationsData, setLoactionsData] = useState([]);
    const [nearbyLocationsData, setNearbyLocationsData] = useState([]);
    const [afterFilterLocationsData, setAfterFilterLoactionsData] = useState([]);
    const [destinationLocation, setDestinationLocation] = useState(null)
    const [isAnimationEnabled, setIsAnimationEnabled] = useState(false)
    const [isQueryDone, setIsQueryDone] = useState(false)
    const [searchCountLeft, setSearchCountLeft] = useState(null)
    // payload for the api request
    const [apiRequestPayload, setApiRequestPayload] = useState({
        "destination_address": "",
        "materials": [],
        "proximity": null
    })
    const [prevApiRequestPayload, setPrevApiRequestPayload] = useState({
        "destination_address": "",
        "materials": [],
        "proximity": null
    })

    const fetchLocationdata = async (key) => {
        try {
            const res = await FETCH_LOCATION_DATA(key)
            setAutoCompleteLoactionsData(res)
        } catch (error) {
            console.log(error)
        }
    }

    const getSearchLimitCount = async () => {
        try {
            const res = await GET_SEARCH_COUNT_LEFT();
            const decryptedData = JSON.parse(decryptResponse(res))
            if (decryptedData) {
                setSearchCountLeft(decryptedData)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleGetLocation = async () => {
        const validatePayload = areObjectsEqual(prevApiRequestPayload, apiRequestPayload)
        setIsSearchActive(false)
        if (!validatePayload) {
            setIsQueryDone(false)
            setisFilterActive(false)
            setFilterOptions([])
            setIsAnimationEnabled(true)
            setResponse(null)
            setPointerValue(null)
            setIsLoading(prev => true)
            try {
                setPrevApiRequestPayload(apiRequestPayload)
                let res = await FIND_MATERIAL_PLACE(apiRequestPayload);
                if (res?.response?.data?.error_code === "SNR_1412") {
                    setIsUserFormOpen(res.response.data.error_code)
                    setLoactionsData([])
                    setNearbyLocationsData([])
                    setIsLoading(prev => false)
                    setIsQueryDone(true)
                    return
                }
                const decryptedData = JSON.parse(decryptResponse(res))
                const { places, nearby_places } = decryptedData;
                const formattedAddressData = places?.map(address => {
                    return CREATE_LOCATION_CARD_DATA(address)
                })
                const formattedNearbyData = nearby_places?.map(address => {
                    return CREATE_LOCATION_CARD_DATA(address)
                })
                setIsLoading(prev => false)
                setLoactionsData(formattedAddressData)
                setNearbyLocationsData(formattedNearbyData)
                //this helps to reserv the current state of query with results data
                setLocationHistoryValues(prev => {
                    return { ...prev, locationsData: formattedAddressData, apiRequestPayload, destinationLocation }
                })
                setIsQueryDone(true)
                getSearchLimitCount()
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        const handler = setTimeout(() => {
            if (searchValue) {
                fetchLocationdata(searchValue)
            }
        }, 200); // 200ms delay for search
        return () => {
            clearTimeout(handler); // Cleanup timeout if query changes
        };
    }, [searchValue]);

    const storeHistory = async (payload) => {
        await MATERIAL_SEARCH_HISTORY(payload)
    }

    const handleOnCardClick = async (cardData, idFromMap = false) => {
        if (cardData?.id === response?.materialPlaceId) {
            setResponse(null)
            setPointerValue(null)
            return
        }
        const reqPayloadForDirection = {
            origin: {
                lat: cardData.location.latitude,
                lng: cardData.location.longitude,
            },
            destination: destinationLocation
        }

        let responseForRoute = await FETCH_LOCATION_ROUTES(reqPayloadForDirection)
        responseForRoute.availableRoutes = responseForRoute.routes
        responseForRoute.routes = [responseForRoute.availableRoutes[0]]
        responseForRoute.activeRoute = 0
        responseForRoute.materialPlaceId = cardData.materialPlaceId
        setResponse(responseForRoute)
        triggerSetPointerValue(responseForRoute.routes[0])

        let reqPayload = GET_REQUEST_PAYLOAD(cardData, apiRequestPayload, destinationLocation);
        const duration = responseForRoute.routes[0].legs[0].duration.text
        const distance = (responseForRoute.routes[0].legs[0].distance.value / 1609.34).toFixed(2)
        reqPayload.distance.time = duration
        reqPayload.distance.miles = parseFloat(distance)
        storeHistory(reqPayload)

        setLocationHistoryValues(prev => {
            return { ...prev, response: responseForRoute }
        })

        if (idFromMap) {
            scrollToDiv(cardData.materialPlaceId)
        }
    }

    const checkValidation = () => {
        return apiRequestPayload.destination_address && apiRequestPayload.proximity && apiRequestPayload.materials.length > 0
    }

    function scrollToDiv(divId) {
        const targetDiv = document.getElementById(divId);
        if (targetDiv) {
            targetDiv.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    const triggerSetPointerValue = (routeData) => {
        let latitude = checkType(routeData.legs[0].steps[0].start_point.lat) === 'function' ? routeData.legs[0].steps[0].start_point.lat() : routeData.legs[0].steps[0].start_point.lat
        let longitude = checkType(routeData.legs[0].steps[0].start_point.lng) === 'function' ? routeData.legs[0].steps[0].start_point.lng() : routeData.legs[0].steps[0].start_point.lng
        let middleValue = Math.round(routeData.legs[0].steps.length / 2);
        const actualPointerValue = {
            ...routeData.legs[0].steps[middleValue].start_point,
            lat: latitude,
            lng: longitude,
            distance: (routeData.legs[0].distance.value / 1609.34).toFixed(2),
            duration: routeData.legs[0].duration.text,
        }
        setPointerValue(actualPointerValue)
        setLocationHistoryValues(prev => {
            return { ...prev, pointerValue: actualPointerValue }
        })
    }

    const setPrevSearchValues = (materialSearchData) => {
        if (materialSearchData) {
            let { apiRequestPayload, response, pointerValue, locationsData, destinationLocation } = materialSearchData;
            setDestinationLocation(destinationLocation || null)
            setLoactionsData(locationsData || [])
            setIsSearchActive(false)
            if (apiRequestPayload) setApiRequestPayload(apiRequestPayload)
            setPointerValue(pointerValue || null)
            setResponse(response || null)
        }
    }

    useEffect(() => {
        if (Object.keys(locationHistoryValues).length) {
            setPrevSearchValues(locationHistoryValues)
        }
        getSearchLimitCount()
    }, [])


    const applyFilter = async () => {
        setIsFilterLoading(prev => true)
        setIsFilterOptionEnabled(false)
        let filteredData = []
        const loopData = [...locationsData, ...nearbyLocationsData]
        loopData.forEach(locationCard => {
            const isPresent = locationCard.categories.some(item => filterOptions.includes(item));
            if (isPresent) {
                filteredData.push(locationCard)
            }
        })
        await new Promise((resolve) => setTimeout(resolve, 500));
        setIsFilterLoading(prev => false)
        setAfterFilterLoactionsData(filteredData)
        setisFilterActive(true)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: "100%", flex: 1 }} onClick={() => {
            setActiveDropDown('')
            setIsFilterOptionEnabled(false)
        }}>
            <PopupForm open={isUserFormOpen} setOpen={setIsUserFormOpen} />
            <p style={{ paddingLeft: "1rem", marginTop: "2rem", marginBottom: '0px', position: "relative" }}>
                {STRINGS.SEARCH_MATERIAL}
                {searchCountLeft && searchCountLeft?.used >= 0 &&
                    <span style={{ position: 'absolute', top: '24px', left: '15px', fontSize: "0.7rem", backgroundColor: '#e2e2e2', borderRadius: "0.5rem", padding: "0.2rem 0.5rem" }} className="filter-option-tooltip">
                        {STRINGS.MONTHLY_LIMIT} {searchCountLeft?.used}/5
                        <p style={{ left: '-4px', top: '13px', zIndex: 1, width: "fit-content" }} className='verified-by-sonar-tooltip'>{STRINGS.FREE_MONTHLY_LIMIT_REACHED_UPGRADE_FOR_UNLIMITED}</p>
                    </span>}
            </p>
            <div className="search-material-main-container">
                <div className="search-material-container"
                    style={{ cursor: !isSearchActive && 'pointer' }}
                    onClick={() => {
                        if (!isSearchActive) {
                            setIsSearchActive(true)
                        }
                    }}>
                    <div className={`material-search-filter-with-icon ${!isSearchActive && 'decrease-width'}`}>
                        {isSearchActive && <p className={`filter-header ${isAnimationEnabled && 'filter-header-animate-in'}`}>{STRINGS.PROJECT_ADDRESS}</p>}
                        <div className="about-filter-with-icon">
                            <input
                                className="no-border-input-box"
                                style={{ cursor: !isSearchActive && 'pointer' }}
                                placeholder={apiRequestPayload?.destination_address ?
                                    apiRequestPayload?.destination_address.length > 22 ?
                                        apiRequestPayload?.destination_address.substring(0, !isSearchActive ? 13 : 22) + '...' :
                                        apiRequestPayload?.destination_address :
                                    "Enter job number or address"}
                                onChange={(e) => setSearchValue(e.target.value)}
                                value={searchValue}
                            />
                            {isSearchActive &&
                                <img src={SearchImg} alt="no" />}
                        </div>
                        {searchValue &&
                            <LocationListDropdown
                                setSearchValue={setSearchValue}
                                autoCompleteLocationData={autoCompleteLocationData}
                                setDestinationLocation={setDestinationLocation}
                                setApiRequestPayload={setApiRequestPayload} />
                        }
                    </div>

                    <div className="devide-indicator"></div>

                    <div className={`material-search-filter-with-icon ${!isSearchActive && 'decrease-width'}`}>
                        {isSearchActive &&
                            <p className={`filter-header ${isAnimationEnabled && 'filter-header-animate-in'}`}>{STRINGS.PROXIMITY}</p>}
                        <div className="about-filter-with-icon" onClick={(e) => {
                            if (isSearchActive) {
                                e.stopPropagation();
                                setActiveDropDown(STRINGS.PROXIMITY)
                            }
                        }}>
                            <span className="filter-sub-text">{apiRequestPayload.proximity ? apiRequestPayload.proximity + ' miles' : 'Select the distance'}</span>
                            {isSearchActive && <ArrowDown />}
                        </div>
                        {activeDropDown === STRINGS.PROXIMITY &&
                            <div className="search-result-for-location hide-scrollbar" style={{ width: "240px", maxHeight: "250px", alignItems: "start", backgroundColor: 'white', overflowY: "scroll", overflowX: 'hidden', zIndex: 99999 }}>
                                {PROXIMITY_OPTIONS.map((km, index) => {
                                    return (
                                        <div className="option-for-mile-selection" onClick={() => setApiRequestPayload(prev => {
                                            setActiveDropDown('')
                                            return { ...prev, proximity: km.value }
                                        })}>
                                            {km.key}
                                        </div>
                                    )
                                })}
                            </div>
                        }
                    </div>

                    <div className="devide-indicator"></div>

                    <div className={`material-search-filter-with-icon ${!isSearchActive && 'decrease-width'}`} >
                        {isSearchActive ?
                            <>
                                <p className={`filter-header ${isAnimationEnabled && 'filter-header-animate-in'}`}>{STRINGS.PRODUCT_MATERIAL}</p>
                                <div className="about-filter-with-icon"
                                    onClick={(e) => {
                                        if (isSearchActive) {
                                            e.stopPropagation()
                                            setActiveDropDown(STRINGS.PRODUCT_MATERIAL)
                                        }
                                    }}>
                                    <span className="filter-sub-text">{apiRequestPayload.materials.length > 0 ? apiRequestPayload.materials.join(", ").substring(0, 20) : 'Select the materials'}</span>
                                    {apiRequestPayload.materials.length > 1 &&
                                        <div className="materials-more-chip">+{apiRequestPayload.materials.length - 1}</div>
                                    }
                                    <ArrowDown />
                                </div>
                            </> :
                            <span className="filter-sub-text">{apiRequestPayload.materials.length} {apiRequestPayload.materials.length === 1 ? 'material' : 'materials'}</span>
                        }
                        {activeDropDown === STRINGS.PRODUCT_MATERIAL &&
                            <div className="search-result-for-location" style={{ width: "auto", left: "-1rem", padding: "1rem", alignItems: "start", backgroundColor: 'white', zIndex: 99999 }}>
                                <p className="filter-header">{STRINGS.SELECT_PRODUCT_TYPE}</p>
                                <CheckboxDropdown
                                    setApiRequestPayload={setApiRequestPayload}
                                    apiRequestPayload={apiRequestPayload} />
                            </div>}
                    </div>
                    {isSearchActive ?
                        <RightArrow className={`${checkValidation() && 'active-arrow'}`} style={{ cursor: "pointer" }}
                            onClick={() => {
                                if (checkValidation()) {
                                    handleGetLocation()
                                }
                            }} />
                        : <BackToSearchImg style={{ cursor: "pointer" }} />
                    }
                </div>
            </div>

            {/* detail section */}
            <div className="result-for-the-material-serah-component">
                <div className={`location-list-of-origins hide-scrollbar ${!isSearchActive && 'location-list-of-origins-active'} `}>
                    {!loading && (locationsData.length > 0 || nearbyLocationsData.length > 0) &&
                        <div className="filter-options-for-location-card">
                            <p className={`${isFilterLoading && 'loading-text-gray'}`}>
                                {isFilterLoading ? STRINGS.FINDING_THE_BEST_MATCHES_FOR_YOU :
                                    isFilterActive ? afterFilterLocationsData.length : [...locationsData, ...nearbyLocationsData].length
                                } {!isFilterLoading && ' '}
                                {(locationsData.length === 1 || (afterFilterLocationsData.length === 1 && isFilterActive)) ? STRINGS.FECILITIY_FOUND : STRINGS.FECILITIES_FOUND}
                                {isFilterLoading &&
                                    <CircularProgress color="success" size="0.8rem" sx={{ ml: '0.5rem' }} />
                                }
                            </p>
                            <div className={`${isFilterActive && filterOptions.length > 0 && 'location-filter-img-after'} location-filter-img`}>
                                <div className="filter-option-tooltip">
                                    <FilterImg className="filter-image" onClick={(e) => {
                                        e.stopPropagation()
                                        setIsFilterOptionEnabled(prev => !prev)
                                    }} />
                                    <p style={{ right: '-0px', top: '35px', zIndex: 1 }} className='verified-by-sonar-tooltip'>{STRINGS.FILTER_TOOLTIP}</p>
                                </div>
                                {isFilterOptionEnabled &&
                                    <div className="search-result-for-location-filter hide-scrollbar" onClick={(e) => e.stopPropagation()} style={{ width: "200px", alignItems: "start", backgroundColor: 'white', overflowY: "scroll", overflowX: 'hidden', zIndex: 99999 }}>
                                        {apiRequestPayload.materials.map((filterOption, index) => {
                                            return (
                                                <div className="option-for-mile-selection" onClick={(e) => {
                                                    if (filterOptions.includes(filterOption)) {
                                                        const filteredList = filterOptions.filter(i => i !== filterOption);
                                                        const filteredSecondList = filteredList.filter(i => i !== 'allChecked');
                                                        setFilterOptions(filteredSecondList)
                                                    } else {
                                                        if ([...filterOptions, filterOption].length === apiRequestPayload.materials.length) {
                                                            setFilterOptions(prev => [...prev, filterOption, 'allChecked'])
                                                        } else {
                                                            setFilterOptions(prev => [...prev, filterOption])
                                                        }
                                                    }
                                                }}>
                                                    <Checkbox
                                                        sx={{
                                                            color: '#1F5D5E',
                                                            '&.Mui-checked': {
                                                                color: '#1F5D5E',
                                                            },
                                                            padding: '0px',
                                                        }}
                                                        id="myCheckbox"
                                                        checked={filterOptions.includes(filterOption)}
                                                    />
                                                    <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{filterOption}</span>
                                                </div>
                                            )
                                        })}
                                        <div className="option-for-mile-selection" onClick={() => {
                                            if (!filterOptions.includes('allChecked')) {
                                                setFilterOptions([...apiRequestPayload.materials, 'allChecked'])
                                            } else {
                                                setFilterOptions([])
                                            }
                                        }}>
                                            <Checkbox
                                                sx={{
                                                    color: '#1F5D5E',
                                                    '&.Mui-checked': {
                                                        color: '#1F5D5E',
                                                    },
                                                    padding: '0px',
                                                }}
                                                id="myCheckbox"
                                                checked={filterOptions.includes('allChecked')}
                                            />
                                            <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>Select all</span>
                                        </div>
                                        <div className="filter-action-buttons">
                                            <span className="filter-close-button" onClick={() => {
                                                setIsFilterOptionEnabled(() => false);
                                                setisFilterActive(false)
                                                setAfterFilterLoactionsData([])
                                                setFilterOptions([])
                                            }}>
                                                {STRINGS.CLEAR}
                                            </span>
                                            <span className="filter-apply-button" onClick={() => {
                                                applyFilter()
                                            }}>
                                                {STRINGS.APPLY}
                                            </span>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    }
                    {loading ? <LocationsLoader /> :
                        isFilterActive ?
                            <>
                                {afterFilterLocationsData.length > 0 ?
                                    afterFilterLocationsData.map((value, index) => {
                                        return (
                                            <LocationCrad
                                                setActiveLoaction={setActiveLoaction}
                                                cardData={value}
                                                destinationLocation={destinationLocation}
                                                apiRequestPayload={apiRequestPayload}
                                                handleOnCardClick={handleOnCardClick}
                                                response={response}
                                                filterOptions={filterOptions}
                                                isFilterActive={isFilterActive}
                                                locationsData={locationsData}
                                                setLoactionsData={setLoactionsData}
                                                locationHistoryValues={locationHistoryValues}
                                                setLocationHistoryValues={setLocationHistoryValues}
                                                nearbyLocationsData={nearbyLocationsData}
                                                setNearbyLocationsData={setNearbyLocationsData}
                                            />
                                        )
                                    })
                                    :
                                    <p style={{ textAlign: "center" }}>{STRINGS.WE_COULD_NOT_FIND_ANY_SUPPLIER}</p>}
                            </>
                            :
                            (locationsData.length > 0 || isQueryDone || nearbyLocationsData.length > 0) ?
                                <>
                                    {
                                        [...locationsData, ...nearbyLocationsData].map((value, index) => {
                                            return (
                                                <>
                                                    {locationsData.length === index && !isFilterActive &&
                                                        <div className='location-card-alert' style={{ flex: 1 }}>
                                                            <div className='location-card-sub-div'>
                                                                <p>{locationsData.length === 0 ? " No results were found in your area." : 'Limited results in your area.'} Showing additional locations nearby</p>
                                                            </div>
                                                        </div>}
                                                    <LocationCrad
                                                        setActiveLoaction={setActiveLoaction}
                                                        cardData={value}
                                                        destinationLocation={destinationLocation}
                                                        apiRequestPayload={apiRequestPayload}
                                                        handleOnCardClick={handleOnCardClick}
                                                        response={response}
                                                        filterOptions={filterOptions}
                                                        isFilterActive={isFilterActive}
                                                        locationsData={locationsData}
                                                        setLoactionsData={setLoactionsData}
                                                        locationHistoryValues={locationHistoryValues}
                                                        setLocationHistoryValues={setLocationHistoryValues}
                                                        nearbyLocationsData={nearbyLocationsData}
                                                        setNearbyLocationsData={setNearbyLocationsData}
                                                    />
                                                </>
                                            )
                                        })
                                    }
                                    {(locationsData.length === 0 && nearbyLocationsData.length === 0 && isQueryDone) && <p style={{ textAlign: "center" }}>{STRINGS.WE_COULD_NOT_FIND_ANY_SUPPLIER}</p>}
                                </>
                                :
                                <></>
                    }
                </div>

                <div style={{ flex: 1, backgroundColor: loading && '#f2f3f5', borderRadius: '12px', overflow: "hidden", height: '100%', position: "relative" }}>
                    <div className="multiple-route-details">
                        {response?.availableRoutes.length > 0 && response?.availableRoutes.map((routeData, index) => {
                            let activeRoute = response?.activeRoute || 0
                            return (
                                <div key={index} className={`route-detail-card ${activeRoute === index && 'active-route-card'}`} onClick={() => {
                                    if (response?.availableRoutes.length > 0) {
                                        let deepCopy = JSON.parse(JSON.stringify(response))
                                        deepCopy.routes = [routeData]
                                        deepCopy.activeRoute = index
                                        setResponse(deepCopy)
                                        triggerSetPointerValue(routeData)
                                        setLocationHistoryValues(prev => {
                                            return { ...prev, response: deepCopy }
                                        })
                                    }
                                }}>
                                    <p>Route {index + 1}</p>
                                    <span>{routeData.legs[0].duration.text}</span>
                                </div>
                            )
                        })}
                    </div>
                    {((locationsData.length > 0 && destinationLocation && !loading) || isQueryDone) &&
                        <MapsComponent
                            response={response}
                            setResponse={setResponse}
                            activeLocation={activeLocation}
                            setActiveLoaction={setActiveLoaction}
                            destinationLocation={destinationLocation}
                            locationsData={isFilterActive ? afterFilterLocationsData : [...locationsData, ...nearbyLocationsData]}
                            pointerValue={pointerValue}
                            setPointerValue={setPointerValue}
                            handleOnCardClick={(value) => {
                                handleOnCardClick(value, true)
                            }} />
                    }
                </div>
            </div>
        </div>
    );
}

export default SearchMaterial;