import React, { useState, useEffect } from 'react'
import STRINGS from '../../../helpers/staticContentHelper';
import documentImg from '../../../assests/sonarWebImages/document.svg';
import emailImg from '../../../assests/sonarWebImages/gmailColored.svg';
import sadEmoji from '../../../assests/sonarWebImages/sademoji.svg'
import aImg from '../../../assests/sonarWebImages/autodesk.svg'
import autodeskWhiteImg from '../../../assests/sonarWebImages/autodeskWhite.svg'
import graphicMarkImg from '../../../assests/sonarWebImages/graphicMark.svg'
import dropBoxImg from '../../../assests/sonarWebImages/dropBox.svg'
import googleDriveImg from '../../../assests/sonarWebImages/googleDrive.svg'
import sageImg from '../../../assests/sonarWebImages/sage.svg'
import proCodeImg from '../../../assests/sonarWebImages/proCode.svg';
import egniteImg from '../../../assests/sonarWebImages/egnite.svg';
import sharePointImg from '../../../assests/sonarWebImages/sharePoint.svg';
import activeImg from '../../../assests/sonarWebImages/image.png'

const IMAGES = [proCodeImg, dropBoxImg, egniteImg, sageImg, aImg, emailImg, graphicMarkImg, googleDriveImg, sharePointImg];

const ACTIVE_CONTENT = [
    null,
    null,
    null,
    null,
    {
        title: STRINGS.MANUAL_SEARCHING,
        description: STRINGS.MATCHES_IN_A_PDF
    },
    {
        title: STRINGS.CONFUSING_EMAIL_THREADS,
        description: STRINGS.MULTIPLE_EMAIL_CHAINS
    },
    {
        title: STRINGS.CTRL_F,
        description: STRINGS.NO_MATCHES_FOUND
    },
    {
        title: STRINGS.HOURS_WEEK,
        description: STRINGS.SPENT_SEARCHING_FOR_INFORMATION
    },
]

const INITIAL_LEFT = [0, 1, 2, 3];
const INITIAL_RIGHT = [5, 6, 7, 8];

function ProblemSection() {
    const [firstSection, setFirstSection] = useState(INITIAL_LEFT);
    const [secondSection, setSecondSection] = useState(INITIAL_RIGHT);
    const [activeImage, setActiveImage] = useState(4);


    function generateUniqueNumber() {
        const timestamp = Date.now(); // Get the current timestamp in milliseconds
        const randomNum = Math.floor(Math.random() * 1000000); // Generate a random number between 0 and 999999
        return timestamp + randomNum; // Combine the timestamp and random number
    }

    function shiftElementsOfAnArray(array, setActiveImg = false) {
        let finalArray = []
        array.forEach((element, index) => {
            if (setActiveImg && index === 0) {
                setActiveImage(element)
                finalArray.push(element === 8 ? 0 : element + 1)
            } else {
                finalArray.push(element === 8 ? 0 : element + 1)
            }
        });
        return finalArray
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveImage(prev => {
                if (prev === 6) {
                    setFirstSection(prev => INITIAL_LEFT)
                    setSecondSection(prev => INITIAL_RIGHT)
                    return 4
                } else {
                    setFirstSection(prev => shiftElementsOfAnArray(prev))
                    setSecondSection(prev => shiftElementsOfAnArray(prev, true))
                }
            })
        }, 6000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className='web-problem-section'>
            <p className='web-section-sub-title'>{STRINGS.PROBLEM}</p>
            <h3 className='web-section-title'>
                {STRINGS.FINDING_RIGHT_INFO}
            </h3>

            <div className='web-problem-section-scroll-image-main-container' style={{ marginBottom: "200px" }}>
                <div className='active-image-container'>
                    <img src={activeImg} alt='no' className='active-image-container-image' />
                    <div className='active-document-right'>
                        <img src={documentImg} alt='no' />
                    </div>
                    <div className='active-document-top'>
                        <img src={documentImg} alt='no' />
                    </div>
                    <div className='active-document-left'>
                        <img src={documentImg} alt='no' />
                    </div>
                    <div key={generateUniqueNumber()} className='current-active-image-in-animate'>
                        <img src={activeImage === 4 ? autodeskWhiteImg : IMAGES[activeImage]} alt='no' />
                    </div>
                    <div className='connect-to-right-div' />
                    <div className='connect-to-top-div' />
                    <div className='connect-to-left-div' />

                    {
                        ACTIVE_CONTENT[activeImage] &&
                        <>
                            <div className='connect-to-bottom-div' />
                            <div className='active-document-bottom'>
                                <div className='information-of-active-img'>
                                    <img src={sadEmoji} alt='no' />
                                    <span>{ACTIVE_CONTENT[activeImage].title}</span>
                                </div>
                                <p className='information-of-active-image-desc'>{ACTIVE_CONTENT[activeImage].description}</p>
                            </div>
                        </>
                    }

                </div>

                <div className='web-infinite-scroll-image-container'>
                    <div className='animate-left-container'>
                        {firstSection.map((i, index) => {
                            return (
                                <div key={generateUniqueNumber()} className={`animate-image-box ${index === 0 && 'slow-disappear'}`}>
                                    <img src={IMAGES[i]} alt='no' />
                                </div>
                            )
                        })}
                    </div>
                    <div className='animate-right-container'>
                        {secondSection.map((i, index) => {
                            return (
                                <div key={generateUniqueNumber()} className={`animate-image-box  ${index === 3 && 'animate-in-image'}`}>
                                    <img src={IMAGES[i]} alt='no' />
                                </div>
                            )
                        })}``
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ProblemSection