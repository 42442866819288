export const PRIVACY_POLICY = `
<style>
    .subTitleStyle {
        font-size: 1rem;
        margin-top: 0rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
    }

    .titleStyle {
        font-size: 20px;
        margin-top: 1rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
        margin-bottom: 1.2rem;
        color: black;
    }

    span {
        font-weight: 600;
        color: black;
        font-family: 'Satoshi';
    }

    a{
    font-weight: 600;
    color: 'black';
    text-direction: underline;
    cursor: pointer;
          font-family: 'Satoshi';
    }

    ul{
        padding-left:0.8rem;
        margin:0;
        margin-bottom:1rem;
        font-family: 'Satoshi';
    }
</style>

<p class='subTitleStyle' style='margin-top:4rem'>
This policy explains how and why we collect, use, hold and disclose your personal information in the course of your access to
<a href='https://sonarlabs.ai/'
 target='__blank' > www.sonalrlabs.ai
</a> website (Site).
</p>

<p class='subTitleStyle'>
<span>“Company” , “we”, “us” </span> and “our” means <span>Siteworkz Private Limited</span>, a company incorporated under the laws of India bearing CIN U72600KA2021PTC150806 having registered office at #4, Vaibhava Centre, 3rd Floor, Ramanamaharshi Road, Opp Palace Grounds, Bengaluru – 560080, Karnataka, India.
</p>

<p class='subTitleStyle'>
You consent to us collecting, holding, using and disclosing your personal information in accordance with this policy.
</p>

<p class='subTitleStyle'>
For residents of India, Company endeavours to collect,
process and treat the Personal Information of its users in compliance with the provisions of the Information Technology Act,
2000, the Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules,
2011 and the Consumer Protection (E-Commerce) Rules, 2020.
</p>





<h5 class="titleStyle">SCOPE OF THE PRIVACY POLICY</h5>

<p class='subTitleStyle'>
This Privacy Policy describes the information that the Company,
as part of its normal operations, collects from you, stores, uses and protects.
The purpose of this Privacy Policy is to outline and help you understand various guidelines and procedures that are consistently followed by the Company when collecting,
storing, utilizing, disclosing or otherwise dealing with any Personal Information. By using the Site, you agree to be legally bound by this Privacy Policy, which shall take effect immediately on your first use of the Site.
</p>

<p class='subTitleStyle'>
Further, the Site may contain hyperlinks to websites operated by parties other than the Company which are developed by people over whom the Company exercises no control as well as the Site may contain social media plug ins.
This Privacy Policy does not apply to the practices of such third-party websites or social media websites.
Such other third-party/social media websites may place their own cookies on your computer/electronic device, collect data or solicit Personal Information.
Company is accordingly not responsible for the privacy practices or the content of such websites.
</p>

<p class='subTitleStyle'>
Any summary of this Privacy Policy generated by third party software or otherwise shall have no legal effect,
shall not be binding on the Company,
shall not be relied upon in substitute for this Privacy Policy,
and neither supersede nor modify this Privacy Policy.
</p>




<h5 class="titleStyle">WHAT IS PERSONAL INFORMATION?</h5>

<p class='subTitleStyle'>
Personal information is any information or an opinion about an identified individual or an individual who can be reasonably identified from the information or opinion.
Information or an opinion may be personal information regardless of whether it is true.
</p>





<h5 class="titleStyle">WHAT PERSONAL INFORMATION DO WE COLLECT AND HOLD? </h5>

<p class='subTitleStyle'>
Company primarily collects and stores the Personal Information, as would be provided by you in the course of creating your account as well as accessing and using the services provided by the Company through the Site.
With regard to creating an account and registering on the Site, the Company would generally process your name, phone number, e-mail address, age, date of birth,
account information like user name, password and device information like preferences, time zone, location data, etc.
</p>

<p class='subTitleStyle'>
Site, the Company may collect certain anonymous information from sources such as server log files, cookies and pixel tags.
Company may collect information concerning your access to and use of the Site such as what buttons/options you click on,
device you use to access the Site, device’s unique identifying number, operating system, device language, device region, device time zone,
mobile network information, etc. Company uses cookies or other similar software to collect data about the users to track their movements around the Site and in order to assist the users to automatically and effortlessly log in on subsequent visits to the Site.
A cookie is a small amount of data, which often includes an anonymous unique identifier, which is sent to a user’s browser from a website's computers and stored on the user’s computer's/electronic device’s hard drive. An application can send its own cookies to the user's browser if the user's browser's/device preference settings allow it.
You can change your privacy preferences regarding the use of cookies and similar technologies through your browser/device. You may set your browser/device to accept all cookies, block certain cookies, require your consent before a cookie is placed in your browser/device, or block all cookies.
Blocking all cookies will affect your online experience and may prevent you from enjoying the full features of the Site.
</p>

<p class='subTitleStyle'>
Company and/or third-party service providers on the Site may also use cookies to manage and measure the performance of advertisements displayed on or delivered through the Site.
This helps the Company as well as service providers to provide more relevant advertising.
</p>

<p class='subTitleStyle'>
Company also automatically receives and records information on the Company’s web server logs from user’s device including the Internet Protocol (IP) address. Company records IP addresses for anonymous statistical purposes,
administration of this Site and associated servers, and to improve the Company’s services.
</p>




<h5 class="titleStyle">WHY DO WE COLLECT, HOLD AND USE YOUR PERSONAL INFORMATION?</h5>

<p class='subTitleStyle'>
We collect, hold and use your personal information so that we can:
</p>

<p class='subTitleStyle'>
(a)	provide you with services, and manage our relationship with you;
</p>

<p class='subTitleStyle'>
(b)	contact you, for example, to respond to your queries or complaints, or if we need to tell you something important;
</p>

<p class='subTitleStyle'>
(c)	comply with our legal obligations and assist government and law enforcement agencies or regulators; or
</p>

<p class='subTitleStyle'>
(d)	identify and tell you about other services that we think may be of interest to you.
</p>

<p class='subTitleStyle'>
If you do not provide us with your personal information, we may not be able to provide you with our services,
communicate with you or respond to your enquiries.
</p>




<h5 class="titleStyle">HOW DO WE COLLECT YOUR PERSONAL INFORMATION?  </h5>

<p class='subTitleStyle'>
We will collect your personal information directly from you whenever you interact with us.
</p>

<p class='subTitleStyle'>
We may collect information from third parties such as:
</p>

<p class='subTitleStyle'>
(a)	Google and Microsoft.
</p>





<h5 class="titleStyle">HOW DO WE STORE AND HOLD PERSONAL INFORMATION?</h5>

<p class='subTitleStyle'>
We store most information about you in computer systems and databases operated by either us or our external service providers.
Some information about you is recorded in paper files that we store securely.
</p>

<p class='subTitleStyle'>
We implement and maintain processes and security measures to protect personal information which we hold from misuse,
interference or loss, and from unauthorised access,
modification or disclosure.
</p>

<p class='subTitleStyle'>
These processes and systems include:
</p>

<p class='subTitleStyle'>
(a)	the use of identity and access management technologies to control access to systems on which information is processed and stored;
</p>

<p class='subTitleStyle'>
(b)	requiring all employees to comply with internal information security policies and keep information secure;
</p>

<p class='subTitleStyle'>
(c)	requiring all employees to complete training about information security; and
</p>

<p class='subTitleStyle'>
(d)	monitoring and regularly reviewing our practice against our own policies and against industry best practices.
</p>

<p class='subTitleStyle'>
We will also take reasonable steps to destroy or de-identify personal information once we no longer require it for the purposes for which it was collected or for any secondary purpose permitted under the APPs.
</p>




<h5 class="titleStyle">WHO DO WE DISCLOSE YOUR PERSONAL INFORMATION TO, AND WHY?</h5>

<p class='subTitleStyle'>
We may transfer or disclose your personal information to our related companies.
</p>

<p class='subTitleStyle'>
We may disclose personal information to external service providers so that they may perform services for us or on our behalf.
</p>

<p class='subTitleStyle'>
We may also disclose your personal information to others outside our group of companies where:
</p>

<p class='subTitleStyle'>
(a)	we are required or authorised by law to do so;
</p>

<p class='subTitleStyle'>
(b)	you may have expressly consented to the disclosure or the consent may be reasonably inferred from the circumstances; or
</p>

<p class='subTitleStyle'>
(c)	we are otherwise permitted to disclose the information under the Privacy Act.
</p>

<p class='subTitleStyle'>
If the ownership or control of all or part of our business changes, we may transfer your personal information to the new owner.
</p>



<h5 class="titleStyle">DO WE DISCLOSE PERSONAL INFORMATION TO OVERSEAS RECIPIENTS?  </h5>

<p class='subTitleStyle'>
We may disclose your personal information to recipients which are located outside India and United States of America.
</p>

<p class='subTitleStyle'>
Those recipients are likely to be located in [DETAILS OF COUNTRIES].
</p>




<h5 class="titleStyle">DO WE USE YOUR PERSONAL INFORMATION FOR MARKETING?</h5>

<p class='subTitleStyle'>
We will use your personal information to offer you products and services we believe may interest you, but we will not do so if you tell us not to.
These products and services may be offered by us, our related companies, our other business partners or our service providers.
</p>

<p class='subTitleStyle'>
Where you receive electronic marketing communications from us, you may opt out of receiving further marketing communications by following the opt-out instructions provided in the communication.
</p>




<h5 class="titleStyle">ACCESS TO AND CORRECTION OF YOUR PERSONAL INFORMATION</h5>

<p class='subTitleStyle'>
You may access or request correction of the personal information that we hold about you by contacting us.
Our contact details are set out below.
There are some circumstances in which we are not required to give you access to your personal information.
</p>

<p class='subTitleStyle'>
There is no charge for requesting access to your personal information,
but we may require you to meet our reasonable costs in providing you with access (such as photocopying costs or costs for time spent on collating large amounts of material).
</p>

<p class='subTitleStyle'>
We will respond to your requests to access or correct personal information in a reasonable time and will take all reasonable steps to ensure that the personal information remains accurate and up to date.
</p>





<h5 class="titleStyle">USER RIGHTS FOR CALIFORNIA CITIZENS</h5>

<p class='subTitleStyle'>
California residents shall be able to exercise certain privacy rights relating to their Personal Information under the California Consumer Privacy Act,
2018 (CCPA) with effect from January 1, 2020 and the California Privacy Rights Act, 2020 (CPRA) with effect from January 1, 2023.
If you are a resident of California, the rights that apply to you are outlined herein below.
</p>

<p class='subTitleStyle'>
(a)	Right to Information Regarding the Collection, Use, and Sale of Personal Information
</p>

<p class='subTitleStyle'>
The categories of Personal Information we collect,
the methods by which we gather and utilise such information,
and the methods by which we disclose your Personal Information are all generally defined in this Privacy Policy.
In addition to the rights outlined above, as a resident of California,
you have the right to inquire more about the following matters for the previous 12 months, pertaining to:
</p>

<ul>
<li class='subTitleStyle'>
    The general and detailed pieces of your Personal Information available with us;
</li>
<li class='subTitleStyle'>
    The sources from which we procured your Personal Information;
</li>
<li class='subTitleStyle'>
    The reasons why we have acquired your Personal Information;
</li>
<li class='subTitleStyle'>
    The categories of third parties to whom we share your Personal Information with; or
</li>
<li class='subTitleStyle'>
    The categories of Personal Information we have shared with third parties about you.
</li>
</ul>

<p class='subTitleStyle'>
(b)	Right to Deletion of Data
</p>

<p class='subTitleStyle'>
You have the right to ask us to delete any Personal Information that we possess about you.
Upon receiving your request, we will inform you if your data can be deleted and to the extent possible,
we will instruct all third parties with whom we have shared your Personal Information with to delete the same.
We may not be able to erase your Personal Information or direct our third parties to do so in certain situations.
Examples of these situations include, but are not limited to, the following:
</p>
<ul class='subTitleStyle'>
<li class='subTitleStyle'>
    If we need to retain your Personal Information in order to provide you our services, to complete a transaction or fulfil the requirements of a contract between us;
</li>
<li class='subTitleStyle'>
    In order to identify security issues; defend against malicious, misleading, duplicitous, or illegitimate behaviour; or to bring legal action against those responsible;
</li>
<li class='subTitleStyle'>
    In order to find and fix faults that interfere with the functionality as intended;
</li>
<li class='subTitleStyle'>
    To ensure that it does not impede, exercise or the right to exercise free speech of another customer;
</li>
<li class='subTitleStyle'>
    If storage of the data is required by the law; or
</li>
<li class='subTitleStyle'>
    The storage of your Personal Information is essential for internal and lawful purposes.
</li>
</ul>

<p class='subTitleStyle'>
(c)	Right to Opt-Out of the Sale/Sharing of Personal Information
</p>

<p class='subTitleStyle'>
There is no need to submit any request to the company pursuant to opting-out of the sale of your Personal Information as we do not indulge in selling of your Personal Information to third parties.
If you wish to opt-out of sharing of your Personal Information with third parties please feel free to contact us.
</p>

<p class='subTitleStyle'>
(d)	Right to Exercise your Privacy Rights without any Discrimination
</p>

<p class='subTitleStyle'>
The Company shall not discriminate against you, if you wish to exercise any of your rights provided under CCPA and CPRA,
including but not limited to: <br /> (1) refusal to provide services to you; <br />
(2) levying of differential pricing for our services; or <br />
(3) providing you with services of different level or quality.
</p>

<p class='subTitleStyle'>
(e)	Right to use an Authorized Agent
</p>

<p class='subTitleStyle'>
To exercise the rights mentioned above, you may employ an authorised agent to tender requests on your behalf.
Please mention whether you are an authorised agent when contacting us to exercise any of rights under CCPA or CPRA.
If so, we will provide you guidance on how to verify and handle your request.
</p>

<p class='subTitleStyle'>
(f)	Right to Correction
</p>

<p class='subTitleStyle'>
If you wish to amend any or correct any Personal Information stored with us,
please feel free to contact us at the details stated below.
</p>

<p class='subTitleStyle'>
(g)	Right to Data Portability
</p>

<p class='subTitleStyle'>
You may send in your request to transmit your Personal Information to another organisation.
We will intimate you regarding the status of such a request after considering the technical feasibility of such transmission for the Company.
</p>

<p class='subTitleStyle'>
(h)	Right to Access Information about Automated Decision Making
</p>

<p class='subTitleStyle'>
You may request the Company to share information about automated decision-making processes linked with your Personal Information.
</p>

<p class='subTitleStyle'>
(i)	Right to Opt-Out of Automated Decision-Making Technology
</p>

<p class='subTitleStyle'>
You may send in a request to the Company to opt-out of the use of automated decision-making technology.
</p>




<h5 class="titleStyle">RESTRICTIVE COVENANTS</h5>


<p class='subTitleStyle'>
The Company hereby cautions and informs that you are not permitted to use the Site to host,
display, upload, modify, publish, transmit, update or share any information that:
</p>

<p class='subTitleStyle'>
(a)	belongs to another person and to which you do not have any right;
</p>

<p class='subTitleStyle'>
(b)	is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, paedophilic,
libellous, invasive of another's privacy, hateful, or racially, ethnically objectionable, disparaging,
relating or encouraging money laundering or gambling, or otherwise unlawful in any manner whatever;
</p>

<p class='subTitleStyle'>
(c)	harm’s minors in any way;
</p>

<p class='subTitleStyle'>
(d)	infringes any patent, trademark, copyright or other proprietary rights;
</p>

<p class='subTitleStyle'>
(e)	violates any law for the time being in force;
</p>

<p class='subTitleStyle'>
(f)	deceives or misleads the addressee about the origin of such messages or communicates any information which is grossly offensive or menacing in nature;
</p>

<p class='subTitleStyle'>
(g)	impersonates another person;
</p>

<p class='subTitleStyle'>
(h)	contains software viruses or any other computer code,
files or programs designed to interrupt,
destroy or limit the functionality of any computer resource;
</p>

<p class='subTitleStyle'>
(i)	threatens the unity, integrity, defence, security or sovereignty of India, friendly relations with foreign states,
or public order or causes incitement to the commission of any cognisable offence or prevents investigation of any offence or is insulting any other nation;
</p>

<p class='subTitleStyle'>
(j)	threatens public health or safety; promotion of cigarettes or any other tobacco products or consumption of intoxicant including alcohol and Electronic Nicotine Delivery System (ENDS) & like products that enable nicotine delivery except for the purpose & in the manner and to the extent, as may be approved under the (Indian) Drugs and Cosmetics Act,
1940 and Rules made thereunder or any other similar laws in any jurisdiction other than India; and
</p>

<p class='subTitleStyle'>
(k)	threatens critical information infrastructure.
</p>



<p class='subTitleStyle'>
In addition, the Company further cautions you to prohibit from:
</p>

<p class='subTitleStyle'>
(a)	violating or attempting to violate the integrity or security of the Site or any content on the Site;
</p>

<p class='subTitleStyle'>
(b)	transmitting any information (including job posts, messages and hyperlinks) on or
through the Site that is disruptive or competitive to the operation of the Site by the Company;
</p>

<p class='subTitleStyle'>
(c)	intentionally submitting on the Site any incomplete, false or inaccurate information;
</p>

<p class='subTitleStyle'>
(d)	making any unsolicited communications to other users of the Site;
</p>

<p class='subTitleStyle'>
(e)	using any engine, software, tool,
agent or other device or mechanism (such as spiders, robots, avatars or intelligent agents) to navigate or search the Site;
</p>

<p class='subTitleStyle'>
(f)	attempting to decipher, decompile, disassemble or reverse engineer any part of the Site;
</p>

<p class='subTitleStyle'>
(g)	copying or duplicating in any manner any of the Company’s content or other information available on the Site;
</p>

<p class='subTitleStyle'>
(h)	framing or hotlinking or deep-linking any content on the Site; and
</p>

<p class='subTitleStyle'>
(i)	posting materials to any of the the Company’s social media pages or otherwise on social media, that are unlawful, fraudulent, libellous,
slanderous, defamatory, abusive, harassing, threatening, obscene or infringing on the rights of any third-party,
including intellectual property, privacy/confidentiality or publicity rights.
</p>






<h5 class="titleStyle">COMPLAINTS</h5>

<p class='subTitleStyle'>
If you have a complaint about the way in which we have handled any privacy issue,
including your request for access or correction of your personal information,
you should contact us. Our contact details are set out below.
</p>

<p class='subTitleStyle'>
We will consider your complaint and determine whether it requires further investigation.
We will notify you of the outcome of this investigation and any subsequent internal investigation.
</p>





<h5 class="titleStyle">CONTACT DETAILS  </h5>

<p class='subTitleStyle'>
If you have any questions, comments, requests or concerns, please contact us at: <br /> <br />
hello@sonarlabs.ai
</p>




<h5 class="titleStyle">CHANGES TO THIS POLICY </h5>

<p class='subTitleStyle'>
From time to time, we may change our policy on how we handle personal information or the types of personal information which we hold.
Any changes to our policy will be published on our website.
</p>

<p class='subTitleStyle'>
You may obtain a copy of our current policy from our website or by contacting us at the contact details above.
</p>

`