import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem'
};
export default function VideoModal({ isModalOpen, setIsModalOpen, videoUrl }) {
    const handleClose = () => setIsModalOpen(false);
    return (
        <Modal
            open={isModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={{ ...style, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '1rem' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setIsModalOpen(false)} />
                </Box>
                <img src={videoUrl} style={{ maxWidth: '100%', maxHeight: '70vh', borderRadius: '0.5rem' }} alt='no' />
            </Box>
        </Modal>
    );
}