import { getApiEndpoints } from "./api.endpoints";
import { API, handleApiResponse } from "./axios.config";
import { encryptPayload } from "../utils/encryptionDecryption";
import { getBooleanFromLocalStorage } from "./user.services";
const abortController = new AbortController();

export const REQUEST_QUERY = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.ASK_QUESTIONS, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const ASK_QUESTIONS_AUTH = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.ASK_QUESTIONS_AUTH, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const MULTI_PDF_SEARCH = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.MULTI_PDF_SEARCH, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const SUGGESTION_QUESTIONS_AUTH = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.SUGGESTION_QUESTIONS_AUTH, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}
export const SUGGESTION_QUESTIONS = async () => {

    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.SUGGESTION_QUESTIONS).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const HIGHLIGHTED_PDF = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.GET_HIGHLIGHTED_PDF, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const GET_HIGHLIGHTED_MULTIPLE_PDF = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);
    const res = await API.post(API_END_POINT.GET_HIGHLIGHTED_MULTIPLE_PDF, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}


export const GET_PDF_FILE_DETAILS = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.GET_PDF_FILE_DETAILS + data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}