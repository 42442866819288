import React, { useEffect, useState } from 'react'
import DoubleArrow from '../../assests/doubleArrow.svg';
import STRINGS from '../../helpers/staticContentHelper';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FETCH_FILE_HISTORY, FETCH_PROJECT_HISTORY } from '../../services';
import moment from 'moment';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { decryptResponse } from '../../utils/encryptionDecryption';
function HistoryModal({ setIsModalOpen, isModalOpen, selectedRow, setSearchParams, setSelectedFile, isFromProject }) {
    const params = useParams();
    const { projectId } = params;
    const [threads, setThreads] = useState([])
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()

    const handleModal = () => {
        setIsModalOpen(false)
        setThreads([])
    }

    const truncateResult = (result = '') => {
        if (result.length > 180) {
            return result.slice(0, 180) + '...'
        }
        return result
    }

    useEffect(() => {
        if (isModalOpen) {
            setIsLoading(true)
            if (projectId) {
                FETCH_FILE_HISTORY(selectedRow?.file_id).then(res => {
                    if (res) {
                        const decryptedData = decryptResponse(res)
                        const parsedDecryptedData = JSON.parse(decryptedData)
                        if (parsedDecryptedData) {
                            parsedDecryptedData.sort((left, right) => {
                                return moment.utc(right.created_at).diff(moment(left.created_at))
                            })
                            setThreads(parsedDecryptedData)
                        }
                        setIsLoading(false)
                    }

                })
            }
            else {
                FETCH_PROJECT_HISTORY(selectedRow?.project_id).then(res => {
                    if (res) {
                        const decryptedData = decryptResponse(res)
                        const parsedDecryptedData = JSON.parse(decryptedData)
                        if (parsedDecryptedData) {
                            parsedDecryptedData.sort((left, right) => {
                                return moment.utc(right.created_at).diff(moment(left.created_at))
                            })
                            setThreads(parsedDecryptedData)
                        }
                        setIsLoading(false)
                    }
                })
            }
        }
    }, [isModalOpen])

    return (
        <div className={`pdf-side-view-modal-absolute`}
            style={{
                width: isModalOpen ? '100%' : '0px',
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                borderRadius: '0px',
                height: 'calc(100vh - 1px)'
            }} onClick={handleModal} >
            <div className={`pdf-side-view-modal pdf-side-view-modal-absolute`} style={{ width: isModalOpen ? '600px' : '0px', height: 'calc(100vh - 0.2rem)' }}
                onClick={(e) => e.stopPropagation()}>
                {isModalOpen &&
                    <>
                        <div className='pdf-side-view-modal-header'>
                            <div className='double-arrow-with-title'>
                                <img src={DoubleArrow} alt='not-found' onClick={handleModal} />
                                <p>History</p>
                            </div>
                            <p className='records-found-text'>{STRINGS.PROJECT}- {selectedRow?.project_name ?? searchParams.get('projectName')}</p>
                        </div>
                        <div className='project-level-history'>
                            {isLoading ? <div className='no-projects-found'>
                                <div class="loading-dots">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                </div>
                            </div>
                                :
                                threads?.length > 0 ?
                                    threads?.map((thread, i) => {
                                        return (
                                            <div className='project-level-history-card' key={i} onClick={() => {
                                                if (isFromProject) {
                                                    localStorage.setItem('fileDetails', JSON.stringify(thread.file))
                                                    navigate(`/project-files/${thread.file.project.project_id}?projectName=${thread.file.project.project_name}&threadId=${thread?.thread_id}&activeTab=Home`)
                                                } else {
                                                    localStorage.setItem('fileDetails', JSON.stringify(thread.file))
                                                    searchParams.set("threadId", thread?.thread_id);
                                                    searchParams.set("activeTab", 'Home');
                                                    setSearchParams(searchParams)
                                                }
                                            }}>
                                                <p className='project-level-history-file-name'><span>{STRINGS.FILE}:</span> {thread?.file?.file_name}</p>
                                                <p className='project-level-history-question'>{thread?.thread_name} </p>
                                                <Markdown remarkPlugins={[[remarkGfm]]} className='mark-up'>
                                                    {truncateResult(thread?.first_response)}
                                                </Markdown>
                                            </div>
                                        )
                                    })
                                    :
                                    <div className='project-level-no-history'>No history found</div>
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default HistoryModal