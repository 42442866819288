import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LStorage } from '../../utils/localStorage.Helper';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem'
};

export default function ShowProfileDetails({ openFolderModal, setOpenFolderModal }) {
    const handleClose = () => setOpenFolderModal(false);
    const userData = LStorage.getUserData()

    return (
        <Modal
            open={openFolderModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
        >
            <Box sx={{ ...style, display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setOpenFolderModal(false)} />
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                    Name : <span>  {userData?.first_name} {userData?.last_name}</span>
                </Typography>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
                    Email :  <span>  {userData?.email}</span>
                </Typography>
            </Box>
        </Modal>
    );
}