import React, { useEffect, useState } from 'react'
import { ReactComponent as GobeImg } from "../assets/globe.svg";
import { ReactComponent as EmailImg } from "../assets/email.svg";
import { ReactComponent as PhoneImg } from "../assets/phone.svg";
import { ReactComponent as VerifiedImg } from "../assets/verified.svg";
import { ReactComponent as WebsiteImg } from "../assets/website.svg";
import { ReactComponent as BlockMaterial } from "../assets/blockMaterial.svg";
import { ReactComponent as FavMaterial } from "../assets/favorite.svg";
import { ReactComponent as BlockListedMaterial } from "../assets/blocked.svg";
import { ReactComponent as RoundStartBlue } from "../assets/roundStart.svg";
import ListOfUsersModal from './listOfUsersModal';
import AddToFavList from './addToFavList';
import AddToBloackList from './addToBlockList';
import STRINGS from '../../../helpers/staticContentHelper';
import { checkHasBloackListAggrigates, checkHasFavAggrigates } from '../../../utils/encryptionDecryption';
import { LStorage } from '../../../utils/localStorage.Helper';
export const center_flex = {
    display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: "0.5rem"
}

function LocationCrad({ locationsData,
    locationHistoryValues, setLocationHistoryValues,
    setNearbyLocationsData, nearbyLocationsData,
    setLoactionsData, filterOptions = [], isFilterActive, handleOnCardClick, setActiveLoaction, cardData, staticRrender = false, response, hideOnHover = false }) {
    const userData = LStorage.getUserData()
    // Find the index of the first colon
    const colonIndex = cardData?.openHours?.currentTiming?.indexOf(":");
    // Split the string using substring
    const label = cardData?.openHours?.currentTiming?.substring(0, colonIndex);
    const rest = cardData?.openHours?.currentTiming?.substring(colonIndex + 1).trim();

    const [isFavPopupOpen, setIsFavPopupOpen] = useState(false);
    const [showWishListAndBlacklist, setShowWishListAndBlacklist] = useState(false);
    const [showFavFilled, setShowFavFilled] = useState(false)
    const [isBloackListPopupOpen, setIsBloackListPopupOpen] = useState(false);
    const [viewUsersPopupList, setViewUsersPopupList] = useState(false);
    const [showBlackListed, setShowBlackListed] = useState(false);
    const [favAndBlackListedData, setFavAndBlackListedData] = useState({
        blacklisted: {
            name: '',
            count: 0,
            isCurrentUserPresent: false
        },
        favorites: {
            name: '',
            count: 0,
            isCurrentUserPresent: false
        }
    });

    const checkHasFavAndSamePerson = (markedMaterials = [], checkValue) => {
        if (cardData?.favouriteFacility?.whole_facility && (cardData?.favouriteFacility?.type === 'personal' || cardData?.favouriteFacility?.type === 'individual')) {
            return true
        }
        const hasAggregates = markedMaterials.some(material =>
            material.favorites.some(favorite => (favorite.material === checkValue && (favorite.type === 'personal' || favorite.type === 'individual')))
        );
        return hasAggregates
    }

    const checkHasFavAggrigatesIn = (markedMaterials = [], checkValue) => {
        if (cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'company') {
            return true
        }
        const hasAggregates = markedMaterials.some(material =>
            material.favorites.some(favorite => (favorite.material === checkValue && favorite.type === 'company'))
        );

        return hasAggregates
    }

    const checkHasBlockListAggrigates = (markedMaterials = [], checkValue) => {
        const hasAggregates = markedMaterials.some(material =>
            material.blacklisted.some(blacklist => blacklist.material === checkValue)
        );
        return hasAggregates
    }
    useEffect(() => {
        if (cardData.markedMaterials) {
            let favList = {
                name: '',
                count: 0,
                isCurrentUserPresent: false
            };
            let blackList = {
                name: '',
                count: 0,
                isCurrentUserPresent: false
            };
            let isCompanyFavOrBlacklistIncluded = false;
            cardData.markedMaterials.forEach(markedMaterial => {
                const filteredFavList = []
                const filteredBlackList = []

                if (markedMaterial.marked_by.email === userData.email) {
                    if (markedMaterial.blacklisted.length > 0) {
                        blackList.isCurrentUserPresent = true
                    }
                    if (markedMaterial.favorites.length > 0) {
                        favList.isCurrentUserPresent = true
                    }
                }

                markedMaterial.favorites.forEach(material => {
                    if (material.available) {
                        if (material.type === 'company') {
                            isCompanyFavOrBlacklistIncluded = true
                        }
                        filteredFavList.push(material)
                    }
                })

                markedMaterial.blacklisted.forEach(material => {
                    if (material.available) {
                        if (material.type === 'company') {
                            isCompanyFavOrBlacklistIncluded = true
                        }
                        filteredBlackList.push(material)
                    }
                })

                if (filteredFavList.length > 0) {
                    if (!favList.name) favList.name = markedMaterial.marked_by.name
                    favList.count++
                }
                if (filteredBlackList.length > 0) {
                    if (!blackList.name) blackList.name = markedMaterial.marked_by.name
                    blackList.count++
                }
            })
            setShowWishListAndBlacklist(isCompanyFavOrBlacklistIncluded)
            setFavAndBlackListedData({
                blacklisted: blackList,
                favorites: favList
            })

            // check all the materials are fav 
            let finalSortedList = [...checkHasFavAggrigates(cardData?.markedMaterials), ...checkHasBloackListAggrigates(cardData?.markedMaterials)];
            const containsAll = cardData?.categories.every(element => finalSortedList.includes(element));
            if (finalSortedList.length > 0 && containsAll) {
                setShowFavFilled(true)
            } else if (cardData.favouriteFacility && cardData.favouriteFacility.whole_facility) {
                setShowFavFilled(true)
            }
        }
    }, [cardData])

    const checkIsWholeFacility = () => {
        if (cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'company') {
            return true
        } else if (cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'personal') {
            return true
        } else if (cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'individual') {
            return true
        } else {
            return false
        }
    }

    return (
        <div className="location-detail-card" id={`${cardData.materialPlaceId}`} style={{ backgroundColor: (response?.materialPlaceId === cardData.materialPlaceId && !hideOnHover) && '#F6F7FA', border: response?.materialPlaceId === cardData.materialPlaceId ? '1px solid #8fb7b5' : '1px solid #dddada' }} onClick={() => {
            if (!staticRrender) {
                handleOnCardClick(cardData)
            }
        }}
            onMouseEnter={() => {
                if (!staticRrender) {
                    setActiveLoaction(cardData.id)
                }
            }}
            onMouseLeave={() => {
                if (!staticRrender) {
                    setActiveLoaction(null)
                }
            }}>

            {isFavPopupOpen &&
                <AddToFavList
                    locationsData={locationsData}
                    setLoactionsData={setLoactionsData}
                    open={isFavPopupOpen}
                    setOpen={setIsFavPopupOpen}
                    cardData={cardData}
                    locationHistoryValues={locationHistoryValues}
                    setLocationHistoryValues={setLocationHistoryValues}
                    isIndividualUser={!userData.org_id}
                    nearbyLocationsData={nearbyLocationsData}
                    setNearbyLocationsData={setNearbyLocationsData}
                />
            }
            {isBloackListPopupOpen &&
                <AddToBloackList
                    isIndividualUser={!userData.org_id}
                    locationHistoryValues={locationHistoryValues}
                    setLocationHistoryValues={setLocationHistoryValues}
                    locationsData={locationsData}
                    setLoactionsData={setLoactionsData}
                    open={isBloackListPopupOpen}
                    setOpen={setIsBloackListPopupOpen}
                    cardData={cardData}
                    nearbyLocationsData={nearbyLocationsData}
                    setNearbyLocationsData={setNearbyLocationsData}
                />
            }
            {viewUsersPopupList &&
                <ListOfUsersModal
                    open={viewUsersPopupList}
                    setOpen={setViewUsersPopupList}
                    usersList={cardData.markedMaterials || []}
                    showBlackListed={showBlackListed}
                    isWholeFacilityMarked={cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'company'}
                />
            }

            {cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'personal' &&
                <div className='location-card-status'>
                    <RoundStartBlue className='star-green' style={{ marginRight: "0.2rem" }} />
                    {STRINGS.PERSONAL_FAVORITE}
                </div>
            }

            {cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'individual' &&
                <div className='location-card-status'>
                    <RoundStartBlue className='star-green' style={{ marginRight: "0.2rem" }} />
                    {STRINGS.PERSONAL_FAVORITE}
                </div>
            }

            {cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'company' &&
                <div className='location-card-status'>
                    <RoundStartBlue style={{ marginRight: "0.2rem" }} />
                    {STRINGS.COMPANY_FAVORITE}
                </div>
            }

            <div className="location-card-title">
                <div className='location-card-name'>
                    <p style={{ maxWidth: "440px", margin: "0px"}}>{cardData?.name}
                        {cardData?.sonarVerified &&
                            <div className='is-verified-by-sonar'>
                                <VerifiedImg style={{ position: "absolute", top: '-1rem', left: "0.2rem" }} />
                                <p className='verified-by-sonar-tooltip'>{STRINGS.VERIFIED_BY_SONAR_LABS}</p>
                            </div>}
                    </p>
                </div>
                {!staticRrender && !showFavFilled &&
                    <div style={{ display: 'flex' }} >
                        <div className='location-card-options'
                            style={{ display: 'flex' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                setIsBloackListPopupOpen(true)
                            }}>
                            <BlockMaterial height='15px' />
                            <p style={{ right: '-35px', top: '30px' }} className='verified-by-sonar-tooltip'>{STRINGS.BLACKLIST}</p>
                        </div>
                        <div className='location-card-options'
                            style={{ display: 'flex' }}
                            onClick={(e) => {
                                e.stopPropagation()
                                if (!showFavFilled) {
                                    setIsFavPopupOpen(true)
                                }
                            }}>
                            <FavMaterial height='15px' />
                            <p style={{ right: '-0px', top: '30px', zIndex: 1 }} className='verified-by-sonar-tooltip'>{STRINGS.FAVORITE}</p>
                        </div>
                    </div>}
            </div>

            {cardData?.openHours?.currentTiming &&
                <div className='detail-with-heading-for-location'>
                    <span style={{ color: label === 'Open' ? 'green' : 'tomato' }}>{label}:</span>
                    <p>{rest}</p>
                </div>}
            <div className='detail-with-heading-for-location'>
                <span>{STRINGS.ADDRESS_LABEL}</span>
                <p>{cardData.address}</p>
            </div>

            {(cardData?.officePhone || cardData?.website) &&
                <div className='detail-with-heading-for-location'>
                    <span>{STRINGS.OFFICE_LABEL}</span>
                    {cardData?.officePhone &&
                        <div style={center_flex}>
                            <PhoneImg />
                            <p>{cardData?.officePhone}
                            </p>
                        </div>
                    }
                    {cardData?.website &&
                        <div style={{ ...center_flex }}>
                            <WebsiteImg />
                            <a href={cardData?.website} target="_blank" rel="noopener noreferrer" style={{ textDecorationColor: '#666666' }}>
                                <p>{STRINGS.WEBSITE_TEXT}</p>
                            </a>
                        </div>
                    }
                </div>
            }
            {(cardData?.phone || cardData?.contact_name || cardData?.email) &&
                <div className='detail-with-heading-for-location'>
                    <span>{STRINGS.SALES_LABEL}</span>
                    {cardData.contact_name &&
                        <p>{cardData.contact_name}</p>
                    }
                    {cardData?.phone &&
                        <div style={center_flex}>
                            <PhoneImg />
                            <p> {cardData?.phone}</p>
                        </div>
                    }
                    {cardData.email &&
                        <div style={{ ...center_flex }}>
                            <EmailImg />
                            <p> {cardData?.email}</p>
                        </div>
                    }
                </div>
            }

            <div className='location-search-more-details'>
                {cardData?.categories.length > 0 &&
                    cardData?.categories.map(category => {
                        return (
                            <div className={`${filterOptions.includes(category) && isFilterActive && 'active-location-search-detail-card'} location-search-detail-card`}>
                                {cardData?.markedMaterials && !checkIsWholeFacility() ?
                                    checkHasFavAggrigatesIn(cardData?.markedMaterials, category) ?
                                        <RoundStartBlue /> :
                                        checkHasFavAndSamePerson(cardData?.markedMaterials, category) ?
                                            <RoundStartBlue className='star-green' /> :
                                            checkHasBlockListAggrigates(cardData?.markedMaterials, category) ?
                                                <BlockListedMaterial /> : <></> : <></>
                                }
                                <p> {category}</p>
                            </div>
                        )
                    })}
            </div>

            <div>
                {((favAndBlackListedData.favorites.count > 0 || favAndBlackListedData.blacklisted.count > 0) && showWishListAndBlacklist) && !checkIsWholeFacility() &&
                    <div className='location-card-alert' style={{ flex: 1 }}>
                        <div className='location-card-sub-div'>
                            <RoundStartBlue />
                            <p>Materials have been marked at this facility</p>
                        </div>
                        <span onClick={(e) => {
                            e.stopPropagation();
                            setViewUsersPopupList(true)
                            setShowBlackListed(false)
                        }}>View</span>
                    </div>
                }

                {cardData?.favouriteFacility?.whole_facility && cardData?.favouriteFacility?.type === 'company' &&
                    <div className='location-card-alert' style={{ flex: 1 }}>
                        <div className='location-card-sub-div'>
                            <RoundStartBlue />
                            <p>{STRINGS.THIS_FACILITY_HAS_BEEN_MARKED}</p>
                        </div>
                        <span onClick={(e) => {
                            e.stopPropagation();
                            setViewUsersPopupList(true)
                            setShowBlackListed(false)
                        }}>View</span>
                    </div>
                }
            </div>

        </div>
    )
}

export default LocationCrad