import React from 'react'
import DoubleArrow from '../../../assests/modalCross.svg';
import MapsComponent from './mapsComponent';
import STRINGS from '../../../helpers/staticContentHelper';
import { ReactComponent as BlockListedMaterial } from "../assets/blocked.svg";
import { ReactComponent as FavMaterial } from "../assets/favorite.svg";
import FavAndBlackListCard from './favAndBlacklistCard';

function DetailModal({ setIsModalOpen, isModalOpen, carddata, isBlackList = false , type }) {
    const handleModal = () => {
        setIsModalOpen(false)
    }

    return (
        <div className={`pdf-side-view-modal-absolute`}
            style={{
                width: isModalOpen ? '100%' : '0px',
                backgroundColor: "rgba(0, 0, 0, 0.2)",
                borderRadius: '0px',
                height: 'calc(100vh)'
            }} onClick={handleModal} >
            <div className={`pdf-side-view-modal pdf-side-view-modal-absolute`} style={{ width: isModalOpen ? '600px' : '0px', height: 'calc(100vh - 0.2rem)' }}
                onClick={(e) => e.stopPropagation()}>
                {isModalOpen &&
                    <>
                        <div className='pdf-side-view-modal-header'>
                            <div className='double-arrow-with-title' style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>

                                <div className='location-card-options'
                                    style={{ backgroundColor: !isBlackList ? '#ABF5D180' : "#FFDDD5", display: "inline-flex" }} >
                                    {isBlackList ? <BlockListedMaterial /> :
                                        <FavMaterial />
                                    }
                                </div>
                                <p> {STRINGS.DETAILS}</p>
                            </div>
                            <img src={DoubleArrow} alt='not-found' onClick={handleModal} style={{ cursor: 'pointer' }} />
                        </div>


                        <div className='card-details-modal-data'>
                            <FavAndBlackListCard
                                cardData={{
                                    name: carddata?.supplier_name || carddata?.landfill_name,
                                    address: carddata?.supplier_address || carddata?.landfill_address,
                                    phone: carddata?.sales_phone_number || '',
                                    categories: carddata?.materials || carddata?.materials_accepted || [],
                                    markedBy: carddata?.user?.email,
                                    reason: carddata.remarks
                                }}
                                staticRrender={true}
                                handleOnCardClick={() => { }}
                                hideOnHover={true}
                                isFromBlacklist={isBlackList}
                                type={type}
                            />

                            <div className='map-component-for-history-modal' style={{ maxWidth: '570px', height: "100%", borderRadius: "8px", overflow: 'hidden', position: "relative" }}>
                                <MapsComponent
                                    locationsData={[]}
                                    activeLocation={carddata?.id}
                                    destinationLocation={{
                                        lat: carddata?.supplier_location?.latitude || carddata?.landfill_location?.latitude,
                                        lng: carddata?.supplier_location?.longitude || carddata?.landfill_location?.latitude,
                                    }}
                                    setResponse={() => { }}
                                    response={null}
                                    handleOnCardClick={() => { }}
                                    pointerValue={null}
                                />
                            </div>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default DetailModal;

