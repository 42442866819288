import React from 'react';
import heroBannerimg from '../components/findAnswer.png'
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { LStorage } from '../../../utils/localStorage.Helper';
import { usePostHog } from "posthog-js/react";
import { useNavigate } from 'react-router-dom';
import { KINDE_SIGN_UP } from '../../../services';
import STRINGS from '../../../helpers/staticContentHelper'

function FindAnswersSection() {
  const posthog = usePostHog()
  const userData = LStorage.getUserData();
  const navigate = useNavigate();
  const signIn = () => {
    KINDE_SIGN_UP().then(res => {
      if (res) {
        const decryptedData = decryptResponse(res)
        const parsedDecryptedData = JSON.parse(decryptedData)
        if (parsedDecryptedData?.url) {
          window.location.href = parsedDecryptedData.url
        }
      }
    })
  }
  const postHogRecording = () => {
    if (userData.email) {
      posthog.identify(userData.email)
    }
  }
  return (
    <div className='web-integration-section' id='find-answer-section' style={{backgroundColor:"red"}}>
      <img src={heroBannerimg} className='find-answer-section-img' width='100%' height='100%' />
      <div className='material-search-hero-banner' >
        <div className='material-serach-hero-banner-text'>
          <p className='footer-top-section-title' style={{ fontSize: '50px', fontWeight: 500, width: "1200px", textWrap: 'wrap' }}>
            {STRINGS.SEARCH_DOCUMENT_WITH_VENDORS}
          </p>
          <button className='button-secondary-web'
            style={{ marginTop: '2rem' }}
            onClick={() => {
              userData ? postHogRecording():
                posthog.capture('signup_clicked_heroSection')
              if (userData && userData?.access_token) {
                navigate('projects')
              } else {
                signIn()
              }
            }}>
            {userData ? "Go to dashboard" : 'Sign up for free'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default FindAnswersSection