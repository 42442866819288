import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { popUpStyle } from '../../newProjectDetails/components/modal/addUser.modal';
import { ReactComponent as FavouritedMaterial } from "../../materialSearch/assets/favourited.svg";
import { ReactComponent as BlockListedMaterial } from "../../materialSearch/assets/blocked.svg";
import { ReactComponent as ModalCross } from '../../../assests/modalCross.svg'
import STRINGS from '../../../helpers/staticContentHelper';
import { useNavigate } from 'react-router-dom';
import { LStorage } from '../../../utils/localStorage.Helper';
function ListOfUsersModal({ open, setOpen, usersList = [] }) {
    const userData = LStorage.getUserData();
    const navigate = useNavigate();
    const [materialsMarked, setMaterialMarked] = useState({
        blcklisted: false,
        favorite: false
    })
    const handleClose = () => {
        setOpen(prev => !prev)
    }

    const [checkUserPresent, setCheckUserPresent] = useState({
        favList: { isCurrentUserPresent: false },
        blackList: { isCurrentUserPresent: false }
    })

    useEffect(() => {
        let favList = {
            isCurrentUserPresent: false
        };
        let blackList = {
            isCurrentUserPresent: false
        };
        usersList.forEach(markedMaterial => {

            if (markedMaterial.blacklisted.length > 0) {
                setMaterialMarked(prev => {
                    prev.blcklisted = true
                    return prev
                })
                if (markedMaterial.marked_by.email === userData.email) {
                    blackList.isCurrentUserPresent = true
                }
            }

            if (markedMaterial.favorites.length > 0) {
                if (markedMaterial.marked_by.email === userData.email) {
                    favList.isCurrentUserPresent = true
                }
                setMaterialMarked(prev => {
                    prev.favorite = true
                    return prev
                })
            }
        })
        setCheckUserPresent({
            favList, blackList
        })
    }, [])


    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Box sx={{ ...popUpStyle, width: '600px', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1.5rem', overflowY: "scroll" }}>
                <div className='alter-heaader'>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: '100%' }}>
                        <p style={{ lineHeight: "1.8rem", margin: '0px', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                            {STRINGS.MATERIALS_MARKED_AT_THIS_FECILITY}
                        </p>
                        <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center' }}>
                            <ModalCross onClick={() => {
                                handleClose()
                            }} style={{ padding: "0.6rem", backgroundColor: "#f6f7fa", borderRadius: "50%", cursor: "pointer", boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px' }} />
                        </div>
                    </div>
                    <div className='devider' style={{ marginTop: "1rem" }} />
                </div>

                {materialsMarked.blcklisted && <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem", marginTop: "2rem" }}>
                    <span>{STRINGS.BLACKLISTED}</span>
                    {usersList.map(userData => {
                        return userData.blacklisted.length > 0 && <>
                            <span style={{ color: '#6D6D6D', fontSize: '14px' }}>{userData.marked_by.email} {
                                userData.blacklisted.map((item, index) => {
                                    return <span style={{ marginLeft: "0.2rem", display: 'inline-flex', justifyContent: "center", alignItems: "center", padding: '0.1rem 0.3rem', gap: '0.5rem', backgroundColor: "#f3f3f3", borderRadius: "0.5rem" }}> <BlockListedMaterial />{item.material}</span>
                                })}
                            </span>
                        </>
                    })}
                </div>}

                {materialsMarked.favorite &&
                    <div style={{ display: 'flex', flexDirection: 'column', gap: "0.5rem", marginTop: "2rem" }}>
                        <span>{STRINGS.FAVOURITED}</span>
                        {usersList.map(userData => {
                            return userData.favorites.length > 0 && <>
                                <span style={{ color: '#6D6D6D', fontSize: '14px' }}>{userData.marked_by.email} {
                                    userData.favorites.map(item => {
                                        return <span style={{ marginLeft: "0.2rem", display: 'inline-flex', justifyContent: "center", alignItems: "center", padding: '0.1rem 0.3rem', gap: '0.5rem', backgroundColor: "#f3f3f3", borderRadius: "0.5rem" }}> <FavouritedMaterial height={'12px'} />{item.material}</span>
                                    })
                                }</span>
                            </>
                        })}
                    </div>
                }
                {(checkUserPresent.blackList.isCurrentUserPresent || checkUserPresent.favList.isCurrentUserPresent) &&
                    <span style={{ color: '#6D6D6D', fontSize: '12px', marginTop: "1rem" }}>{STRINGS.TOP_UPDATE_OR_MAKE_CHANGES_PLEASE_GO_TO_FAVORITE} <span style={{ color: "#016c6a", fontWeight: 500, cursor: "pointer" }}
                        onClick={() => {
                            navigate('/material-favorite')
                        }}>click here</span>
                    </span>}
            </Box>
        </Modal>
    )
}

export default ListOfUsersModal;