import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import STRINGS from '../../helpers/staticContentHelper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import FilterImg from '../../assests/filter.svg'
import SortImg from '../../assests/sort.svg'
import Button from '@mui/material/Button';
import { ActionOptions, StatusIndicator, baseTypography, buttonStyle } from './components';
import HistoryModal from '../../components/historyModal';
import { IS_USER_ADMIN } from '../../utils/encryptionDecryption';
import { LStorage } from '../../utils/localStorage.Helper';
import { checkUserPermission } from '../../utils/encryptionDecryption';
// import DropDownButtonWithIcon from '../../components/dropdownComponent';

// const SORT_OPTIONS = [
//     {
//         title: 'Recent to oldest'
//     },
//     {
//         title: 'Oldest to recent'
//     }
// ]
// const FILTER_OPTIONS = [
//     {
//         title: 'Ready for search folder'
//     },
//     {
//         title: 'Indexing folder'
//     }
// ]

function ProjectsList({ setOpen, tableData, projectsListLoading, setIsProjectHistoryOpen, isProjectHistoryOpen }) {
    const userData = LStorage.getUserData();
    const navigate = useNavigate();
    const [selectedProject, setSelectedProject] = useState({})
    const [searchParams, setSearchParams] = useSearchParams()

    const columns = [
        { id: 'project_name', label: 'NAME', align: 'start', width: '100%' },
        // {
        //     id: 'project_status', label: 'STATUS', align: 'start',
        //     minWidth: '180px',
        //     format: (value) => {
        //         return <StatusIndicator value={value} />
        //     }
        // },
        { id: 'created_at', label: 'LAST UPDATED ON', align: 'start', minWidth: '200px', format: (value) => value.substring(0, 10) },
        {
            id: 'actions', label: 'ACTIONS', align: 'start',
            minWidth: '300px',
            format: (value, row) => {
                return <ActionOptions setIsProjectHistoryOpen={setIsProjectHistoryOpen} rowData={row} setSelectedRow={setSelectedProject} />
            },
        },
    ];

    if (projectsListLoading) {
        return (
            <div className='no-projects-found'>
                <div class="loading-dots">
                    <div class="dot"></div>
                    <div class="dot"></div>
                    <div class="dot"></div>
                </div>
            </div>
        )
    }

    return (
        <>
            {tableData.length === 0 ?
                <div className='no-projects-found'>
                    <p>{STRINGS.INITIAL_MATERIAL_SEARCH_MSG}</p>
                    {checkUserPermission("CREATE", userData) ?
                        <Button variant="outlined" size="large"
                            sx={{ ...buttonStyle, backgroundColor: '#1B2E31', color: "white", ...baseTypography }}
                            onClick={() => setOpen(true)}
                        >{STRINGS.CREATE_PROJECT}</Button> :
                        <Button type='submit' variant="outlined" size="large"
                            sx={{ ...buttonStyle, backgroundColor: '#1B2E31', color: "white", whiteSpace: "nowrap" }}
                            onClick={() => navigate('/material-search')}
                        >
                            {STRINGS.MATERIAL_SEARCH}
                        </Button>
                    }
                </div>
                :
                <>
                    <div className='space-between'>
                        <p>{STRINGS.YOUR_PROJECTS}</p>
                        <div className='filters-header'>
                            {/* <DropDownButtonWithIcon icon={SortImg} title={'Sort'} options={SORT_OPTIONS} /> */}
                            {/* <DropDownButtonWithIcon icon={FilterImg} title={'Filter'} options={FILTER_OPTIONS} /> */}
                        </div>
                    </div>
                    <TableContainer sx={{ px: '1rem', width: 'calc(100% - 2rem)' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                                ...baseTypography,
                                                minWidth: column.minWidth,
                                                padding: "0.4rem 0.6rem",
                                                color: '#6D6D6D'
                                            }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData
                                    .map((row) => {
                                        return (
                                            <TableRow hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.code}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    navigate(`/project-files/${row.project_id}?projectName=${row.project_name}`)
                                                }
                                                }>
                                                {columns.map((column) => {
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}
                                                            sx={{
                                                                position: 'relative', ...baseTypography, padding: '0.5rem',
                                                            }}>
                                                            {column.format
                                                                ? column.format(value, row)
                                                                : value}

                                                        </TableCell>

                                                    );
                                                })}

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }
            <HistoryModal isFromProject={true} setIsModalOpen={setIsProjectHistoryOpen} isModalOpen={isProjectHistoryOpen} selectedRow={selectedProject} setSearchParams={setSearchParams} />

        </>
    )
}

export default ProjectsList