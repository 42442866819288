import React from 'react'
import { ReactComponent as FavMaterial } from '../assets/noDataFound.svg';

function NoDataFound({ title, subTitle }) {
  return (
    <div style={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: "100%" }}>
      <div className='no-data-found-container'>
        <div className='no-data-found-image-container'>
          <FavMaterial />
        </div>
        <p>{title}</p>
        <span>{subTitle}</span>
      </div>
    </div>
  )
}

export default NoDataFound