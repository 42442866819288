import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import { VERIFY_USER, RESEND_VERIFICATION_LINK, SERVER_VALIDATION } from '../../services/user.services';
import Header from '../../components/header';
import { Typography, Button, Card, CardContent } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import sonarLogo from '../../assests/sonar.svg'
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { LStorage } from '../../utils/localStorage.Helper'
import { decryptResponse } from '../../utils/encryptionDecryption';

function VerifyUser() {
    const params = useParams();
    const navigate = useNavigate()
    const posthog = usePostHog()
    const { userToken } = params;
    const [isUserVerified, setIsUserVerified] = useState(false)
    const [error, setError] = useState(false)
    const [userEmail, setUserEmail] = useState('')
    const [resentVerification, setResentVerification] = useState(true)
    const [invalidToken, setInvalidToken] = useState(false)

    const buttonStyle = {
        backgroundColor: '#1B2E31',
        height: '50px',
        color: 'white',
        borderRadius: '10px',
        padding: '20px',
        textTransform: 'none',
        marginTop: '1rem',
    };
    const verifyUserOnboarding = async (userToken) => {

        await SERVER_VALIDATION({ "verification_token": userToken }).then(res => {
            localStorage.setItem("instance", res?.status_info.toString());
        })

        const reqPayload = {
            verify_token: userToken
        }

        await VERIFY_USER(reqPayload).then(res => {
            if (res?.response?.status === 400) {
                const decrptedMessage = decryptResponse(res?.response?.data?.data)
                const parsedMessage = JSON.parse(decrptedMessage)
                if (parsedMessage?.message === 'E3005') {
                    setUserEmail(parsedMessage?.email)
                    setError(true)
                }
            }
            if (res?.data) {
                const decryptedData = decryptResponse(res.data)
                const parsedDecryptedData = JSON.parse(decryptedData)

                if (parsedDecryptedData?.data) {
                    // const decryptedData = decryptResponse(res?.data)
                    // const decryptedDetails = decryptResponse(parsedDecryptedData?.data?.user_details)
                    const reqData = {
                        ...parsedDecryptedData?.data,
                        ...parsedDecryptedData?.user_details,
                    }
                    LStorage.setUserData(reqData)
                    posthog.identify(parsedDecryptedData?.user_details?.email)
                    setIsUserVerified(true)
                }
                else {
                    setError(true)
                    setInvalidToken(true)
                }
            }
        })
    }
    useEffect(() => {
        if (userToken) {
            verifyUserOnboarding(userToken)
        }
    }, [userToken])

    const resendVerificationLink = () => {
        const reqPayload = {
            email: userEmail
        }
        RESEND_VERIFICATION_LINK(reqPayload).then(res => {
            if (res?.data) {
                setResentVerification(true)
            }
        })
    }

    const redirectDashboard = () => {
        navigate('/projects')
    }

    useEffect(() => {
        if (isUserVerified) {
            setTimeout(() => {
                redirectDashboard()
            }, 3000)
        }

    }, [isUserVerified])

    return (
        <>

            <Header bookDemo={true} />
            {isUserVerified || error ?
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '16px', marginTop: '2rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '16px' }}>
                        {error ?
                            <ErrorIcon style={{ fontSize: 50, color: 'red' }} />
                            :
                            <CheckCircleIcon style={{ fontSize: 50, color: 'green' }} />
                        }

                    </div>
                    <Typography variant="h4" gutterBottom style={{ color: '#124429' }}>
                        {error ? 'Oops! verification link expired' : 'Thank you for verifying your email!'}
                    </Typography>
                    {!invalidToken &&
                        <Typography paragraph >
                            {error ? 'Click below to receive a new verification link' : 'Your email has been successfully verified.'}
                        </Typography>
                    }
                    {error && !invalidToken &&
                        <Button
                            style={{ ...buttonStyle }}
                            onClick={resendVerificationLink}
                        >
                            Resend verification link
                        </Button>

                    }
                    {!invalidToken && !error &&
                        <Button
                            style={{ ...buttonStyle }}
                            onClick={redirectDashboard}
                        >
                            Go to Dashboard
                        </Button>

                    }
                </div>
                :
                <CircularProgress color="success" sx={{ color: '#124429', margin: 'auto', display: 'flex', marginTop: '20%', size: 80 }} />
            }
        </>
    )
}

export default VerifyUser