import HistoryImg from '../../../assests/historyInActive.svg'
// import AddImg from '../../../assests/add.svg'
import chevronLeft from '../../../assests/chevronLeft.svg';
import DropDownButtonWithIcon from '../../../components/dropdownComponent';
import MoreDots from '../../../assests/moreDots.svg';
// import DownloadImg from '../../../assests/download.svg';
// import FolderImg from '../../../assests/createNewFolder.svg';
import EditImg from '../../../assests/renameIcon.svg';
import DeleteImg from '../../../assests/delete.svg';
import { useState } from 'react';
import STRINGS from '../../../helpers/staticContentHelper';
import RenameModal from '../../../components/renameModal/RenameModal';
import DeleteModal from '../../../components/deleteModal/DeleteModal';
import { useEffect, useRef } from 'react';
import { LStorage } from '../../../utils/localStorage.Helper';
import { checkUserPermission } from '../../../utils/encryptionDecryption';
export const underLinePrimaryTextStyle = { textDecoration: 'underline', cursor: "pointer", color: "#006D6B" }

export const baseTypography = {
    fontFamily: 'Plus Jakarta Sans'
}

export const buttonStyle = {
    border: "1px solid #E6E8EA",
    borderRadius: "12px",
    color: "black",
    textTransform: 'none',
    "&:hover": {
        border: "1px solid #E6E8EA",
        background: '#1B2E31',
        opacity: '0.9',
        color: "white",
    },
    fontFamily: 'Plus Jakarta Sans'
};

export const popUpStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem',
    "&:focus": {
        outline: 'none'
    }
};

export const ActionOptions = ({ isFromFiles = false, setIsProjectHistoryOpen, setIsFileHistoryOpen, rowData, fetchList, setSelectedRow, celeryStatus }) => {
    const userData = LStorage.getUserData();
    const [isUserDropDownActive, setIsUserDropDownActive] = useState(false);
    const [isDeleteWindowConfirmActive, setIsDeleteWindowConfirmActive] = useState(false)
    const [openRenameModal, setOpenRenameModal] = useState(false)
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    const FILE_ACTIONS = [
        {
            title: 'Rename',
            icon: EditImg
        },
        // {
        //     title: 'Delete',
        //     icon: DeleteImg
        // },
    ]


    const wrapperRef = useRef(null);

    useEffect(() => {
        if (isUserDropDownActive) {
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setIsUserDropDownActive(false)
                }
            }
            document.addEventListener("click", handleClickOutside);
        }
    }, [isUserDropDownActive]);

    return (
        <>
            {openRenameModal &&
                <RenameModal setOpenRenameModal={setOpenRenameModal} title={rowData?.file_id ? "Rename file" : "Rename folder"} openRenameModal={openRenameModal} rowData={rowData} type={STRINGS.TYPE_FILE_FOLDER} fetchList={fetchList} />
            }
            {openDeleteModal &&
                <DeleteModal setOpenDeleteModal={setOpenDeleteModal} openDeleteModal={openDeleteModal} rowData={rowData} fetchList={fetchList} celeryStatus={celeryStatus} />
            }

            <div className='filters-header' ref={wrapperRef} key={rowData?.file_id}>
                {isFromFiles ?
                    <>
                        {!rowData?.folder_name ?
                            <DropDownButtonWithIcon
                                icon={HistoryImg}
                                title={STRINGS.SEARCH_HISTORY}
                                onOptionChange={() => {
                                }}
                                onChange={() => {
                                    setIsFileHistoryOpen(true);
                                    setSelectedRow(rowData)
                                }}
                            />
                            :
                            checkUserPermission("CREATE", userData) ?
                                <DropDownButtonWithIcon
                                    icon={EditImg}
                                    title={STRINGS.RENAME_FOLDER}
                                    onOptionChange={() => {
                                    }}
                                    onChange={() => {
                                        setOpenRenameModal(true)
                                    }}
                                /> 
                                : 
                                <div style={{padding:'1rem 0px' , width:"150px"}}></div>
                        }
                        {/* {rowData?.file_id &&
                            <div className='nav-bar-icons'
                                style={{ backgroundColor: '#F3F3F3', marginLeft: '1rem' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setIsUserDropDownActive(prev => !prev)
                                }}
                            >
                                <img src={MoreDots} alt='userImg' />
                                {FILE_ACTIONS.length > 0 && isUserDropDownActive &&
                                    <div className='drop-down-options-list'>
                                        {FILE_ACTIONS.map((option, index) => {
                                            return (
                                                <div className='drop-down-option' key={index} onClick={() => {
                                                    if (option.title === 'Rename') {
                                                        setOpenRenameModal(true)
                                                    }
                                                    if (option.title === 'Delete') {
                                                        setOpenDeleteModal(true)
                                                    }
                                                }}>
                                                    {option.icon &&
                                                        <img src={option.icon} alt='not-found' />
                                                    }
                                                    {option.title}
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div>
                        } */}
                        {(rowData.file_status) &&
                            <>
                                {/* <p>{STRINGS.VIEW}</p> */}
                                <img src={chevronLeft}
                                    alt='noImageFound'
                                    style={{ transform: 'rotate(180deg)' }}
                                />
                            </>
                        }
                        {rowData?.folder_name &&
                            <>
                                {/* <div className='nav-bar-icons'
                            style={{ backgroundColor: '#F3F3F3', marginLeft: '1rem' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsUserDropDownActive(prev => !prev)
                            }}
                        >
                            <img src={MoreDots} alt='userImg' />
                            {FILE_ACTIONS.length > 0 && isUserDropDownActive &&
                                <div className='drop-down-options-list'>
                                    {FILE_ACTIONS.map((option, index) => {
                                        return (
                                            <div className='drop-down-option' key={index} onClick={() => {
                                                if (option.title === 'Rename') {
                                                    setOpenRenameModal(true)
                                                }
                                                if (option.title === 'Delete') {
                                                    setOpenDeleteModal(true)
                                                }
                                            }}>
                                                {option.icon &&
                                                    <img src={option.icon} alt='not-found' />
                                                }
                                                {option.title}
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div> */}
                                <div style={{ width: '5px', display: 'flex', justifyContent: 'end', alignItems: "center" }}>
                                    {/* <p>{STRINGS.VIEW}</p> */}
                                    <img src={chevronLeft}
                                        alt='noImageFound'
                                        style={{ transform: 'rotate(180deg)' }}
                                    />

                                </div>
                            </>
                        }
                    </>
                    :
                    <>
                        {/* <DropDownButtonWithIcon
                        icon={AddImg}
                        title={'Search  thread'}
                        onOptionChange={() => {
                        }}
                    /> */}
                        <DropDownButtonWithIcon
                            icon={HistoryImg}
                            title={'Project history'}
                            onOptionChange={() => {
                            }}
                            onChange={() => {
                                setIsProjectHistoryOpen(true)

                                setSelectedRow(rowData)
                            }}
                        />
                        <p style={{ marginLeft: '1rem' }}>{STRINGS.VIEW_PROJECT}</p>
                        <img src={chevronLeft}
                            alt='noImageFound'
                            style={{ transform: 'rotate(180deg)' }}
                        />
                    </>
                }
            </div>
        </>
    )
}

// statusIndicator Component for the status on indexing file

export const StatusIndicator = ({ value, deleteList, row }) => {
    return <div className='indexing-status'>
        <div className={`${deleteList?.includes(row?.file_id) ? 'delete-in-progress' : value ? 'ready-to-search' : 'indexing'}`}></div>
        {deleteList?.includes(row?.file_id) ? 'Delete in progress' : value ? STRINGS.READY_FOR_SEARCH : STRINGS.INDEXING}
    </div>
}