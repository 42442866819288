import React from 'react';
import UserForm from './userForm';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import STRINGS from '../../helpers/staticContentHelper'

export const popUpStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#0d1618',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem',
};


function PopupForm({ open, setOpen }) {
    const handleClose = () => {
        setOpen(prev => !prev)
    }
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{ ...popUpStyle, width: '700px', minHeight: '450px', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1rem 1.5rem', overflowY: "scroll" , backgroundColor:"#1b2d30"}}>
                <span style={{textAlign:'start' , color:"white" ,  fontFamily: 'Satoshi', fontSize:"20px" , marginLeft:'1.5rem' , fontWeight:"600"}}>{STRINGS.YOU_HAVE_REACHED_YOUR_MONTHLY_LIMIT}</span>
                <span style={{textAlign:'start' ,  fontFamily: 'Satoshi',  color:"#ecf0f3" , fontSize:"14px" , marginLeft:'1.7rem', marginTop:'0.3rem'}}>{STRINGS.FOR_UNMIMITED_SEARCH_PLESE_CONTACT_US}</span>
                <hr style={{width:'100%' , border:"0.2px solid gray"}} />
                <UserForm isTriggeredFromMain={false}/>
            </Box>
        </Modal>
    )
}

export default PopupForm