import { createContext, useContext, useState } from 'react';

const AvailableConfiguredGPTs = createContext();

export const AvailableConfiguredGPTsContext = ({ children }) => {
    const [availableConfiguredGPTs, setAvailableConfiguredGPTs] = useState([]);

    return (
        <AvailableConfiguredGPTs.Provider value={{ availableConfiguredGPTs, setAvailableConfiguredGPTs }}>
            {children}
        </AvailableConfiguredGPTs.Provider>
    );
};

export const useAvailableConfiguredGPTs = () => useContext(AvailableConfiguredGPTs);