import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import STRINGS from '../../../../helpers/staticContentHelper';
import BackImg from '../../../../assests/back.svg';
import { decryptResponse } from '../../../../utils/encryptionDecryption';
import { GET_PROJECTS_LIST } from '../../../../services';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/material/styles';
import { LIST_PROJECT_MEMBERS } from '../../../../services';

const fontAndSize = {
    fontFamily: "Plus Jakarta Sans",
    color: 'rgb(27, 46, 49)',
    fontSize: '16px',
    margin:'0.1rem'
}

function InviteNewExistingUser({ setUserFormData, getProjectName, projectId, userData, setActiveTab, usersData, userFormData, error, setError }) {
    const [projectsList, setProjectsList] = useState([])
    const [dropDownUsers, setDropdownUsers] = useState([])

    useEffect(() => {
        setUserFormData({
            user_emails: [],
            project_ids: projectId ? [projectId] : []
        })
    }, [projectId])

    const fetchProjectsList = () => {
        GET_PROJECTS_LIST({ created_by: userData?.created_by }).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData) {
                    setProjectsList(parsedDecryptedData)
                }
            }
        })
    }

    useEffect(() => {
        fetchProjectsList();
    }, []) //eslint-disable-line

    const handleDelete = (chipToDelete, key) => () => {
        setUserFormData(prev => {
            const filteredData = prev[key].filter((chip) => chip !== chipToDelete)
            return { ...prev, [key]: filteredData }
        })
    };

    const ListItem = styled('li')(({ theme }) => ({
        margin: theme.spacing(0.5),
    }));

    const getListOfProjectUsers = (orgId, projectId) => {
        const queryObj = {
            org_id: orgId,
            project_id: projectId,
            existing_project_users: false
        }
        LIST_PROJECT_MEMBERS(queryObj).then(res => {
            if (res) {
                const decryptedUsersData = JSON.parse(decryptResponse(res))
                console.log(decryptedUsersData)
                setDropdownUsers(decryptedUsersData)
            }
        })
    }

    useEffect(() => {
        if (userData.org_id && projectId) {
            getListOfProjectUsers(userData.org_id, projectId)
        } else {
            const filteredUsersData = usersData.filter(singleUser => singleUser.role !== 'admin')
            setDropdownUsers(filteredUsersData)
        }
    }, [projectId, userData.org_id, usersData])

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                <img onClick={() => {
                    setActiveTab('')
                }} src={BackImg} alt='not-found' width='20px' height='20px' style={{ cursor: "pointer" }} />
                <p style={{ lineHeight: "0px", ...fontAndSize, textAlign: 'center', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                    {STRINGS.INVITE_USER_TO_TEAM}
                </p>
            </div>
            <div  style={{ marginTop: '1rem' }}>
                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                    <Typography className='input-label' sx={{ ...fontAndSize }}>
                        Select Projects
                    </Typography>
                    <Select
                        displayEmpty
                        onChange={(e => {
                            setUserFormData(prev => {
                                return { ...prev, project_ids: [...prev.project_ids, e.target.value] }
                            })
                            setError(prev => {
                                return { ...prev, project_ids: '' }
                            })
                        })}
                        sx={{marginTop:"0px"}}
                        input={<OutlinedInput
                            fullWidth
                            required
                            error={error.isError && error.project_ids}
                            size='small'
                            className='outline-input-customized'
                        />}
                        renderValue={() => <em>Select projects</em>}
                        MenuProps={[]}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {projectsList.map((project) => {
                            if (!userFormData.project_ids.includes(project.project_id)) {
                                return (
                                    <MenuItem
                                        key={project.project_name}
                                        value={project.project_id}
                                        sx={{ ...fontAndSize }}
                                    >
                                        {project.project_name}
                                    </MenuItem>
                                )
                            }
                        })}
                    </Select>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                        }}
                        component="ul"
                    >
                        {userFormData.project_ids.map((data) => {
                            let icon;
                            return (
                                <ListItem key={data}>
                                    <Chip
                                        icon={icon}
                                        label={getProjectName(data)}
                                        onDelete={handleDelete(data, 'project_ids')}
                                    />
                                </ListItem>
                            );
                        })}
                    </Box>
                    {(error.isError && error.project_ids) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                            {error.project_ids}
                        </span>
                    }
                </div>
            </div>
            <div  style={{ marginTop: '1rem' }}>
                <div className='flex-input-box' style={{ marginTop: '1rem' }}>
                    <Typography className='input-label' sx={{ ...fontAndSize }}>
                        Select User
                    </Typography>
                    <Select
                        displayEmpty
                        disabled={projectId && dropDownUsers.length === 0}
                        onChange={(e => {
                            setUserFormData(prev => {
                                return { ...prev, user_emails: [...prev.user_emails, e.target.value] }
                            })
                            setError(prev => {
                                return { ...prev, user_emails: '' }
                            })
                        })}
                        sx={{marginTop:"0px"}}
                        input={<OutlinedInput
                            fullWidth
                            required
                            error={error.isError && error.user_emails}
                            size='small'
                            className='outline-input-customized'
                        />}
                        renderValue={() => <em>Select user</em>}
                        MenuProps={[]}
                        inputProps={{ 'aria-label': 'Without label' }}
                    >
                        {dropDownUsers.map((user) => {
                            if (!userFormData.user_emails.includes(user.email)) {
                                return (
                                    <MenuItem
                                        onKeyDown={(e) => e.stopPropagation()}
                                        key={user.email}
                                        value={user.email}
                                        sx={{ ...fontAndSize }}
                                    >
                                        {user.email}
                                    </MenuItem>
                                )
                            }
                        })}
                    </Select>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'start',
                            flexWrap: 'wrap',
                            listStyle: 'none',
                            p: 0,
                            m: 0,
                        }}
                        component="ul"
                    >
                        {userFormData.user_emails.map((data) => {
                            let icon;
                            return (
                                <ListItem key={data}>
                                    <Chip
                                        icon={icon}
                                        label={data}
                                        onDelete={handleDelete(data, 'user_emails')}
                                    />
                                </ListItem>
                            );
                        })}
                    </Box>
                    {(error.isError && error.user_emails) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                            {error.user_emails}
                        </span>
                    }
                </div>
            </div>
            <p style={{ fontSize: '0.9rem', fontFamily: "Plus Jakarta Sans", }}>
                {STRINGS.CNNOT_FIND_YOUR_TEAM_MATE} <span style={{ fontFamily: "Plus Jakarta Sans", color: "#34b37e", fontWeight: 600, cursor: "pointer" }} onClick={() => {
                    setActiveTab('add-user')
                }}>{STRINGS.ADD_NEW_USER}</span>
            </p>
        </div>
    )
}

export default InviteNewExistingUser