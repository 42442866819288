import React from 'react';
import scannerImg from './package.svg';
import searchThroughImg from './editPrompt.svg';
import dataBaseImg from './upload.svg';
import STRINGS from '../../../helpers/staticContentHelper';

const SOLUTION_FOOTER_DATA = [
    {
        img: scannerImg,
        title: STRINGS.ADD_YOUR_DATA_TO_TAILOR_IT_FOR_YOUR_NEEDS
    },
    {
        img: searchThroughImg,
        title: STRINGS.EDIT_OUR_PROMPT_OR_ADD_YOUR_OWN
    },
    {
        img: dataBaseImg,
        title:STRINGS.UPLOAD_FILES_OR_PDFS_TO_TRAIN_YOUR_GPT
    },
]

function SetUpYourGpt() {
    return (
        <div className='web-workflow-section-full-width' id='feature_section'>
            <div className='web-solution-section-container'  style={{paddingTop:"2rem"}}>

                <div className='web-solution-content-component solution-card-footer-info-main' style={{ padding: '0' }}>

                    <div className='select-active-solution footer-select-active-solution'>
                        <p className='select-active-solution-control-f' >
                            {STRINGS.SET_UP_YOUR_GPT}<br/> in just <span style={{ fontWeight: '300', fontStyle: 'italic' }}> three </span>
                            steps...
                        </p>
                    </div>

                    <div className='selected-solution solution-card-footer-info-container'>
                        {SOLUTION_FOOTER_DATA.map(footerData => {
                            return (
                                <div className='solution-control-f-card'>
                                    <img src={footerData.img} alt='no' />
                                    <p className='solution-control-f-card-info'>{footerData.title}</p>
                                </div>
                            )
                        })}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SetUpYourGpt