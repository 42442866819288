import React from 'react';
import './index.css';
import UserForm from './userForm';
import STRINGS from '../../helpers/staticContentHelper';

function UserFormMain() {

    return (
        <div className='web-workflow-section-full-width' style={{ borderBottom:"1px solid gray" }}>
            <div className='web-solution-section-container' style={{ paddingTop:'0px' }}>
                <div className='web-problem-section'>
                    <div className='user-from-component'>

                        <div className='about-form-page-infromation'>
                            <p className='form-page-title'>
                                {STRINGS.READY_TO_SEE_SONAR_LABS}
                            </p>
                            <p className='form-page-sub-title'>
                                {STRINGS.JOIN_CONTRACTORS_FROM_ALL_OVER_AMERICA}
                            </p>
                        </div>
                        <UserForm />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default UserFormMain;