import React from 'react'
import STRINGS from '../../helpers/staticContentHelper';
import awsImg from '../../assests/sonarWebImages/aws.svg';
import lockImg from '../../assests/sonarWebImages/lockSecurity.svg';
import manageUserImg from '../../assests/sonarWebImages/securityManageusers.svg';
import ssoSupport from '../../assests/sonarWebImages/ssoSupport.svg';
import securityImg from '../../assests/sonarWebImages/securityPageImg.svg'

const SECURITY_DATA_FIRST_ROW = [
    {
        title: STRINGS.ROBUST_AWS_BACKED_SECURITY,
        description: STRINGS.ROBUST_AWS_BACKED_SECURITY_description,
        img: awsImg
    },
    {
        title: STRINGS.PRIVATE_BY_DESIGN,
        description: STRINGS.PRIVATE_BY_DESIGN_DESCRIPTION,
        img: lockImg
    },
]
const SECURITY_DATA_SECOND_ROW = [
    {
        title: STRINGS.MANAGE_USERS,
        description: STRINGS.MANAGE_USERS_DESCRIPTION,
        img: manageUserImg
    },
    {
        title: STRINGS.SSO_SUPPORT,
        description: STRINGS.SSO_SUPPORT_DESCRIPTION,
        img: ssoSupport
    }
]

function Security({ posthog }) {
    return (
        <div className='web-problem-section' id='security-section-padding-fix' >
            <div className='web-hero-section-title' style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', padding: '0px 1rem' }}>
                <h1 className='web-gradient-title'>
                    <span className='web-gradient-title-security-span'>{STRINGS.UNCOMPROMISING_SECURITY}
                    </span> {STRINGS.FOR_YOUR_DATA}
                </h1>
                <p className='web-gradient-sub-title' style={{ maxWidth: '850px', marginTop: "1rem" }}>
                    {/* {STRINGS.AT_OUR_CORE} <br /> */}
                    {STRINGS.OUR_SECURITY_MEASURES_ENSURE_YOUR_INFORMATION}
                </p>
            </div>

            <img src={securityImg} alt='no' width='100%' />

            <div className='web-integration-section' style={{ flexDirection: "column", gap: "0px", paddingTop: '0px' }}>
                <p className='web-section-sub-title'>{STRINGS.OUR_MEASURES}</p>
                <h3 className='web-section-title'>
                    {STRINGS.SECURITY_YOU_TRUST}
                </h3>

                <div className='security-page-content'>
                    {SECURITY_DATA_FIRST_ROW.map((cardData, index) => {
                        return (
                            <div className='security-page-content-card' key={index + 'security'}>
                                <div className='security-page-content-image-container'>
                                    <img src={cardData?.img} alt='no' />
                                </div>
                                <div className='web-security-content-card-content-info'>
                                    <p>{cardData?.title}</p>
                                    <span>
                                        {cardData?.description}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>

                <div className='security-page-content'>
                    {SECURITY_DATA_SECOND_ROW.map((cardData, index) => {
                        return (
                            <div className='security-page-content-card' key={index + 'security'}>
                                <div className='security-page-content-image-container'>
                                    <img src={cardData?.img} alt='no' />
                                </div>
                                <div className='web-security-content-card-content-info'>
                                    <p>{cardData?.title}</p>
                                    <span>
                                        {cardData?.description}
                                    </span>
                                </div>
                            </div>
                        )
                    })}
                </div>

            </div>

        </div>
    )
}

export default Security