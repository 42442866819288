import React from 'react';
import STRINGS from '../../../helpers/staticContentHelper';
import keyImg from '../../../assests/sonarWebImages/key.svg'
import lockImg from '../../../assests/sonarWebImages/lock.svg'
import fingerPrintImg from '../../../assests/sonarWebImages/fingerPrint.svg';
import webSecurityImg from '../../../assests/sonarWebImages/webSecurityImg.png'

const WEB_SECURITY_DATA = [
    {
        title: STRINGS.DATA_PRIVACY,
        img: lockImg,
        description: STRINGS.YOUR_DOCUMENTS_REMAIN_PRIVATE
    },
    {
        title: STRINGS.ENCRYPTION,
        img: fingerPrintImg,
        description: STRINGS.DATA_TRANSFERS_ARE_ENCRYPTED_FOR_SECURE_UPLOADS
    },
    {
        title: STRINGS.CONTROLLED_ACCESS,
        img: keyImg,
        description: STRINGS.YOU_CONTROL_WHO_ACCESSES_YOUR_DOCUMENTS
    },
]


function SecuritySection({ posthog }) {
    return (
        <div className='web-security-section-container' id='security_section' style={{ justifyContent: "start", flexDirection: 'column', alignItems: "start" }}>
            <p className='web-section-sub-title' style={{ margin: '0' }}>{STRINGS.SECURITY_UPPERCASE}</p>
            <div className='web-security-section-with-button'>
                <h1 className='web-section-title-security'>{STRINGS.YOUR_DOCUMENTS_YOUR_DATA_SECURED}</h1>
            </div>

            <div className='web-security-section-content-container'>
                <div className='web-security-content-section'>

                    {WEB_SECURITY_DATA.map(securityData => {
                        return (
                            <div key={securityData.title} className='web-security-content-card'>
                                <div className='web-security-content-card-image-container'>
                                    <img src={securityData.img} alt='no' />
                                </div>
                                <div className='web-security-content-card-content-info'>
                                    <p>{securityData.title}</p>
                                    <span>{securityData.description}</span>
                                </div>
                            </div>
                        )
                    })}

                </div>
                <div className='web-security-section-with-image'>
                    <img style={{ borderRadius: '12px', maxWidth: "100%" }} src={webSecurityImg} alt='no' />
                </div>
            </div>


            <a href='/security' className='security-button-from-security-section' onClick={() => {
                posthog.capture('security_button_clicked_security_section')
            }}>
                {STRINGS.LEAR_MORE_ABOUT_SECURITY}
            </a>
        </div>
    )
}

export default SecuritySection