import React from "react";
import { useRoutes } from "react-router-dom";
import routes from "./routes";
import { LStorage } from "./utils/localStorage.Helper";

function App() {
  const userData = LStorage.getUserData();
  const routing = useRoutes(routes(Boolean(userData?.access_token)))

  return (
    // <React.StrictMode>
      <div>
        {routing}
      </div>
    // </React.StrictMode>
  );
}

export default App;