import { createContext, useContext, useState } from 'react';

const FunctionContext = createContext();

export const FunctionProvider = ({ children }) => {
  const [value, setValue] = useState("");

  const sharedFunction = (newValue) => {
    setValue(newValue);
  };

  return (
    <FunctionContext.Provider value={{ sharedFunction, value }}>
      {children}
    </FunctionContext.Provider>
  );
};

export const useFunctionContext = () => useContext(FunctionContext);
