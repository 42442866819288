export const TERMS_OF_USE = `
<style>
    .subTitleStyle {
        font-size: 1rem;
        margin-top: 0rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
    }

    .titleStyle {
        font-size: 20px;
        margin-top: 1rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
        margin-bottom: 1.2rem;
        color: black;
    }

    span {
        font-weight: 600;
        color: black;
        font-family: 'Satoshi';
    }

    a{
        font-weight: 600;
        color: black;
        text-direction: underline;
        cursor: pointer;
        font-family: 'Satoshi';
    }

    ul{
        padding-left:0.8rem;
        margin:0;
        margin-bottom:1rem;
        font-family: 'Satoshi';
    }
</style>
<h5 class='titleStyle' style='margin-top:4rem'>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING THIS SITE</h5>

<h5 class='titleStyle'>1.  WHO WE ARE AND HOW TO CONTACT US  </h5>

<p class='subTitleStyle'>
    <a href='https://sonarlabs.ai/' target='__blank' >   www.sonarlabs.ai </a> is a website (Website) operated by Siteworkz Private Limited,
    a company incorporated under the laws of India bearing CIN U72600KA2021PTC150806 having registered office at #4,
    Vaibhava Centre, 3rd Floor,
    Ramanamaharshi Road, Opp Palace Grounds, Bengaluru – 560080, Karnataka, India (Company, we, us and our).
</p>

<p class='subTitleStyle'>
    To contact us, please email hello@sonarlabs.ai or +91 6366564505.
</p>




<h5 class='titleStyle'>2.  BY USING OUR WEBSITE YOU ACCEPT THESE TERMS    </h5>

<p class='subTitleStyle'>
    By using our Website, you confirm that you accept these terms of use and that you agree to comply with them.
    If you do not agree to these terms, you must not use our Website
</p>



<h5 class='titleStyle'>3.  WE MAY MAKE CHANGES TO THESE TERMS    </h5>

<p class='subTitleStyle'>
    We amend these terms from time to time. Every time you wish to use our Website, please check these terms to ensure you understand the terms that apply at that time.
    <br />
    <br />
    These terms were most recently updated on 15th April 2024.
</p>


<h5 class='titleStyle'>4.  WE MAY MAKE CHANGES TO OUR WEBSITE</h5>

<p class='subTitleStyle'>
    We may update and change our Website from time to time to reflect changes to our services,
    our users’ needs, changes in law and our business priorities.
    We will try to give you reasonable notice of any major changes.
</p>





<h5 class='titleStyle'>5.  WE MAY SUSPEND OR WITHDRAW OUR WEBSITE  </h5>

<p class='subTitleStyle'>
    Our Website is made available free of charge. Access to certain services offered through the Website is on a paid basis,
    for which you may need to create an account and pay subscription charges as per the pricing plans,
    as stated on the Website.
</p>

<p class='subTitleStyle'>
    We do not guarantee that our Website, or any content on it, will always be available, uninterrupted or be error-free.
    We may suspend or withdraw or restrict the availability of all or any part of our Website for business and operational reasons.
    We will try to give you reasonable notice of any suspension or withdrawal.
</p>

<p class='subTitleStyle'>
    You are also responsible for ensuring that all persons who access our Website through your internet connection
    are aware of these terms of use and other applicable terms and conditions,
    and that they comply with them.
</p>



<h5 class='titleStyle'>6.  ELIGIBILITY TO USE OUR WEBSITE    </h5>

<p class='subTitleStyle'>
    Our Website is directed to users who are residing in and using this Website within India or the United States of America.
    We do not represent that content available on or through our Website is appropriate for use or available in other locations. If you access our Website from outside India or the United States of America,
    you do at your risk and you are responsible for compliance with laws applicable to your accessing the Website from your location.
</p>


<h5 class='titleStyle'>7.  ACCOUNTS </h5>

<p class='subTitleStyle'>
    1.1 ACCOUNTS
</p>

<p class='subTitleStyle'>
    (a)   In order to use some of the functionality of the Website, you will be required to sign-up, register and receive an account through the Website (an Account).
</p>

<p class='subTitleStyle'>
    (b) As part of the Account registration process and as part of your continued use of the Website, you are required to provide personal information and details,
    such as your email address, first and last name, preferred username, a secure password, billing addresses, mobile phone number, profile information, payment details, ratings and reviews, verified identifications,
    verified certifications and authentications, and other information as determined by the Company from time to time.
</p>

<p class='subTitleStyle'>
    (c) You agree that you’re solely responsible for:
</p>

<p class='subTitleStyle' style='padding-left:0.5rem;'>
    (i) maintaining the confidentiality and security of your Account information and your password; and <br />
    (ii) any activities and those of any third party that occur through your Account, whether those activities have been authorised by you or not.
</p>

<p class='subTitleStyle'>
    (d) You warrant that any information you give to the Company in the course of completing the Account registration process will always be accurate, honest, correct and up to date.
</p>

<p class='subTitleStyle'>
    (e)  Once you complete the Account registration process, Company may,
    in its absolute discretion, choose to accept you as a registered user within the Website and provide you with an Account.
</p>


<p class='subTitleStyle'>
    (f) Company reserves the right to contact you about any concerning behaviour by you, or to seek a resolution with you.
</p>

<p class='subTitleStyle'>
    (g) Company may suspend or cancel your Account for any reason, including for any failure to comply with the Terms.
</p>

<p class='subTitleStyle'>
    (h) You also agree to let us know if you detect any unusual activity on your Account as soon as you become aware of it.
</p>

<p class='subTitleStyle'>
    (i)   We will not be responsible to you for, and expressly disclaim any liability for, any cost, loss,
    damages or expenses arising out of a failure by you to maintain the security of your Account information or your password.
</p>

<p class='subTitleStyle'>
    (j)   You agree to release Company from any and all liability for any loss or damage that arises out of or in connection with information you provide that is not accurate,
    honest, correct or up-to-date.
</p>

<p class='subTitleStyle'>
    1.2 ACCOUNT CANCELLATION
</p>

<p class='subTitleStyle'>
    (a) <span > (Cancellation by you)</span> You are responsible for
    the cancellation of your Account. You can cancel your Account at any time by using the functionality provided on the Website.
</p>

<p class='subTitleStyle'>
    (b) <span >  (Cancellation by us) </span> To the extent permitted by law, we reserve the right to terminate your access to any or all of the Website at any
    time without notice and without issuing a refund if you breach any provision of these terms.
</p>





<h5 class='titleStyle'>8. PAYMENT FOR SUBSCRIPTION </h5>

<p class='subTitleStyle'>
    (a) All prices are:
</p>

<p class='subTitleStyle' style='padding-left:0.5rem;'>
    (i)  on a monthly, half-yearly or yearly subscription basis; <br />
    (ii) in USD or INR (except where otherwise indicated); and <br />
    (iii) subject to change prior to you completing the payment, without notice.
</p>


<p class='subTitleStyle'>
    (c) <span >(Payment obligations)</span> Unless otherwise agreed in writing,
    you must pay for the subscription plan selected by you at the time of placing an order.
</p>


<p class='subTitleStyle'>
    (d) <span >(GST/VAT) </span> Unless otherwise indicated, amounts stated do not include GST, VAT or any similar tax.
    In relation to any GST or VAT payable for a taxable supply by us, you must pay the GST or VAT subject to us including that amount in the order,
    or otherwise providing a tax invoice.
</p>

<p class='subTitleStyle'>
    (e) <span > (Online payment partner) </span>
    We may use third-party payment providers <span > (Payment Providers)</span> to collect payments.
    The processing of payments by the Payment Provider will be, in addition to these terms, subject to the terms,
    conditions and privacy policies of the Payment Provider and we are not liable for the security or performance of the Payment Provider.
    We reserve the right to correct, or to instruct our Payment Provider to correct,
    any errors or mistakes in collecting your payment.

</p>

<p class='subTitleStyle'>
    (f) <span > (Pricing errors) </span>
    In the event that we discover an error or inaccuracy in the price at which your order was purchased,
    we will attempt to contact you and inform you of this as soon as possible. You will then have the option of purchasing your order at the correct price, or cancelling your order.
    If you choose to cancel your order and payment has already been debited, the full amount will be credited back to your original method of payment.
</p>

<p class='subTitleStyle'>
    (g) <span >(Refunds) </span>
    If you cancel a contract with us for a Subscription, within the 30-day cooling-off period, we will process any
    refund due to you as soon as possible and, in any case, within 14 days after the day you have given notice of your cancellation.
    We will refund any money received from you using the same method originally used by you to pay for your purchase unless otherwise agreed.
    In case the Subscription is cancelled upon expiry of 30-day cooling-off period, then the cancellation will
    be effective only upon expiry of the duration of the on-going subscription and you will not be entitled to any refund.
</p>

<p class='subTitleStyle'>
    (h) <span >(Auto-renewal) </span>
    Unless you cancel your Subscription, it will be automatically renewed at the end of each subscription term, at the rate(s)
    then in effect. You will receive an auto-renewal reminder notice with the then current rate(s)
    (i) 5 days prior to charging your credit/debit card/3rd party payment account for a monthly Subscription; and
    (ii) 30 days prior to charging your credit/debit card/3rd party payment account for an annual Subscription. You may opt out of the automatic renewal at any time by contacting customer service at [insert email id] and
    (i) your monthly Subscription will continue until the end of the then current month and
    (ii) your annual Subscription will continue until the end of the end date of the ongoing subscription.
</p>

<p class='subTitleStyle'>
    (i) <span >(Recurring Billing </span>
    ) By placing an order for a Subscription, you authorize us to charge you the subscription fee then in effect
    at the beginning of each billing period to your payment method. For example, you authorize us to charge your payment method
    the promotional rate disclosed on the subscription screen in the initial billing period (if applicable) and the regular
    subscription rate in subsequent billing periods. We reserve the right to change the timing of our billing, in particular,
    in the event your payment method has not successfully settled. If your payment method is declined for a recurring payment
    of your subscription fee, provide us a new payment method promptly or your subscription will be cancelled.
    s You acknowledge that the amount charged each billing period may vary for reasons that may include price changes or changing your subscription,
    and you authorize us to charge your payment method for such varying amounts each billing period.
</p>
<p class='subTitleStyle'>
Read more about our cancellation policy   <a href=${window.location.origin+"/refund-and-cancellation-policy"} target='__blank' >here</a>.
</p>
<h5 class='titleStyle'> 9. HOW YOU MAY USE MATERIAL ON OUR WEBSITE   </h5>

<p class='subTitleStyle'>
    We are the owner or the licensee of all intellectual property rights in our Website and all services offered through the Website,
    and in the material published on it.
    Those works are protected by intellectual property laws and treaties around the world. All such rights are reserved.
</p>

<p class='subTitleStyle'>
    You may print off one copy, and may download extracts, of any page(s) from our Website for your personal use and you may draw the attention of others to content posted on our Website.
</p>

<p class='subTitleStyle'>
    You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.
</p>

<p class='subTitleStyle'>
    Our status (and that of any identified contributors) as the authors of content on our Website must always be acknowledged.
</p>

<p class='subTitleStyle'>
    You must not use any part of the content on our Website for commercial purposes without obtaining a licence to do so from us or our licensors.
</p>

<p class='subTitleStyle'>
    If you print off, copy or download any part of our Website in breach of these terms of use, your right to use our Website will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.
</p>

<p class='subTitleStyle'>
    If we provide social media features such as the ability to share content, you may take such actions as are enabled by such features.
</p>

<p class='subTitleStyle'>
    You must not delete or alter any copyright, trade mark or other proprietary rights notices from copies of materials from this Website.
</p>





<h5 class='titleStyle'>10.  DO NOT RELY ON INFORMATION ON THIS WEBSITE    </h5>

<p class='subTitleStyle'>
    This Website is provided on an “as is” and “as available” basis, and we make no representations or warranties, express or implied, regarding the operation or availability of the Website.

</p>

<p class='subTitleStyle'>
    The content on our Website is provided for general information only. It is not intended to amount to advice on which you should rely.
</p>

<p class='subTitleStyle'>
    Although we make reasonable efforts to update the information on our Website,
    we make no representations, warranties or guarantees, whether express or implied,
    that the content on our Website is accurate, complete or up-to-date.
</p>



<h5 class='titleStyle'>11.  WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO   </h5>

<p class='subTitleStyle'>
    Where our Website contains links to other websites (including banner advertisements and sponsored links) and
    resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as
    approval by us of those linked websites or information you may obtain from them. We have no control over the contents of those websites or
    resources, and you access third party websites entirely at your own risk and subject to the terms and conditions of use
    for those websites.
</p>




<h5 class='titleStyle'>12.  USER-GENERATED CONTENT   </h5>

<p class='subTitleStyle'>
    This Website may include information and materials uploaded by other users of the Website,
    including to comment threads. This information and these materials have not been verified or approved by us.
    The views expressed by other users on our Website do not represent our views or values.
</p>


<p class='subTitleStyle'>
    This Website may contain comment threads,
    personal web pages or profiles, forums, bulletin boards, search bar,
    history threads and other interactive features (collectively, <span >Interactive Services</span>) that allow users to post,
    submit, publish, display or transmit to other users or other persons <span >(post)</span>
    content or materials (collectively,  <span > User Contributions </span>)
    on or through the Website.
</p>

<p class='subTitleStyle'>
    All User Contributions must comply with the content standards set out in Clause 12 of these terms.
</p>

<p class='subTitleStyle'>
    Any User Contribution you post to the Website will be considered non-confidential and non-proprietary.
    You retain all of your ownership rights in your content, however by providing any User Contribution on the Website,
    you grant us and our affiliates and service providers, and each of their and our respective licensees,
    successors and assigns the right to use, reproduce, modify, perform, display,
    distribute and otherwise disclose to third parties any such material for any purpose.
</p>

<p class='subTitleStyle'>
    We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you
    to our Website constitutes a violation of their intellectual property rights, or of their right to privacy.
</p>

<p class='subTitleStyle'>
    We have the right to remove any posting you make on our Website if, in our opinion,
    your post does not comply with the content standards in Clause 12 of these terms.
</p>

<p class='subTitleStyle'>
    You are solely responsible for securing and backing up your content.
</p>

<p class='subTitleStyle'>
    You represent and warrant that:
</p>


<ul >
    <li class='subTitleStyle'>
        You own or control all rights in and to your User Contributions and have the right to grant the license
        granted above to us and our affiliates and service providers,
        and each of their and our respective licensees, successors and assigns.
    </li>
    <li class='subTitleStyle'>
        All of your User Contributions do and will comply with these terms.
    </li>

    <li class='subTitleStyle'>
        You understand and acknowledge that you are responsible for any User Contributions you submit or contribute,
        and you (and not us) have fully responsibility for such content,
        including its legality, reliability, accuracy and appropriateness.
    </li>

    <li class='subTitleStyle'>
        We are not responsible,
        or liable to any third party, for the content or accuracy of any User Contributions posted by you or
        any other user of the Website.
    </li>
</ul>


<p class='subTitleStyle'>
    We have the right to remove, refuse to post or take any action with respect to any User Contributions for any or no reason in our sole
    discretion. If you wish to complain about information and materials uploaded by other users,
    please contact us on hello@sonarlabs.ai
</p>




<h5 class='titleStyle'>13.  CONTENT STANDARDS   </h5>

<p class='subTitleStyle'>
    The content standards in clause 12 apply to any and all User Contributions and use of social media features.
    User Contributions must in their entirety comply with all applicable federal, state,
    local and international laws and regulations. In particular, you warrant that your User Contributions will not:
</p>



<ul >
    <li class='subTitleStyle'>
        Contain any material which is defamatory, obscene, abhorrent, indecent, abusive, offensive, harassing, violent, hateful, inflammatory or otherwise objectionable.
    </li>
    <li class='subTitleStyle'>
        Infringe any patent, trade mark, trade secret, copyright or other intellectual property or other rights of any other person.
    </li>
    <li class='subTitleStyle'>
        Violate the legal rights (including the rights of publicity and privacy) of others or contain any material that
        could give rise to any civil or criminal liability under applicable laws or regulations or that
        otherwise may be in conflict with these terms and our privacy policy [INSERT AS LINK TO PRIVACY POLICY].
    </li>
    <li class='subTitleStyle'>
        Be likely to deceive any person.
    </li>
    <li class='subTitleStyle'>
        Promote any illegal activity, or advocate, promote or assist any unlawful act.
    </li>
    <li class='subTitleStyle'>
        Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any other person.
    </li>
    <li class='subTitleStyle'>
        Impersonate any person, or misrepresent your identity or affiliation with any person or organisation, including with us.
    </li>
    <li class='subTitleStyle'>
        Involve commercial activities or sales, such as contests, sweepstakes and other sales promotions, barter or advertising.
    </li>
    <li class='subTitleStyle'>
        Give the impression that they emanate from or are endorsed by us or any other person or entity, if this is not the case.
    </li>
</ul>

<p class='subTitleStyle'>
    Whenever you make use of a feature that allows you to upload content to our Website, or to make contact with other users of our Website,
    you must comply with the content standards in clause 12.
</p>

<p class='subTitleStyle'>
    You warrant that any such contribution complies with these standards,
    and you will be liable to us and indemnify us for any breach of that warranty.
    This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.
    We may report any breach of your warranty to the relevant law enforcement authorities and we will co-operate with those authorities
    by disclosing your identity to them. In the event of such a breach,
    your right to use our Website will cease immediately.
</p>




<h5 class='titleStyle'>4.  LIMITATION OF LIABILITY    </h5>

<p class='subTitleStyle'>
    In no event will we, our affiliates or their licensors, service providers, employees, social media influencers, agents,
    officers or directors be liable for damages of any kind, under any legal theory, arising out of or in connection with
    your use, or inability to use, our Website, any websites linked to it, any content on our Website or such other websites
    or any services or items obtained through our Website or such other websites, including any direct, indirect, special,
    incidental, consequential or punitive damages, including but not limited to, personal injury, pain and suffering,
    emotional distress, loss of revenue, loss of profits, loss of business or anticipated savings, loss of use, loss of
    goodwill, loss of data, and whether caused by tort (including negligence), breach of contract or otherwise, even if
    foreseeable.
</p>


<p class='subTitleStyle'>
    Nothing in this clause affects any liability which cannot be excluded or limited under applicable law. We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors and for fraud or fraudulent misrepresentation. Where our liability cannot be excluded, we limit our liability to the fullest extent permitted by the Australian Consumer Law.

</p>

<p class='subTitleStyle'>
    You agree to defend, indemnify and hold us, our affiliates, licensors and service providers, and each of their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns, harmless from and against any claims, liabilities, damages, judgments, awards, losses, costs, expenses or fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these terms or your use of the Website, including, but not limited to, your User Contributions, your use of any information obtained from the Website and any use of the Website’s content, services and products other than as expressly authorised in these terms.
</p>






<h5 class='titleStyle'>15.  WE ARE NOT RESPONSIBLE FOR VIRUSES     </h5>

<p class='subTitleStyle'>
    We do not guarantee that our Website will be secure or free from bugs or viruses or any other type of malicious code or software.
</p>

<p class='subTitleStyle'>
    You are responsible for configuring your technology to access our Website. You should use your own antivirus software.
</p>






<h5 class='titleStyle'>16.  RULES ABOUT LINKING TO OUR WEBSITE    </h5>

<p class='subTitleStyle'>
    You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or
    take advantage of it.
    The website to which you are linking must comply in all respects with the content standards set out in Clause 12.
</p>

<p class='subTitleStyle'>
    You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.
</p>

<p class='subTitleStyle'>
    Our Website must not be framed or mirrored on any other Website, nor may you create a link to any part of our Website other than the home page.
</p>

<p class='subTitleStyle'>
    We reserve the right to withdraw linking permission without notice.
</p>

<p class='subTitleStyle'>
    If you wish to link to or make any use of content on our Website other than that set out above, please contact hello@sonarlabs.ai.
</p>





<h5 class='titleStyle'>17.  GOVERNING LAW</h5>

<p class='subTitleStyle'>
    These terms of use, their subject matter and their formation,
    are governed by laws of the Republic of India. You and we both agree that the jurisdictional courts in India
    will have exclusive jurisdiction.
</p>






<h5 class='titleStyle'>18.  OUR TRADE MARKS ARE REGISTERED </h5>

<p class='subTitleStyle'>
    [“TRADE MARK 1”] and [”TRADE MARK 2”] and all related names, logos,
    product and service names, designs and slogans are our trade marks or the trade marks of our affiliates or licensors.
    You must not use such marks without our prior written permission unless they are part of material you are using as permitted under Clause
    8. Other names, logos, product and service names, designs and slogans on this Website are the trade marks
    of their respective owners and are used by us under licence.
</p>







<h5 class='titleStyle'>19.  PROHIBITED USES  </h5>

<p class='subTitleStyle'>
    You may use the Website only for lawful purposes and in accordance with these terms. You agree not to use the Website:
</p>




<ul >
    <li class='subTitleStyle'>
        in any way that violates any applicable federal, state, local or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from other countries);
    </li>
    <li class='subTitleStyle'>
        for the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information or otherwise;
    </li>
    <li class='subTitleStyle'>
        to send, knowingly receive, upload, download, use or re-use any material which does not comply with the content standards set out in Clause 12 of these terms;
    </li>
    <li class='subTitleStyle'>
        to transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any “junk mail”, “chain letter” or “spam” or any other similar solicitation;
    </li>
    <li class='subTitleStyle'>
        to impersonate or attempt to impersonate us, any of our employees, another user or any other person or entity (including, without limitation, by using email addresses or screen names associated with any of the foregoing); or
    </li>
    <li class='subTitleStyle'>
        to engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Website, or which, as determined by us, may harm us or users of the Website or expose them to liability.
    </li>
</ul>


<p class='subTitleStyle'>
    Additionally, you agree not to:
</p>

<ul >
    <li class='subTitleStyle'>
        use the Website in any manner that could disable, overburden, damage, or impair the Website or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website;
    </li>
    <li class='subTitleStyle'>
        use any robot, spider or other automatic device, process or means to access the Website for any purpose, including monitoring or copying any of the material on the Website;
    </li>
    <li class='subTitleStyle'>
        use any manual process to monitor or copy any of the material on the Website or for any other unauthorised purpose without our prior written consent;
    </li>
    <li class='subTitleStyle'>
        use any automatic or manual process to reverse engineer or decompile any part of the Website;
    </li>
    <li class='subTitleStyle'>
        use any device, software or routine that interferes with the proper working of the Website;
    </li>
    <li class='subTitleStyle'>
        introduce any viruses, trojan horses, worms, logic bombs,
        spyware, computer code, file, program or material which is malicious or technologically harmful;
    </li>
    <li class='subTitleStyle'>
        attempt to gain unauthorised access to, interfere with, damage or disrupt any parts of the Website,
        the server on which the Website is stored, or any server, computer or database connected to the Website;
    </li>
    <li class='subTitleStyle'>
        attack the Website via a denial-of-service attack or a distributed denial-of-service attack; or
    </li>
    <li class='subTitleStyle'>
        otherwise attempt to interfere with the proper working of the Website.
    </li>
</ul>

<p class='subTitleStyle'>
    We may report any of the activities above to the relevant law enforcement authorities and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our Website will cease immediately.
</p>



<h5 class='titleStyle'>20.  OTHER TERMS AND CONDITIONS   </h5>

<p class='subTitleStyle'>
    Additional terms and conditions may also apply to specific portions, services or features of the Website.
    All such additional terms and conditions are incorporated by this reference into these terms.
</p>
`