import React, { useState, useEffect } from 'react'
import { popUpStyle } from '../../newProjectDetails/components/modal/addUser.modal'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import STRINGS from '../../../helpers/staticContentHelper';
import { buttonSecondaryStyle } from '../../newProjectDetails/components/modal/addUser.modal';
import { Checkbox } from '@mui/material';
import { ADD_TO_BLACK_LIST } from '../../../services';
import { checkHasBloackListAggrigates, checkHasFavAggrigates, decryptResponse } from '../../../utils/encryptionDecryption';
import { CircularProgress } from "@mui/material";
import { ReactComponent as CompanyImg } from '../assets/newMaterialCard.svg';
import { ReactComponent as FacilityImg } from '../assets/newFacilityCard.svg';
import { ReactComponent as SelectedImg } from '../assets/newSelectedCard.svg';
import { ReactComponent as PersonalStartImg } from '../assets/newPersonalCard.svg';
import { ReactComponent as CompanyStartImg } from '../assets/newCompanyCard.svg';
import { ReactComponent as RightImg } from '../../../assests/right.svg';
import { ReactComponent as ModalCross } from '../../../assests/modalCross.svg'
import { useNavigate } from 'react-router-dom';

function AddToBloackList({ open, setOpen, cardData, locationsData,
    locationHistoryValues, setLocationHistoryValues,
    setNearbyLocationsData, nearbyLocationsData,
    setLoactionsData, isIndividualUser = true }) {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [filterOptions, setFilterOptions] = useState([]);
    const [reson, setReason] = useState([])
    const [optionsToAddToFav, setOptionsToAddToBloackList] = useState([]);

    const [facilityType, setFacilityType] = useState('')
    const [markFacilityFor, setMarkFacilityFor] = useState('')
    const [stepOne, setShowStepOne] = useState(true);
    const [stepThree, setStepThree] = useState(true);
    const [stepTwo, setStepTwo] = useState(false);

    const handleClose = () => {
        setOpen(prev => !prev)
    }

    const handleAddToBlockList = async () => {
        setIsLoading(true)
        let reqPayload = {
            "supplier_name": cardData.companyName,
            "supplier_address": cardData.formattedAddress,
            "supplier_id": cardData?.supplierId,
            "supplier_location": cardData.location,
            "materials": filterOptions,
            "remarks": reson.join("\n"),
            "available_materials": cardData?.categories,
            "type": isIndividualUser ? "individual" : markFacilityFor,
            "blacklist_facility": facilityType === 'The facility' ? true : false
        }

        const res = await ADD_TO_BLACK_LIST(reqPayload)
        handleClose()
        setIsLoading(false)

        try {
            if (!res.response) {
                const decryptedRes = decryptResponse(res)
                const parsedResponse = JSON.parse(decryptedRes)

                let locationsDataCopy = JSON.parse(JSON.stringify(locationsData))
                let nearByLocationsdataCopy = JSON.parse(JSON.stringify(nearbyLocationsData))
                if (parsedResponse.marked_materials) {
                    let finalData = []
                    let finalNearbyLocationsdata = []
                    locationsDataCopy.forEach(cardLocation => {
                        if (cardLocation.supplierId === cardData.supplierId) {
                            if (facilityType !== 'The facility') {
                                cardLocation.markedMaterials = parsedResponse.marked_materials
                                finalData.push(cardLocation)
                            }
                        } else {
                            finalData.push(cardLocation)
                        }
                    })
                    nearByLocationsdataCopy.forEach(cardLocation => {
                        if (cardLocation.supplierId === cardData.supplierId) {
                            if (facilityType !== 'The facility') {
                                cardLocation.markedMaterials = parsedResponse.marked_materials
                                finalNearbyLocationsdata.push(cardLocation)
                            }
                        } else {
                            finalNearbyLocationsdata.push(cardLocation)
                        }
                    })
                    setNearbyLocationsData(finalNearbyLocationsdata)
                    setLoactionsData(finalData)
                    locationHistoryValues.locationsData = finalData
                    setLocationHistoryValues(locationHistoryValues)
                }
            } else {
                console.log(res.response.data, 'error')
            }
        } catch (error) {

        }
    }

    useEffect(() => {
        if (cardData.categories) {
            let finalSortedList = [...checkHasFavAggrigates(cardData.markedMaterials), ...checkHasBloackListAggrigates(cardData.markedMaterials)];
            let listToRender = []
            cardData.categories.forEach(material => {
                if (!finalSortedList.includes(material)) {
                    listToRender.push(material)
                }
            })
            setOptionsToAddToBloackList(listToRender)
        }
        if (isIndividualUser) {
            setMarkFacilityFor('individual')
        }
    }, [open])

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            onClick={(e) => {
                e.stopPropagation()
            }}
        >
            <Box sx={{ ...popUpStyle, width: '680px', maxHeight: "90vh", display: 'flex', flexDirection: "column", justifyContent: "space-between", padding: '1.5rem', overflowY: "scroll" }}>

                <div className='alter-heaader'>
                    <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center', width: '100%' }}>
                        <p style={{ lineHeight: "1.8rem", margin: '0px', fontSize: '20px', fontWeight: '500', flex: 1 }}>
                            {STRINGS.ADD_TO_BLACKLIST}
                        </p>
                        <div style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center' }}>
                            <ModalCross onClick={() => {
                                handleClose()
                            }} style={{ padding: "0.6rem", backgroundColor: "#f6f7fa", borderRadius: "50%", cursor: "pointer", boxShadow: 'rgba(0, 0, 0, 0.05) 0px 1px 2px 0px' }} />
                        </div>
                    </div>
                    <div className='devider' style={{ marginTop: "1rem" }} />
                </div>

                {stepOne &&
                    <>
                        <p className='section-title-for-card'>{STRINGS.WHAT_WOULD_YOU_LIKE_BLACKLIST}</p>
                        <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem', }}>

                            <div
                                className={`${facilityType === 'Select from materials' && 'select-type-new-card-active'} select-type-new-card`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setFacilityType('Select from materials')
                                }}>
                                {facilityType === 'Select from materials' ?
                                    <SelectedImg /> : <CompanyImg />}
                                <div className='select-type-new-card-info'>
                                    <p>{STRINGS.MATERIALS}</p>
                                    <span>{STRINGS.ADD_SPECIFIC_MATERIALS_AT_THE_FACILITY}</span>
                                </div>
                            </div>
                            <div className={`${facilityType === 'The facility' && 'select-type-new-card-active'} select-type-new-card`}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setFacilityType('The facility')
                                }}>
                                {facilityType === 'The facility' ?
                                    <SelectedImg /> : <FacilityImg />}
                                <div className='select-type-new-card-info'>
                                    <p>{STRINGS.FACILITY}</p>
                                    <span>{STRINGS.ADD_THE_SUPPLIER_TO_YOUR_BLACKLIST}</span>
                                </div>
                            </div>
                        </div>
                        {/* select fav */}
                        {markFacilityFor !== 'individual' &&
                            <>
                                <p className='section-title-for-card' style={{ marginTop: "2rem" }}>{STRINGS.WHERE_WOULD_YOU_LIKE_TO_SAVE}</p>
                                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: '0.5rem', }}>

                                    <div
                                        className={`${markFacilityFor === 'personal' && 'select-type-new-card-active'} select-type-new-card`}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setMarkFacilityFor('personal')
                                        }}>
                                        {markFacilityFor === 'personal' ?
                                            <SelectedImg />
                                            : <PersonalStartImg />}
                                        <div className='select-type-new-card-info'>
                                            <p>{STRINGS.PERSONAL}</p>
                                            <span>{STRINGS.IT_WILL_ONLY_BE_SEEN_BY_YOU}</span>
                                        </div>
                                    </div>
                                    <div className={`${markFacilityFor === 'company' && 'select-type-new-card-active'} select-type-new-card`}
                                        style={{ position: 'relative' }}
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            setMarkFacilityFor('company')
                                        }}>
                                        {markFacilityFor === 'company' ?
                                            <SelectedImg /> : <CompanyStartImg />}
                                        <div className='select-type-new-card-info'>
                                            <p>{STRINGS.COMPANY}</p>
                                            <span>{STRINGS.SHARED_WITH_ORG}</span>
                                        </div>
                                        {markFacilityFor === 'company' &&
                                            <span style={{ position: 'absolute', bottom: "-16px", left: '3px', color: "red", fontSize: '11px', fontWeight: 400, }}>{STRINGS.THIS_CHANGE_WILL_APPLY_TO_YOUR_ENTIRE_ORG}</span>
                                        }
                                    </div>
                                </div>
                            </>
                        }
                    </>
                }

                {stepTwo &&
                    <>
                        {facilityType !== 'The facility' &&
                            <>
                                <p className='section-title-for-card' style={{ marginTop: "2rem" }}>{STRINGS.SELECT_MATERIALS_YOU_WOULD_LIKE_TO_BLACKLIST}</p>
                                {optionsToAddToFav.length > 1 &&
                                    <div style={{ display: 'flex', gap: "0.5rem", padding: '0.3rem', paddingBottom: "0.5rem" }}>
                                        <span style={{ color: "#006D6B", fontSize: "14px", fontWeight: "400", cursor: "pointer", textDecoration: "underLine" }} onClick={() => setFilterOptions(cardData?.categories)}>Select all</span>
                                        <span style={{ color: "#1B2E31", fontSize: "14px", fontWeight: "400", cursor: 'pointer', textDecoration: "underLine" }} onClick={() => setFilterOptions([])}>Clear</span>
                                    </div>}
                                <div className="grid-container" style={{ gridTemplateColumns: "1fr 1fr" }}>
                                    {optionsToAddToFav.map(option => {
                                        return (
                                            <div className="grid-item" style={{ cursor: "pointer" }} onClick={() => {
                                                if (filterOptions.includes(option)) {
                                                    const filteredList = filterOptions.filter(i => i !== option);
                                                    setFilterOptions(filteredList)
                                                } else {
                                                    setFilterOptions(prev => [...prev, option])
                                                }
                                            }}>
                                                <Checkbox
                                                    sx={{
                                                        color: '#1F5D5E',
                                                        '&.Mui-checked': {
                                                            color: '#1F5D5E',
                                                        },
                                                        padding: '0px',
                                                    }}
                                                    id="myCheckbox"
                                                    checked={filterOptions.includes(option)}
                                                />
                                                <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{option}</span>
                                            </div>
                                        )
                                    })}
                                </div>
                            </>
                        }

                        <p className='section-title-for-card' style={{ marginTop: "1rem" }}>{STRINGS.REASON_FOR_BLACKLIST}</p>
                        <div className='text-area-for-block-list' style={{ padding: "0px" }} >

                            <div className="grid-container" style={{ gridTemplateColumns: "1fr 1fr" }}>
                                {['Material not available', 'Quality issues', 'Unresponsive', 'Non-compliance with specifications', 'Unethical practices', 'Not a good fit'].map(option => {
                                    return (
                                        <div className="grid-item" style={{ cursor: "pointer" }} onClick={() => {
                                            if (reson.includes(option)) {
                                                const filteredArray = reson.filter(value => value !== option)
                                                setReason(filteredArray)
                                            } else {
                                                setReason(prev => [...prev, option])
                                            }
                                        }}>
                                            <Checkbox
                                                sx={{
                                                    color: '#1F5D5E',
                                                    '&.Mui-checked': {
                                                        color: '#1F5D5E',
                                                    },
                                                    padding: '0px',
                                                }}
                                                id="myCheckbox"
                                                checked={reson.includes(option)}
                                            />
                                            <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{option}</span>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                }


                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "1.8rem", alignItems: 'center' }}>
                    {stepTwo ?
                        <div className='card-back-button' onClick={() => {
                            setStepTwo(false)
                            setShowStepOne(true)
                        }}>
                            <RightImg className='rotate-image' />
                            {STRINGS.BACK}
                        </div> : <div></div>}

                    <Button variant="outlined"
                        size="large"
                        sx={{ ...buttonSecondaryStyle, width: '150px', height: "40px" }}
                        onClick={() => {
                            if (stepOne) {
                                setStepTwo(true)
                                setShowStepOne(false)
                            } else {
                                handleAddToBlockList()
                            }
                        }}
                        disabled={stepOne ? !facilityType || !markFacilityFor || isLoading : reson.length === 0 || (facilityType !== 'The facility' && filterOptions.length === 0) || isLoading}
                    >
                        {isLoading ?
                            <CircularProgress color="success" size="1rem" />
                            : stepOne ? 'Next' : 'Save'}
                    </Button>
                </div>
                <span style={{ color: '#6D6D6D', fontSize: '12px', marginTop: "1rem" }}>{STRINGS.TOP_UPDATE_OR_MAKE_CHANGES_PLEASE_GO_TO_FAVORITE} <span style={{ color: "#016c6a", fontWeight: 500, cursor: "pointer" }}
                    onClick={() => {
                        navigate('/material-favorite')
                    }}>click here</span>
                </span>
            </Box>
        </Modal>
    )
}

export default AddToBloackList;