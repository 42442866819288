import axios from "axios";
import { LStorage } from "../utils/localStorage.Helper";
import { USER_LOG_OUT } from '../services/user.services';
import { decryptResponse } from "../utils/encryptionDecryption";
const BASE_URL = "http://localhost:8000/api/";
// const BASE_URL = "https://api.sonarlabs.ai/api/";

const configInterceptor = (req) => {
    const accessToken = LStorage.getUserData()?.access_token;
    req.headers["Content-Type"] = 'application/json';
    req.headers["ngrok-skip-browser-warning"] = "any";
    if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
    }
    return req;
};

const configInterceptorForFormData = (req) => {
    const accessToken = LStorage.getUserData()?.access_token;
    req.headers["Content-Type"] = "multipart/form-data";
    req.headers["ngrok-skip-browser-warning"] = "any";
    if (accessToken) {
        req.headers.Authorization = `Bearer ${accessToken}`;
    }
    return req;
};

// this config interceptors user for headers content-type ==> 'application/x-www-form-urlencoded'
export const API = axios.create({
    baseURL: BASE_URL,
});


API.interceptors.request.use(configInterceptor);

export const API_FOR_FORM = axios.create({
    baseURL: BASE_URL,
});

API_FOR_FORM.interceptors.request.use(configInterceptorForFormData);

export const handleApiResponse = async (res) => {
    let result = { message: 'something went wrong' }
    if (res?.response && (res.response.status === 403)) {
        try {
            USER_LOG_OUT().then(res => {
                if (res) {
                    const decryptedData = decryptResponse(res)
                    const parsedDecryptedData = JSON.parse(decryptedData)
                    LStorage.removeUserData()
                    if (parsedDecryptedData?.url) {
                        window.location.href = '/'
                        LStorage.removeUserData()
                        window.open(parsedDecryptedData.url, "_blank");
                    }
                }
            })
        } catch (error) {
            console.log(error)
        }
        // const loggedUserData = LStorage.getUserData()
        // const refreshToken = loggedUserData?.refresh_token;
        // const encryptedPayload = encryptPayload({ refresh_token: refreshToken })
        // const instance = getBooleanFromLocalStorage("instance")
        // const API_END_POINT = getApiEndpoints(instance);
        // await axios.post(API_END_POINT.REFRESH_TOKEN, encryptedPayload).then(res => {
        //     if (res?.data && res?.data?.data) {
        //         const decryptedData = decryptResponse(res?.data?.data)
        //         const parsedDecryptedData = decryptedData ? JSON.parse(decryptedData) : null
        //         let userData = LStorage.getUserData()
        //         userData.access_token = parsedDecryptedData?.new_access_token;
        //         LStorage.setUserData(userData)
        //         window.location.reload();
        //     }
        // }).catch(err => {
        //     LStorage.removeUserData()
        //     window.location.reload();
        // })
    }

    return null
};