export const CONTACT_US = `
<style>
    .subTitleStyle {
        font-size: 1rem;
        margin-top: 0rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
    }

    .titleStyle {
        font-size: 20px;
        margin-top: 1rem;
        font-weight: 600;
        color: gray;
        line-height: 1.4rem;
        font-family: 'Satoshi';
        margin-bottom: 1.2rem;
        color: black;
    }

    span {
        font-weight: 600;
        color: black;
        font-family: 'Satoshi';
    }

    a{
    font-weight: 600;
    color: 'black';
    text-direction: underline;
    cursor: pointer;
          font-family: 'Satoshi';
    }

    ul{
        padding-left:0.8rem;
        margin:0;
        margin-bottom:1rem;
        font-family: 'Satoshi';
    }
</style>

<p class='subTitleStyle' style='margin-top:4rem'>
If you have any questions or concerns regarding this policy, please reach out to us:
</p>

<p class='subTitleStyle' style='margin-bottom:4rem'>
Email: support@sonarlabs.ai <br/>
Phone: +16505594914  <br/> <br/>
Address:<br/>
#4 Bellary Road,   <br/>
3rd Floor Vaibhava Center,  <br/>
Bangalore 560080 India.  
</p>

`