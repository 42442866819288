import React, { useState, useEffect } from 'react'
import LogoWithName from '../../assests/sonar.png';
import SonarLogo from '../../assests/SonarNewLogo.svg';
import NavBarToggler from '../../assests/navbarToggler.svg';
import HistoryInActiveImg from '../../assests/historyInActive.svg';
import HistoryActiveImg from '../../assests/historyActive.svg';
import FileFolderActiveImg from '../../assests/fileFolderActive.svg';
import FileFolderInactiveImg from '../../assests/fileFolderInactive.svg';
import BackImg from '../../assests/back.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import STRINGS from '../../helpers/staticContentHelper';
import { LStorage } from '../../utils/localStorage.Helper';
import { decryptResponse } from '../../utils/encryptionDecryption';
import { useSearchParams } from 'react-router-dom';
import { useFunctionContext } from '../../services/triggerLocationHistoryContext';
import { useStoreLocationHistoryContext } from '../../services/storeMaterialSearchContext';
import { GET_ORG_DETAILS } from '../../services';
import axios from 'axios';
import materislSearchImg from './assets/materialSearch.svg';
import materislSearchActiveImg from './assets/materislSearchActive.svg';
import landfillSearchImg from './assets/landFills.svg';
import landfillSearchFilledImg from './assets/landfillsFilled.svg';
import { GET_FAVOURITE_SUPPLIER_LIST } from '../../services';

function MaterialSideBar({ isTogglerOn, setIsTogglerOn }) {
    const { locationHistoryValues, setLocationHistoryValues } = useStoreLocationHistoryContext();
    const { value } = useFunctionContext();
    const [searchParams, setSearchParams] = useSearchParams()
    let historyId = searchParams.get('historyId')
    let favoriteId = searchParams.get('favId')
    const [history, setSearchHistory] = useState([])
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();
    const userData = LStorage.getUserData();

    useEffect(() => {
        const getListOfHistroty = async () => {
            try {
                let res = await GET_FAVOURITE_SUPPLIER_LIST('personal');
                const parsedResponse = JSON.parse(decryptResponse(res))
                setSearchHistory(parsedResponse)
            } catch (error) {
                console.log(error)
            }
        }
        getListOfHistroty()
    }, [value])

    const getOrgDetails = async () => {
        const reposnse = await GET_ORG_DETAILS();
        const decryptedData = decryptResponse(reposnse)
        const parsedDecryptedData = JSON.parse(decryptedData)
        const { organisation_logo_url } = parsedDecryptedData;
        const copyUserData = JSON.parse(JSON.stringify(userData))
        copyUserData.org_logo = organisation_logo_url
        LStorage.setUserData(copyUserData)
        axios.get(organisation_logo_url).then(() => {
            window.location.reload();
        }).catch(e => { })
    }

    useEffect(() => {
        if ((userData.org_logo && userData?.org_id)) {
            axios.get(userData.org_logo).then(() => { }).catch(e => { getOrgDetails() })
        }
    }, [])

    return (
        <div className={`project-details-navbar-main  ${!isTogglerOn && 'toggler-off'}`}>
            <div className='project-details-navbar'>
                <div className='navbar-header'>
                    {isTogglerOn ?
                        <>
                            <img src={LogoWithName} alt='not-found' height={'32px'} />
                            <img src={NavBarToggler}
                                alt='not-found'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                                className='toggler-icon'
                            />
                        </> :
                        <>
                            <img src={SonarLogo} alt='not-found' className='oh-hover-hide' />
                            <div className='navbar-option on-hover-show'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                            >
                                <img src={NavBarToggler}
                                    alt='not-found'
                                />
                            </div>
                        </>
                    }
                </div>
                <div className='divider-hor'></div>
                <div className={`navbar-option ${path === '/material-search' && 'active-tab'}`}
                    onClick={() => navigate('/material-search')}>
                    <img src={`${path === '/material-search' ? materislSearchActiveImg : materislSearchImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.MATERIALS}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.MATERIALS}</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/landfill-search' && 'active-tab'}`}
                    onClick={() => navigate('/landfill-search')}>
                    <img src={`${path === '/landfill-search' ? landfillSearchFilledImg : landfillSearchImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.LANDFILLS}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.LANDFILLS}</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/material-favorite' && 'active-tab'}`}
                    onClick={() => navigate('/material-favorite')}>
                    <img src={`${path === '/material-favorite' ? HistoryActiveImg : HistoryInActiveImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.FAVORITES}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.FAVORITES}</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/material-history' && 'active-tab'}`}
                    onClick={() => navigate('/material-history')}>
                    <img src={`${path === '/material-history' ? FileFolderActiveImg : FileFolderInactiveImg}`} alt='not-found' width='20px' height='20px' />
                    <span>{STRINGS.HISTORY}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.HISTORY}</p>
                    }
                </div>
                {/* {isTogglerOn &&
                    <div style={{ marginLeft: '1.2rem', display: 'flex', gap: '0.1rem', flexDirection: "column", borderLeft: "1.5px solid #c9c6c6", paddingLeft: '0.2rem' }}>
                        {history.length > 0 &&
                            history.slice(0, 5).map(searchHistory => {
                                return (
                                    <div key={searchHistory.favourite_id} className='navbar-option'
                                        style={{ padding: '4px 5px', fontSize: '0.75rem', overflow: "hidden", color: 'gray', backgroundColor: favoriteId === searchHistory?.favourite_id && "#ECF0F3" }}
                                        onClick={() => {
                                            navigate(`/material-favorite?favId=${searchHistory.favourite_id}`)
                                        }}
                                    >
                                        {searchHistory?.supplier_name?.substring(0, 27)}
                                        {searchHistory?.supplier_name?.length > 27 && '...'}
                                    </div>
                                )
                            })}
                    </div>
                } */}
            </div>
            {/* navbar button  */}
            <div className='project-details-navbar'>
                <div className='project-info' onClick={() => {
                    navigate('/dashboard')
                    setLocationHistoryValues({})
                }}>
                    <img src={BackImg} alt='not-found' className='thread-icon on-hover-move' width='20px' />
                    <div className='navbar-option-no-hover'>
                        <img src={BackImg} alt='not-found' width='20px' height='20px' className='on-hover-move' />
                        <span className='back-to-project'>
                            {STRINGS.BACK_TO_DASHBOARD}
                        </span>
                    </div>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.BACK_TO_DASHBOARD}</p>
                    }
                </div>
                {userData?.org_id &&
                    <div className='navbar-option-profile' style={{ marginTop: '1rem' }}>
                        <img src={(userData?.org_id && userData?.org_logo) ? userData?.org_logo : SonarLogo} alt='not-found' />
                        {isTogglerOn &&
                            <span>{userData?.org_id ? userData?.org_name : userData?.first_name + " " + userData?.last_name}</span>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default MaterialSideBar;