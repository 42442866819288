import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import STRINGS from '../../helpers/staticContentHelper'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: '0.5rem'
};

export default function DuplicateFileModal({ open, setOpen, title, description }) {
    const handleClose = () => setOpen(false);

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setOpen(false)} />
                </Box>
                <Typography id="modal-modal-title" variant="h5" component="h2" sx={{ textAlign: 'center', marginBottom:'1rem' }}>
                    {title}
                </Typography>
                <Typography id="modal-modal-description" sx={{
                    textAlign: 'center',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#6D6D6D',
                    pb: 3
                }}>
                    {description}
                </Typography>
            </Box>
        </Modal>
    );
}