import { getApiEndpoints } from "./api.endpoints";
import { API, handleApiResponse, API_FOR_FORM } from "./axios.config";
import { encryptPayload } from "../utils/encryptionDecryption";
import { getBooleanFromLocalStorage } from "./user.services";
const abortController = new AbortController();

export const CREATE_ASSISTANT = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.CREATE_ASSISTANT, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const UPDATE_ASSISTANT = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.UPDATE_ASSISTANT, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}


export const CREATE_GPT_THREAD = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.CREATE_GPT_THREAD, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const GPT_ADD_THREAD_MSG = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.GPT_ADD_THREAD_MSG, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const DELETE_GPT_ASSISTANT_FILE = async (data) => {
    const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.post(API_END_POINT.DELETE_GPT_ASSISTANT_FILE, encryptedPayload).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const ADD_GPT_ASSISTANT_FILE = async (data) => {
    // const encryptedPayload = encryptPayload(data)
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API_FOR_FORM.post(API_END_POINT.ADD_GPT_ASSISTANT_FILE, data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        return err
    });
    return res
}

export const LOAD_THREAD_MESSAGES = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.LOAD_THREAD_MESSAGES + data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const LOAD_USER_ASSISTANTS = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.LOAD_USER_ASSISTANTS).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const LOAD_GPT_ASSISTANTS_THREADS = async (data) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.LOAD_GPT_ASSISTANTS_THREADS + data).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const LOAD_GPT_ASSISTANTS_FILES = async (assistantId) => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.LOAD_GPT_ASSISTANTS_FILES + assistantId).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}

export const MONTHLY_GPT_CHAT_COUNT = async () => {
    const instance = getBooleanFromLocalStorage("instance")
    const API_END_POINT = getApiEndpoints(instance);

    const res = await API.get(API_END_POINT.MONTHLY_GPT_CHAT_COUNT).then(res => {
        return res.data
    }).catch(err => {
        handleApiResponse(err)
        abortController.abort();
        return err
    });

    return res
}
