import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as SonarWebLogo } from '../../../assests/sonarWebImages/sonarLogo.svg';
import STRINGS from '../../../helpers/staticContentHelper';
import { useNavigate } from 'react-router-dom';
import { LStorage } from '../../../utils/localStorage.Helper';
import { KINDE_LOG_IN } from '../../../services';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { ReactComponent as FileSearchImg } from '../webComponents/assets/documentSearch.svg';
import { ReactComponent as GptImg } from '../webComponents/assets/gpt.svg';
import { ReactComponent as MaterialSearchImg } from '../webComponents/assets/material.svg';
import { KINDE_SIGN_UP } from '../../../services';
import { ReactComponent as HandMenuBurger } from '../../../assests/sonarWebImages/handMenuBurger.svg';
import { ReactComponent as CrossImg } from '../../../assests/sonarWebImages/cross.svg';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function HeaderComponent({ posthog, showSignInButton = true }) {
  const userData = LStorage.getUserData();
  const [isMenuActive, setIsMenuActive] = useState(true);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (isMenuActive) {
      function handleClickOutside(event) {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setIsMenuActive(false)
        }
      }
      document.addEventListener("click", handleClickOutside);
    }
  }, [isMenuActive]);

  const navigate = useNavigate();

  const signIn = () => {
    KINDE_LOG_IN().then(res => {
      if (res) {
        const decryptedData = decryptResponse(res)
        const parsedDecryptedData = JSON.parse(decryptedData)
        if (parsedDecryptedData?.url) {
          window.location.href = parsedDecryptedData.url
        }
      }
    })
  }

  const signUp = () => {
    KINDE_SIGN_UP().then(res => {
      if (res) {
        const decryptedData = decryptResponse(res)
        const parsedDecryptedData = JSON.parse(decryptedData)
        if (parsedDecryptedData?.url) {
          window.location.href = parsedDecryptedData.url
        }
      }
    })
  }

  const postHogRecording = () => {
    if (userData.email) {
      posthog.identify(userData.email)
    }
  }

  return (
    <div className='header-parent'>
      <div className='web-header'>
        <SonarWebLogo onClick={() => {
          navigate('/')
        }} style={{ cursor: 'pointer' }} />
        <div className='web-header-burger-icon'>
          {!showSignInButton ?
            <a href='https://cal.com/sonar/15min' target='_blank' rel="noreferrer">
              <button className='button-secondary-web'
                onClick={() => {
                  posthog.capture('book_demo_clicked')
                }}>
                {STRINGS.BOOK_DEMO}
              </button>
            </a> :
            isMenuActive ?
              <CrossImg className='menu-burger-navar' onClick={() => setIsMenuActive(false)} /> :
              <HandMenuBurger className='menu-burger-navar' onClick={(e) => {
                e.stopPropagation()
                setIsMenuActive(true)
              }} />
          }
          {showSignInButton &&
            <div className={`web-nav-items ${isMenuActive && 'menu-burger-navar-active'}`} ref={wrapperRef}>
              <a className='web-nav-links on-hover-show-options'
                style={{ position: 'relative' }}
                onClick={(e) => {
                  e.stopPropagation()
                  posthog.capture('features_clicked_header')
                }}>
                {STRINGS.PRODUCTS}
                <div className='products-options-on-hover' onClick={(e) => {
                  e.stopPropagation()
                  posthog.capture('features_clicked_header')
                }}>
                  <div style={{
                    backgroundColor: 'white', marginTop: "40px", borderRadius: '8px', padding: "14px",
                    border: '1px solid rgba(236, 240, 243, 1)',
                    boxShadow: '0px 8px 20px -4px #1718181F',
                    boxShadow: '0px 3px 6px -3px #17181814'
                  }}>
                    <div className='product-option'
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate('/')
                      }}>
                      <div className='product-option-image'>
                        <MaterialSearchImg />
                      </div>
                      <div className='product-option-title-container'>
                        <p className='product-option-title'>{STRINGS.MATERIAL_SEARCH}</p>
                        <p className='product-option-sub-title'>{STRINGS.MATERIAL_SEARCH_SUB_TEXT}</p>
                      </div>
                    </div>

                    <div className='product-option'
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate('/document-search')
                      }}>
                      <div className='product-option-image'>
                        <FileSearchImg />
                      </div>
                      <div className='product-option-title-container'>
                        <p className='product-option-title'>{STRINGS.DOCUMENT_SEARCH}</p>
                        <p className='product-option-sub-title'>{STRINGS.DOCUMENT_SEARCH_SUB_TEXT} </p>
                      </div>
                    </div>

                    <div className='product-option'
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate('/construction-gpt')
                      }}>
                      <div className='product-option-image'>
                        <GptImg />
                      </div>
                      <div className='product-option-title-container'>
                        <p className='product-option-title'>{STRINGS.CONSTRUCTION_GPT}</p>
                        <p className='product-option-sub-title'>{STRINGS.CONSTRUCTION_GPT_SUB_TEXT} </p>
                      </div>
                    </div>

                  </div>
                </div>
              </a>

              <Accordion sx={{ backgroundColor: "black" }} id="panel1-dropdown">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: "white" }} />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{ color: 'white', padding: "0px", fontWeight: 600 }}
                >
                  {STRINGS.PRODUCTS}
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px", paddingLeft: "0.5rem" }}>
                  <a className='web-nav-links'
                    onClick={() => {
                      posthog.capture('features_clicked_header')
                      navigate('/')
                    }}>
                    {STRINGS.MATERIAL_SEARCH}
                  </a> <br /> <br />
                  <a
                    className='web-nav-links'
                    onClick={() => {
                      posthog.capture('security_clicked_header')
                      navigate('/document-search')
                    }}>
                    {STRINGS.DOCUMENT_SEARCH}
                  </a><br /> <br />
                  <a
                    className='web-nav-links'
                    onClick={() => {
                      posthog.capture('security_clicked_header')
                      navigate('/construction-gpt')
                    }}>
                    {STRINGS.CONSTRUCTION_GPT}
                  </a>
                </AccordionDetails>
              </Accordion>

              <a className='web-nav-links'
                href='/#feature_section'
                onClick={() => {
                  posthog.capture('features_clicked_header')
                }}>
                {STRINGS.FEATURES}
              </a>
              <a href='/security'
                className='web-nav-links'
                onClick={() => {
                  posthog.capture('security_clicked_header')
                }}>
                {STRINGS.SECURITY}
              </a>
              <a href='https://blog.sonarlabs.ai/'
                className='web-nav-links' onClick={() => {
                  posthog.capture('blog_clicked_header')
                }}>
                {STRINGS.BLOG}
              </a>

              {!userData &&
                <button className='button-primary-web'
                  onClick={() => {
                    signIn()
                    posthog.capture('login_clicked_header')
                  }}>
                  {STRINGS.SIGN_IN_TEXT}
                </button>}

              <div style={{ textAlign: 'center', marginLeft: "-5px" }}>
                <button className={`${userData ? 'button-primary-web' : 'button-secondary-web'}`}
                  onClick={() => {
                    userData ? postHogRecording() :
                      posthog.capture('signup_clicked_heroSection')
                    if (userData && userData?.access_token) {
                      navigate('/dashboard')
                    } else {
                      signUp()
                      posthog.capture('sign_up_clicked_header')
                    }
                  }}>
                  {userData ? "Go to dashboard" : 'Sign up for free'}
                </button>
              </div>

            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default HeaderComponent;