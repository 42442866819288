import React from 'react'
import { ReactComponent as FavouritedMaterial } from "../assets/favourited.svg";
import { ReactComponent as BlockListedMaterial } from "../assets/blocked.svg";
import STRINGS from '../../../helpers/staticContentHelper';
import { ReactComponent as RoundStartBlue } from "../assets/roundStart.svg";

function FavAndBlackListCard({ isFromBlacklist, cardData, type }) {

    return (
        <div className="location-detail-card" style={{ border: '1px solid #8fb7b5' }} >

            <div className='location-card-name'>
                <p style={{ maxWidth: "440px", margin: "0px" }}>{cardData?.name}
                </p>
            </div>

            <div className='detail-with-heading-for-location' >
                <span>{STRINGS.ADDRESS_LABEL}</span>
                <p>{cardData.address}</p>
            </div>

            <div className='detail-with-heading-for-location' >
                <span>{isFromBlacklist ? 'Blacklisted by' : 'Favorited by'}</span>
                <p>{cardData.markedBy}</p>
            </div>

            {isFromBlacklist &&
                <div className='detail-with-heading-for-location' >
                    <span>Reason</span>
                    <p>{cardData.reason}</p>
                </div>}

            <div className='location-search-more-details'>
                {cardData?.categories.length > 0 &&
                    cardData?.categories.map(category => {
                        return (
                            <div className={`location-search-detail-card`}>
                                {!isFromBlacklist ?
                                    <RoundStartBlue className={`${(type === 'personal' || type === 'individual') && 'star-green'} `} /> :
                                    <BlockListedMaterial />
                                }
                                <p> {category}</p>
                            </div>
                        )
                    })}
            </div>
        </div>
    )
}

export default FavAndBlackListCard