import React from 'react';
import scannerImg from './assets/scanner.svg';
import searchThroughImg from './assets/smartRoute.svg';
import dataBaseImg from './assets/favorites.svg';
import STRINGS from '../../../helpers/staticContentHelper';
const SOLUTION_FOOTER_DATA = [
    {
        img: scannerImg,
        title: STRINGS.VERIFIED_LISTING,
        subTitle:STRINGS.VERIFIED_LISTING_SUB_TITLE

    },
    {
        img: searchThroughImg,
        title: STRINGS.START_ROUTE_PLANNING,
        subTitle:STRINGS.PLAN_DELIVERY_PATH_TO_SAVE_TIME_AND_COST
    },
    {
        img: dataBaseImg,
        title: STRINGS.SHARED_FAVORITES,
        subTitle:STRINGS.KEEP_YOUR_TEAM_ALIGNED_WITH_SHARED_LIST
    },
]

function StreamLineSection() {
    return (
        <div className='web-integration-section no-gap-in-mobile-view' id='feature_section' style={{flexDirection:'column' , justifyContent:"flex-start" , alignItems:"flex-start"}}>
              <div style={{marginBottom:"5rem"}}>
                <p style={{lineHeight:"1.5rem" ,marginTop:"0px", fontSize:'18px' , textTransform:'uppercase'}}>{STRINGS.STREAMLINE_YOUR_MATERIAL_PROCUREMENT}</p>
                <h3 style={{margin:'0px', fontSize:"44px" , fontWeight:"500"}}>{STRINGS.DISCOVER_VENDORS_PLAN_LOGISTICS_AND_COLLABORATE}</h3>
            </div>
            <div className='selected-solution solution-card-footer-info-container' style={{ justifyContent:"space-between" , width:"100%"}}>
                {SOLUTION_FOOTER_DATA.map(footerData => {
                    return (
                        <div className='solution-control-f-card'>
                            <img src={footerData.img} alt='no' style={{marginBottom:'1.5rem'}}/>
                            <p style={{margin:'0px', fontSize:'20px' , fontWeight:500 , marginBottom:"0.5rem"}}>{footerData.title}</p>
                            <p style={{margin:'0px' , fontSize:'16px',  fontWeight:400 }}>{footerData.subTitle}</p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default StreamLineSection;