import * as React from 'react';
import Box from '@mui/material/Box';
import modalCross from '../../assests/modalCross.svg'
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import STRINGS from '../../helpers/staticContentHelper'
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { RENAME_FILE_NAME, RENAME_PROJECT_NAME, RENAME_FOLDER_NAME } from '../../services';
import { decryptResponse } from '../../utils/encryptionDecryption';
import CircularProgress from '@mui/material/CircularProgress';

function RenameModal({ setOpenRenameModal, title, openRenameModal, rowData, type, fetchList }) {

    const handleClose = (e) => {
        setOpenRenameModal(false);
    }
    const [newName, setNewName] = React.useState('')
    const [isLoading, setIsLoading] = React.useState(false)
    const entityType = type === STRINGS.TYPE_FILE_FOLDER ? rowData?.file_id ? 'file' : 'folder' : 'project'

    const validateFolderName = () => {
        if (newName.trim().length >= 1)
            return true
        return false
    }
    const renameOption = (e) => {

        const isValidFolderName = validateFolderName()
        if (isValidFolderName) {
            if (entityType === 'file') {
                setIsLoading(true)
                const reqData = {
                    file_id: rowData?.file_id,
                    new_file_name: newName,
                }
                RENAME_FILE_NAME(reqData).then(res => {
                    if (res?.message) {
                        fetchList()
                        setOpenRenameModal(false);
                        setNewName('')
                    }
                    setIsLoading(false)
                })
            }
            else if (entityType === 'folder') {
                setIsLoading(true)
                const reqData = {
                    folder_id: rowData?.folder_id,
                    new_folder_name: newName,
                }
                RENAME_FOLDER_NAME(reqData).then(res => {
                    const decryptedResponse = decryptResponse(res)
                    const parsedDecryptedResponse = JSON.parse(decryptedResponse)
                    if (parsedDecryptedResponse) {
                        fetchList()
                        setOpenRenameModal(false);
                        setNewName('')
                    }
                    setIsLoading(false)
                })
            }
            else {
                setIsLoading(true)
                const reqData = {
                    folder_id: rowData?.folder_id,
                    new_folder_name: newName,
                }
                RENAME_FOLDER_NAME(reqData).then(res => {
                    const decryptedResponse = decryptResponse(res)
                    const parsedDecryptedResponse = JSON.parse(decryptedResponse)
                    if (parsedDecryptedResponse) {
                        fetchList()
                        setOpenRenameModal(false);
                        setNewName('')
                    }
                    setIsLoading(false)
                })
            }
        }
    }
    const baseTyphography = {
        fontFamily: 'Plus Jakarta Sans'
    }
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 3,
        px: 4,
        borderRadius: '0.5rem',
        ...baseTyphography
    };


    const buttonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
            background: validateFolderName() ? '#1B2E31' : '#e6e6e6',
            opacity: '0.9',
            color: validateFolderName() ? 'white' : '#6D6D6D',
        },
        ...baseTyphography
    };
    return (
        <Modal
            open={openRenameModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={() => setOpenRenameModal(false)} />
                </Box>
                <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ textAlign: 'center', ...baseTyphography }}>
                    {title}
                </Typography>
                <Typography id="modal-modal-title" sx={{ textAlign: 'center', color: '#6D6D6D', ...baseTyphography }}>
                    {`Enter a new name for the ${entityType}`}
                </Typography>
                <Typography sx={{ ...baseTyphography }} component="label" display="block" mt={2} fontSize='13px' paddingBottom={'5px'}>
                    {`Current ${entityType} name`}
                </Typography>
                <OutlinedInput size='small'
                    placeholder="Enter new name"
                    fullWidth
                    required
                    sx={{
                        borderRadius: '0.5rem',
                        backgroundColor: '#F8F8FF',
                        ...baseTyphography
                    }}
                    value={rowData?.file_name ?? rowData?.folder_name}
                    disabled={true}
                />
                <Typography sx={{ ...baseTyphography }} component="label" display="block" mt={2} fontSize='13px' paddingBottom={'5px'}>
                    {`New  ${entityType} name`}
                </Typography>
                <OutlinedInput size='small'
                    placeholder={`Enter ${entityType} name`}
                    fullWidth
                    required
                    sx={{
                        borderRadius: '0.5rem',
                        ...baseTyphography
                    }}
                    value={newName}
                    onChange={(e) => setNewName(e.target.value)}
                />
                {isLoading ?
                    <CircularProgress color="success" sx={{ color: '#124429', margin: 'auto', display: 'flex', marginTop: '0.2rem' }} />
                    :
                    <Button type='submit' variant="outlined" size="large" fullWidth
                        sx={{ ...buttonStyle, ...baseTyphography, backgroundColor: validateFolderName() ? '#1B2E31' : '#e6e6e6', color: validateFolderName() ? 'white' : '#6D6D6D', my: 2, disabled: !validateFolderName() }}
                        onClick={renameOption}
                    >
                        {STRINGS.SAVE}
                    </Button>
                }
            </Box>
        </Modal>
    )
}

export default RenameModal