import React, { useEffect, useState } from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import STRINGS from '../../helpers/staticContentHelper';
import UploadFilesImg from '../../assests/upload.svg';
import DropDownButtonWithIcon from '../../components/dropdownComponent';
import { TextareaAutosize } from '@mui/base';
import { styled } from '@mui/system';
import UploadIFilesToGpt from './uploadIFilesToGpt'
import pdfImg from '../../assests/pdfIcon.svg';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { baseTypography } from '../newProjects/components';
import modalCross from '../../assests/modalCrossWhite.svg';
import { useAvailableConfiguredGPTs } from '../../services/availableConfiguredGpts';
import { LOAD_GPT_ASSISTANTS_FILES, DELETE_GPT_ASSISTANT_FILE, CREATE_ASSISTANT, UPDATE_ASSISTANT } from '../../services/constructionGpt.services';
import { decryptResponse } from '../../utils/encryptionDecryption';
import DeleteGPTConfiguredFile from './deleteGptConfiguredFIle';
// custom input box <-- textarea
const StyledTextareaAutosize = styled(TextareaAutosize)({
    width: '100%',
    border: 'none',
    outline: 'none',
    resize: 'none',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
});
const truncateFileName = (fileName) => {
    if (fileName.length > 25) {
        return fileName.slice(0, 25) + '...'
    }
    return fileName
}

export const handleValidation = (data) => {
    const errorValue = {
        isError: false,
        name: '',
        instructions: '',
    }
    Object.entries(data).forEach(([key, value]) => {
        if (!value) {
            errorValue[key] = 'filed is required'
            errorValue.isError = true
        }
    });
    return errorValue
}

function ConfigureGpt() {
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [fileData, setFileData] = useState({});
    const { availableConfiguredGPTs, setAvailableConfiguredGPTs } = useAvailableConfiguredGPTs();
    const [uploadedFileDetails, setUploadedFileDetails] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [formData, setFormData] = useState({
        name: '',
        instructions: '',
    })
    const [error, setError] = useState({
        isError: false,
        name: '',
        instructions: ''
    })

    // to update the existing configured GPT
    const updateGpt = async () => {
        formData['assistant_id'] = availableConfiguredGPTs[0]?.assistant_id
        const res = await UPDATE_ASSISTANT(formData)
        console.log(res)
        if (res) {
            const parsedResponse = JSON.parse(decryptResponse(res))
            setAvailableConfiguredGPTs([parsedResponse.assistant])
        }
        alert('update GPT successful')
        setIsLoading(false);
    }

    const handleOrgFormSubmit = async () => {
        const errorValues = handleValidation(formData)
        setError(errorValues)
        if (!errorValues.isError) {
            setIsLoading(true);
            if (availableConfiguredGPTs.length > 0) {
                updateGpt()
                return
            } else {
                const res = await CREATE_ASSISTANT(formData)
                const parsedResponse = JSON.parse(decryptResponse(res))
                setAvailableConfiguredGPTs([parsedResponse.assistant])
                alert('created GPT successful')
                setIsLoading(false);
            }
        }
    }

    const onChangeFormData = (name, value) => {
        setFormData(prev => {
            return { ...prev, [name]: value }
        })
        if (value) {
            setError(prev => {
                return { ...prev, [name]: '' }
            })
        } else {
            setError(prev => {
                return { ...prev, [name]: 'field is required' }
            })
        }
    }

    const deleteFile = async (fileData) => {
        setFileData(fileData);
        setOpenDeleteModal(true)
    }

    const getAssistantFiles = async (assistantId) => {
        const res = await LOAD_GPT_ASSISTANTS_FILES(assistantId);
        if (res) {
            const decryptedResponse = JSON.parse(decryptResponse(res))
            setUploadedFileDetails(decryptedResponse)
        }
    }

    useEffect(() => {
        if (availableConfiguredGPTs.length > 0) {
            getAssistantFiles(availableConfiguredGPTs[0].assistant_id);
            setFormData({
                name: availableConfiguredGPTs[0].assistant_name,
                instructions: availableConfiguredGPTs[0].instructions,
            })
        }
    }, [availableConfiguredGPTs])

    return (
        <div className='pricing-form-details' style={{ width: "100%", overflow: "hidden" }}>
            <DeleteGPTConfiguredFile
                setOpenDeleteModal={setOpenDeleteModal}
                openDeleteModal={openDeleteModal}
                fileData={fileData}
                uploadedFileDetails={uploadedFileDetails}
                setUploadedFileDetails={setUploadedFileDetails} />
            <UploadIFilesToGpt setUploadedFileDetails={setUploadedFileDetails} open={open} setOpen={setOpen} />
            <div style={{ marginTop: '2rem' }} className='flex-input-box-between'>
                <div style={{ flex: 1 }} >
                    <p className='input-label-text'>{STRINGS.NAME_YOUR_GPT}*</p>
                    <OutlinedInput size='small'
                        placeholder={STRINGS.NAME_YOUR_GPT}
                        fullWidth
                        required
                        value={formData.name}
                        className='outline-input-customized'
                        onChange={(e) => onChangeFormData('name', e.target.value)}
                    />
                    {(error.isError && error.name) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                            {error.name}
                        </span>
                    }
                </div>
            </div>

            <div style={{ marginTop: '2rem' }} className='flex-input-box-between'>
                <div style={{ flex: 1 }} >
                    <p className='input-label-text'>{STRINGS.INSTRUCTIONS}*</p>
                    <OutlinedInput size='small'
                        placeholder="Enter instructions"
                        inputComponent={StyledTextareaAutosize}
                        fullWidth
                        required
                        value={formData.instructions}
                        className='outline-input-customized'
                        inputProps={{
                            minRows: 5, // Minimum rows
                            maxRows: 10, // Optional, maximum rows
                        }}
                        onChange={(e) => onChangeFormData('instructions', e.target.value)}
                    />
                    {(error.isError && error.instructions) &&
                        <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                            {error.instructions}
                        </span>
                    }
                </div>
            </div>


            <div style={{ flex: 1, display: 'flex', justifyContent: 'end', alignItems: "center", marginTop: '20px', marginTop: "2rem" }}>
                <button disabled={isLoading} onClick={() => handleOrgFormSubmit()} className='button-secondary-web' style={{ height: "45px", width: '150px', borderRadius: "0.5rem" }}>
                    {isLoading ? 'Please wait...' : availableConfiguredGPTs.length > 0 ? 'Update GPT' : 'Create GPT'}
                </button>
            </div>

            {availableConfiguredGPTs.length > 0 &&
                <>
                    {uploadedFileDetails.length === 0 &&
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: '20px', marginTop: "2rem" }}>
                            <p className='input-label-text'>{STRINGS.UPLOAD_FILES_TO_PROVIDE_INSTRUCTIONS_TO_GPT}</p>
                            <DropDownButtonWithIcon
                                icon={UploadFilesImg}
                                title={STRINGS.UPLOAD_FILES}
                                onChange={() => {
                                    setOpen((prev => !prev))
                                }}
                            />
                        </div>
                    }
                    {
                        <>
                            <p className='input-label-text' style={{ lineHeight: '1rem', marginTop: "2rem" }}>Files Uploaded*</p>
                            <Box sx={{
                                display: 'flex',
                                alignItems: "center",
                                flexDirection: 'column',
                                gap: '10px',
                                maxHeight: '350px',
                                overflowY: 'scroll',
                                overflowX: 'hidden',
                                marginTop: '1rem',
                                padding: '0.1rem',
                                borderRadius: '0.2rem'
                            }}>

                                {
                                    uploadedFileDetails.map((fileDetails, index) => {
                                        return (
                                            <Box sx={{
                                                p: 1, display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: "white",
                                                width: 'calc(100% - 1rem)',
                                                border: '1px solid #ECF0F3',
                                                borderRadius: '8px'
                                            }} fullWidth >
                                                <Box sx={{ display: 'flex', gap: '0.8rem', overflow: 'hidden' }}>
                                                    <img src={pdfImg} alt='imagenofound' />
                                                    <Typography id="modal-modal-description" sx={{
                                                        textAlign: 'center',
                                                        fontSize: '14px',
                                                        fontWeight: 400,
                                                        color: '#6D6D6D',
                                                        ...baseTypography
                                                    }}>
                                                        {fileDetails.file_name}
                                                    </Typography>
                                                </Box>
                                                <img src={modalCross} alt='crossIcon'
                                                    style={{
                                                        backgroundColor: "#006D6B",
                                                        padding: '0.5rem',
                                                        borderRadius: '1rem',
                                                        cursor: "pointer",
                                                    }} onClick={() => deleteFile(fileDetails)} />
                                            </Box>
                                        )
                                    })
                                }
                            </Box>
                        </>
                    }
                </>
            }
        </div>
    )
}

export default ConfigureGpt