import React, { useState, useEffect } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';
import "./PdfViewer.css";

const PdfViewer = ({ boundingBoxHighlight, fileUrl, pageNumber }) => {

    const DemoFileUrl = "https://aquamarine-tremendous-muskox-851.mypinata.cloud/ipfs/QmYJH1FvAvMdT7sqUTDzDevJgLWR1nrvmb6NzFBXHPfNuN"
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { CurrentPageLabel, jumpToPage } = pageNavigationPluginInstance;
    const renderHighlights = (props) => (
        <div className="highlight-container">
            {boundingBoxHighlight
                .filter((area) => area.pageIndex === props.pageIndex)
                .map((area, idx) => (
                    <div
                        key={idx}
                        className="highlight-area"
                        style={{
                            ...props.getCssProperties(area, props.rotation),
                            background: 'yellow',
                            opacity: 0.4,
                        }}
                    />
                ))}
        </div>
    );

    const highlightPluginInstance = highlightPlugin({
        renderHighlights,
        trigger: Trigger.None,
    });

    useEffect(() => {
        jumpToPage(pageNumber)
    }, [pageNumber])

    return (
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
            <div className='rpvMainDiv'>
                <div className='viewer'>
                    <Viewer
                        initialPage={0}
                        fileUrl={fileUrl ? fileUrl : DemoFileUrl }
                        plugins={[pageNavigationPluginInstance]} />
                </div>
                <div className='pageNumber'>
                    <CurrentPageLabel>
                        {(props) => (
                           <span style={{ color: 'gray' }}>{`${props.currentPage + 1} of ${props.numberOfPages}`}</span>
                        )}
                    </CurrentPageLabel>
                </div>
            </div>
        </Worker>
    );
};

export default PdfViewer;
