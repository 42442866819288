import React from 'react';
import '../newWebPage/websiteIndex.css';
import HeaderComponent from '../newWebPage/webComponents/HeaderComponent';
import FooterSection from '../newWebPage/webComponents/FooterSection';
import { usePostHog } from "posthog-js/react";
import Security from './Security';

function SecurityMain() {
    const posthog = usePostHog()
    return (
        <div className='web-main-container'>
            <HeaderComponent posthog={posthog} />
            <Security posthog={posthog} />
            <FooterSection posthog={posthog} />
        </div>
    )
}

export default SecurityMain;