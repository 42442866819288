import React, { useState } from 'react';
import '../../project/fileUpload.css'
import uploadFile from '../../../assests/uploadFile.svg';
import edidFileImg from '../../../assests/editFileUpload.svg'
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import pdfImg from '../../../assests/pdfIcon.svg'
import modalCross from '../../../assests/modalCrossWhite.svg'
import modalCrossBlack from '../../../assests/modalCross.svg'
import LinearProgress from '@mui/material/LinearProgress';
import EditIcon from "../../../assests/editIcon.svg"
import TextField from '@material-ui/core/TextField';
import IndexingFileModal from '../../projectDetails/fileIndexingModal';
import STRINGS from '../../../helpers/staticContentHelper';
import { UPLOAD_FILES, INITIAL_FILE_INDEXING } from '../../../services';
import Modal from '@mui/material/Modal';
import { decryptResponse, encryptPayload } from '../../../utils/encryptionDecryption';
import { baseTypography, buttonStyle, popUpStyle } from '../../newProjects/components';
import { useSearchParams } from 'react-router-dom';
import { generateUUID } from '../../../utils/encryptionDecryption';

export default function AddFIlesToProject({
    open, setOpen,
    fetchFilesList, projectId,
    fetchFolderFilesList, getFolderIdAndDir,
    setDuplicateFileModal,
    setFileUploadingStatus }) {
    const [dropFile, setDropFile] = useState({})
    const [searchParams] = useSearchParams()
    const [isDuplicateFilesDetected, setIsDuplicateFilesDetected] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [sizeLimit, setSizeLimit] = useState(false)
    const [isEditable, setIsEditable] = useState(false);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [singleFileLimit, setSingleFileLimit] = useState(false);
    const [editedFileName, setEditedFileName] = useState([]);

    const handleClose = () => {
        setIsDuplicateFilesDetected(false)
        setUploadedFiles([])
        setEditedFileName([])
        setDropFile({})
        setIsFileUploading(false)
        setOpen(false)
    };

    const onUpload = (files) => {
        setSingleFileLimit(prev => false)
        let numberOfFiles = []
        setUploadedFiles(prev => {
            numberOfFiles = [...prev, ...files]
            if (numberOfFiles.length > 10) {
                setSizeLimit(false)
                setIsModalOpen(true)
            }
            return numberOfFiles.slice(0, 10)
        })

        numberOfFiles.forEach(file => {
            if (file && (file?.size > 1024 * 1024 * 1024)) {
                setSizeLimit(true)
                setIsModalOpen(true)
                setUploadedFiles([])
            }
        })
    };

    const fetchData = async (fileData) => {
        try {
            const res = await INITIAL_FILE_INDEXING(fileData);
            return res.data;
        } catch (error) {
            console.error('FILE_INDEXING_FAILED', error)
            return null;
        }
    };

    const indexAllFiles = async (files) => {
        try {
            const promises = files.map((fileData) => fetchData(fileData));
            const results = await Promise.all(promises);
            return results;
        } catch (error) {
            return [];
        }
    };

    const uploadSingleFile = async (fileData, folderDir, index) => {
        try {
            let finalRes = {
                index,
                isFileUploaded: true
            }
            const randomNumber = generateUUID()
            setFileUploadingStatus(prev => [...prev, { uuid: randomNumber, file_name: fileData.fileName }])
            const res = await UPLOAD_FILES(fileData.formData);
            if (res?.response?.data) {
                if (res?.response?.data?.error_code === 'SNR_0402') {
                    finalRes.isFileUploaded = false
                    setFileUploadingStatus(prev => {
                        let sortedArray = prev.filter(value => value.uuid !== randomNumber);
                        return sortedArray
                    })
                }
            } else if (res) {
                setFileUploadingStatus(prev => {
                    let sortedArray = prev.filter(value => value.uuid !== randomNumber);
                    return sortedArray
                })
                const decryptedData = decryptResponse(res)
                const parsedResponse = JSON.parse(decryptedData)

                const uploadedFileDetails = parsedResponse?.uploaded_file_details
                if (folderDir) {
                    const { folderId, rowDir } = getFolderIdAndDir();
                    fetchFolderFilesList(rowDir, folderId, false);
                } else {
                    fetchFilesList(false)
                }
                if (uploadedFileDetails) {
                    await indexAllFiles(uploadedFileDetails);
                }
            }
            return finalRes;
        } catch (error) {
            console.error('FILE_INDEXING_FAILED', error)
            return null;
        }
    };

    const uploadAllFiles = async (listOfFiles, folderDir) => {
        try {
            const promises = listOfFiles.map((fileData, index) => uploadSingleFile(fileData, folderDir, index));
            const results = await Promise.all(promises);
            return results;
        } catch (error) {
            return [];
        }
    };


    const addFileToProject = async () => {
        const folderDir = searchParams.get('folderDir')
        let multipleFormData = [];

        uploadedFiles?.forEach((file, index) => {
            let encryptedFileNames = []
            let encryptedDirs = []
            let filesList = []

            let updatedFileName = editedFileName[index] ? editedFileName[index] : file?.name
            updatedFileName = updatedFileName.includes('.pdf') ? updatedFileName : updatedFileName + '.pdf'
            const fileName = encryptPayload(updatedFileName, true)
            encryptedFileNames.push(fileName)
            const encryptedDir = encryptPayload(`${folderDir ? folderDir : 'temp'}`, true)
            encryptedDirs.push(encryptedDir)
            filesList.push(file)

            // individual upload
            const formData = new FormData();
            formData.append('file_type', 'pdf')
            formData.append('dirs', JSON.stringify(encryptedDirs));
            formData.append('file_names', JSON.stringify(encryptedFileNames));

            formData.append('files', file);

            formData.append('project_id', projectId)
            setIsFileUploading(true)
            multipleFormData.push({ formData: formData, fileName: updatedFileName })
        })

        const fileUploadStatus = await uploadAllFiles(multipleFormData, folderDir)
        const duplicateFileNames = []
        fileUploadStatus.forEach(duplicateFile => {
            if (!duplicateFile.isFileUploaded) {
                duplicateFileNames.push(duplicateFile.index)
            }
        })

        setIsFileUploading(false)
        setEditedFileName([])

        const filteredFiles = uploadedFiles.filter((singleFile, index) => duplicateFileNames.includes(index))
        if (filteredFiles.length > 0) {
            setUploadedFiles(filteredFiles)
            setIsDuplicateFilesDetected(true)
        } else {
            handleClose()
        }
    }

    const fileInputRef = React.useRef(null);
    const [selectedFileIndex, setSelectedFileIndex] = React.useState(0);

    const handleCancel = (index) => {
        fileInputRef.current.value = null;
    }

    const handleEditClick = (index) => {
        setSelectedFileIndex(index)
        const newEditList = [...editedFileName]
        uploadedFiles?.forEach((file, index) => {
            const fileToEdit = editedFileName[index] ? editedFileName[index] : file?.name
            newEditList[index] = fileToEdit.includes('.pdf') ? fileToEdit.replace('.pdf', '') : fileToEdit
        })
        setEditedFileName(newEditList);
        setIsEditable(true);
    };

    const handleChange = (event, index) => {
        const newEditList = [...editedFileName]
        newEditList[index] = event.target.value
        setEditedFileName(newEditList);
    };

    React.useEffect(() => {
        if (dropFile.current) {
            dropFile.current.addEventListener('dragover', handleDragOver);
            dropFile.current.addEventListener('drop', handleDrop);
        }
    }, [dropFile]);

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const { files } = e.dataTransfer;

        if (files && files.length) {
            onUpload(files);
        }
    };

    const truncateFileName = (fileName) => {
        if (fileName.length > 25) {
            return fileName.slice(0, 25) + '...'
        }
        return fileName
    }

    const handleFileSelect = (event) => {
        const files = event.target.files;
        onUpload(files);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={popUpStyle}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <img src={modalCrossBlack} alt='crossIcon'
                        style={{
                            backgroundColor: "#1B2E3114",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer"
                        }} onClick={handleClose} />
                </Box>
                <div className='FilesDragAndDrop' style={{ paddingBottom: "0px" }} ref={(e) => {
                    if (e) {
                        setDropFile(dropFile.current = e)
                    }
                }}>
                    <IndexingFileModal open={isModalOpen}
                        setOpen={setIsModalOpen}
                        title={STRINGS.UPLOAD_LIMIT_REACHED}
                        description={sizeLimit ? STRINGS.FILE_SIZE_LIMIT_REACHED : STRINGS.YOU_CAN_UPLOAD_MAXIMUM_4_FILES}
                        singleFileLimitText={singleFileLimit ? STRINGS.SINGLE_FILE_SIZE_LIMIT_REACHED : ''}
                    />
                    <div className='FilesDragAndDrop__area' style={{ padding: "1rem 0px 0px 0px", ...(isDuplicateFilesDetected && { border: 'none', padding: 0, margin: 0 }) }}>
                        {!isDuplicateFilesDetected &&
                            <input type="image" src={uploadFile} alt='fileUpload' height='50px' htmlFor="fileInput"
                                accept="application/pdf,application/vnd.ms-excel"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (!isDuplicateFilesDetected) {
                                        document.getElementById("fileInput").click();
                                    }
                                }} />}
                        {!isDuplicateFilesDetected ?
                            <p style={{ ...baseTypography, textAlign: 'center' }}>{STRINGS.DRAG_AND_DROP} <span />
                                <label htmlFor="fileInput" style={{
                                    color: "#006D6B", cursor: "pointer",
                                    ...baseTypography, textDecoration: "underline"
                                }}>
                                    {STRINGS.BROWSE_FILES}
                                </label>
                                <input
                                    accept="application/pdf,application/vnd.ms-excel"
                                    type="file"
                                    id="fileInput"
                                    style={{ display: "none" }}
                                    onChange={handleFileSelect}
                                    ref={fileInputRef}
                                    multiple
                                />
                                <p style={{ ...baseTypography, textAlign: 'center', lineHeight: "0px" }}>{STRINGS.PDF_WARN}</p>
                            </p> :
                            <p style={{ ...baseTypography, textAlign: 'center', fontWeight: 600, fontSize: '24px', margin: '0px' }}>{isDuplicateFilesDetected && isFileUploading ? 'Adding files' :  'Duplicate files detected'} <span />
                               {!isFileUploading && <p style={{ ...baseTypography, textAlign: 'center', lineHeight: "0px", fontWeight: 300, color: '#6D6D6D', fontSize: '14px' }}> Please rename the files before uploading them again</p>}
                            </p>
                        }
                        {/* <p style={{ ...baseTypography, textAlign: 'center', fontWeight: 600, fontSize: '24px', marginTop: '0px' }}>Adding files </p> */}
                    </div>
                    {/* files list  */}
                    {uploadedFiles?.length > 0 &&
                        <Box sx={{
                            display: 'flex',
                            alignItems: "center",
                            flexDirection: 'column',
                            gap: '10px',
                            maxHeight: '350px',
                            overflowY: 'scroll',
                            overflowX: 'hidden',
                            marginTop: '1rem',
                            padding: '0.1rem',
                            borderRadius: '0.2rem'
                        }}>
                            {uploadedFiles?.map((uploadedFile, index) => {
                                if (isEditable && selectedFileIndex !== index) return <></>
                                return (
                                    <Box sx={{
                                        p: 1, display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        backgroundColor: "white",
                                        width: 'calc(100% - 1rem)',
                                        border: '1px solid #ECF0F3',
                                        borderRadius: '8px'
                                    }} fullWidth >
                                        <Box sx={{ display: 'flex', gap: '0.8rem', overflow: 'hidden' }}>
                                            <img src={pdfImg} alt='imagenofound' />
                                            {isEditable ?
                                                <>
                                                    <TextField
                                                        value={editedFileName[index]}
                                                        onChange={(event) => handleChange(event, index)}
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            style: { width: '190px', fontSize: '14px', padding: '0rem', flex: 1, ...baseTypography }
                                                        }}
                                                    />
                                                </>
                                                :
                                                <Typography id="modal-modal-description" sx={{
                                                    textAlign: 'center',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#6D6D6D',
                                                    ...baseTypography
                                                }}>
                                                    {truncateFileName(editedFileName[index] ? editedFileName[index] : uploadedFile?.name)}
                                                </Typography>
                                            }
                                        </Box>
                                        {!isFileUploading &&
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.9rem' }}>
                                                {!isEditable ? (
                                                    <>
                                                        <img src={EditIcon} alt='editIcon'
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                            onClick={() => handleEditClick(index)} />

                                                        <img src={modalCross} alt='crossIcon'
                                                            style={{
                                                                backgroundColor: "#006D6B",
                                                                padding: '0.5rem',
                                                                borderRadius: '1rem',
                                                                cursor: "pointer",
                                                            }} onClick={() => {
                                                                setIsEditable(false)

                                                                const uploadedFilesList = uploadedFiles?.filter((file, idx) => {
                                                                    return idx !== index
                                                                })
                                                                const editedFileNameList = editedFileName?.filter((file, idx) => {
                                                                    return idx !== index
                                                                })

                                                                if (uploadedFilesList.length === 0) {
                                                                    setIsDuplicateFilesDetected(false)
                                                                }

                                                                setUploadedFiles(uploadedFilesList)

                                                                setEditedFileName(editedFileNameList)
                                                            }
                                                            } />
                                                    </>
                                                )
                                                    :
                                                    (<>
                                                        <Typography id="modal-modal-description" sx={{
                                                            textAlign: 'center',
                                                            fontSize: '15px',
                                                            paddingTop: '0.1rem',
                                                            fontWeight: 600,
                                                            color: '#006D6B',
                                                            cursor: 'pointer',
                                                            ...baseTypography

                                                        }} onClick={() => setIsEditable(false)}>
                                                            {STRINGS.SAVE}
                                                        </Typography>
                                                        <img src={modalCross} alt='crossIcon'
                                                            style={{
                                                                backgroundColor: "#006D6B",
                                                                padding: '0.5rem',
                                                                borderRadius: '1rem',
                                                                cursor: "pointer",
                                                            }} onClick={() => {
                                                                const newEditList = [...editedFileName]
                                                                newEditList[index] = uploadedFile?.name
                                                                setEditedFileName(newEditList);
                                                                setIsEditable(false)
                                                            }
                                                            } />
                                                    </>
                                                    )
                                                }
                                            </Box>}
                                    </Box>
                                )
                            })
                            }
                        </Box>
                    }
                    {!isFileUploading && !isEditable &&
                        <Button variant="outlined" size="large" fullWidth
                            sx={{
                                ...buttonStyle, ...baseTypography,
                                backgroundColor: uploadedFiles.length === 0 ? 'white' : '#1B2E31',
                                color: "white", mt: 2
                            }}
                            onClick={addFileToProject}
                            disabled={uploadedFiles.length === 0}
                        >
                            {STRINGS.ADD_TO_PROJECT}
                        </Button>
                    }
                </div>
            </Box>
        </Modal>
    );
}