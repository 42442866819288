import React from 'react'
import integrationImg from './priorotize.png';
import collaborateImg from './collabrate.png';
import STRINGS from '../../../helpers/staticContentHelper';
import { ReactComponent as RightImg } from './right.svg'

const MULTIPLE_PRIORITIZE_OPTIONS = [
    STRINGS.YOUR_GPT_IS_EXCLUSIVELY_FOR_YOUR_USE,
    STRINGS.DATA_UPLOADED_REMAINS_PRIVATE_AND_SECURE,
    STRINGS.NO_AUTHORIZED_ACCESS_TO_YOUR_CUSTOM_GPT
]

function YourDataSafe() {
    return (
        <>
            <div className='web-integration-section'>
                <div className='integration-information'>
                    <div className='integration-details'>
                        <p className='web-section-sub-title' style={{ textTransform: 'uppercase' }}>{STRINGS.YOUR_DATA_IS_SAFE}</p>
                        <h1 className='web-section-title-security' style={{ marginTop: "0.5rem" }}>{'We prioritize your privacy and control.'}</h1>
                        {
                            MULTIPLE_PRIORITIZE_OPTIONS.map(option => {
                                return (
                                    <div className='prioritize-option-with-mark'>
                                        <RightImg style={{ height: '18px' }} />
                                        {option}
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
                <div className='integration-information'>
                    <img src={integrationImg} alt='no' style={{ maxWidth: '100%', minWidth: '250px', borderRadius: '12px' }} />
                </div>
            </div>

            <div className='web-integration-section row-reverse' style={{ paddingTop: "1rem" }}>
                <div className='integration-information top'>
                    <img src={collaborateImg} alt='no' style={{ maxWidth: '100%', minWidth: '250px', borderRadius: '12px' }} />
                </div>

                <div className='integration-information' style={{ justifyContent: "flex-end" }}>
                    <div className='integration-details' >
                        <p className='web-section-sub-title' style={{ textTransform: 'uppercase', }}>{STRINGS.COLLABORATE} </p>
                        <h1 className='web-section-title-security' style={{ marginTop: "0.5rem", maxWidth: "480px" }}>{STRINGS.BRING_YOUR_TEAM_INTO_THE_LOOP}</h1>

                        <div className='prioritize-option-with-mark' style={{ color: "#00000099" }}>
                            {STRINGS.INVITE_YOUR_TEAM_ACCESS_THE_GPT_YOU_HAVE_BUILT}
                        </div>

                    </div>
                </div>

            </div>
        </>

    )
}

export default YourDataSafe