import React from 'react'
import sonarLogo from '../assests/sonar.png'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { LStorage } from '../utils/localStorage.Helper';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import { USER_LOG_OUT } from '../services';
import { usePostHog } from "posthog-js/react";
import { decryptResponse } from '../utils/encryptionDecryption';

function Header({ bookDemo = false }) {
    const posthog = usePostHog()
    const navigate = useNavigate();
    const userData = LStorage.getUserData();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };



    const buttonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        color: "black",
        textTransform: 'none',
        "&:hover": {
            border: "1px solid #E6E8EA",
        },

    };

    const logOutUser = () => {
        const reqData = {
            refresh_token: userData.refresh_token
        }
        USER_LOG_OUT(reqData).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                posthog.reset()
                LStorage.removeUserData()
                handleClose();
                navigate('/')
                if (parsedDecryptedData?.url) {
                    window.open(parsedDecryptedData.url, "_blank");
                }
            }
        })
    }

    return (
        <Box
            sx={{
                borderRadius: "0px",
                display: 'flex',
                justifyItems: "space-between",
                alignItems: "center",
                padding: "0.3rem 1.8rem",
                backgroundColor: "white",
                borderBottom: bookDemo ? '3px solid whitesmoke' : 0
            }}>
            <Box
                edge="start"
                aria-label="menu"
                sx={{ flexGrow: 1, justifyContent: 'flex-start' }}
            >
                <img src={sonarLogo} alt='not-found' height={'32px'} onClick={() => navigate("/")} style={{ cursor: 'pointer' }} />
            </Box>

            {userData ?
                <>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '2.8rem',
                            height: '2.8rem',
                            borderRadius: '50%',
                            backgroundColor: '#006D6B1F',
                            cursor: 'pointer'
                        }}
                        id="demo-positioned-button"
                        aria-controls={open ? 'demo-positioned-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        {userData?.first_name?.substring(0, 1) + userData?.last_name?.substring(0, 1)}
                    </Box>
                    <Typography style={{
                        fontSize: "18px",
                        fontWeight: 500,
                        lineHeight: '20px',
                        marginLeft: '0.5rem'
                    }}>
                        {userData?.first_name + " " + userData?.last_name}
                    </Typography>
                </>
                :
                bookDemo ?
                    <a href='https://cal.com/sonar/15min?date=2024-04-17&month=2024-04'>
                        <Button variant="outlined" size="large" sx={{ ...buttonStyle }} onClick={() => {
                            posthog.capture('book_demo_clicked')
                        }}>Book a demo</Button>
                    </a>
                    :
                    <Button variant="outlined" size="large"
                        sx={{ ...buttonStyle, backgroundColor: '#1B2E31', color: "white" }}
                        onClick={() => navigate(userData?.access_token ? 'projects' : '/login')} > Sign up for free</Button>
            }

            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ marginTop: '3rem' }}
            >
                <MenuItem sx={{ minWidth: '150px' }} onClick={() => {
                    logOutUser()
                }} >Logout</MenuItem>
            </Menu>
        </Box>
    )
}

export default Header