import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Button } from '@mui/material';
import { useAlert } from '../../utils/alertsProvider';
function Alert() {
    const { alert, closeAlert } = useAlert();
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: '0.5rem',
        height: 200,
        display: "flex",
        flexDirection: 'column',
        justifyContent: "space-between",
        padding: 2,
        border: 'none',
        "&:focus": {
            outline: 'none'
        }
    };

    const cancelButtonStyle = {
        border: "1px solid #E6E8EA",
        borderRadius: "10px",
        textTransform: 'none',
        background: '#1B2E31',
        color: 'white',
        "&:hover": {
            border: "1px solid #E6E8EA",
            background: '#1B2E31',
            color: 'white'
        },
        fontFamily: 'Plus Jakarta Sans'
    };

    return (
        <Modal
            open={alert.isVisible}
            onClose={closeAlert}
            aria-labelledby="modal-modal-title"
            onClick={(e) => e.stopPropagation()}
        >
            <Box sx={style}>

                <div className='alter-heaader'>
                    <p className='alert-header-text'>{alert.title}</p>
                    <div className='devider' style={{ marginTop: "1rem" }} />
                </div>

                <div className='alert-body'>
                    <span className='alert-body-text jakarta-font'>
                        {alert.highlightText &&
                            <>
                                <span style={{ fontWeight: 600 }}>{alert.highlightText}</span> <br />
                            </>
                        }
                        {alert.message}
                    </span>
                </div>

                <div className='alert-footer jakarta-font'>
                    <div className='devider' style={{ marginBottom: "1rem" }} />
                    <Button
                        type='submit'
                        variant="outlined"
                        size="large"
                        sx={{ ...cancelButtonStyle, width: "auto" }}
                        onClick={closeAlert}
                    >
                        {alert.buttonText}
                    </Button>
                </div>
            </Box>
        </Modal>
    )
}

export default Alert