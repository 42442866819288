import React, { useEffect } from 'react';
import '../newWebPage/websiteIndex.css';
import { usePostHog } from "posthog-js/react";
import HeaderComponent from '../newWebPage/webComponents/HeaderComponent';
import FooterSection from '../newWebPage/webComponents/FooterSection';
import MaterialSearchHeroBanner from './components/herobanner';
import StreamLineSection from './components/streamlineSection';
import FindAnswersSection from './components/findAnswersSection';
import  UserFormMain from '../formPage/userFormMain'
function MaterialSearchPage() {
    const posthog = usePostHog()
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='web-main-container'>
            <HeaderComponent posthog={posthog} />
            <MaterialSearchHeroBanner />
            <StreamLineSection />
            <FindAnswersSection />
            <UserFormMain />
            <FooterSection posthog={posthog} />
        </div>
    )
}

export default MaterialSearchPage;