import React, { useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import STRINGS from '../../../helpers/staticContentHelper';
import { useNavigate } from 'react-router-dom';
import { ActionOptions, StatusIndicator, baseTypography } from '../../newProjects/components';
import DropDownButtonWithIcon from '../../../components/dropdownComponent';
import UploadFilesImg from '../../../assests/upload.svg';
import CreateNewFolderImg from '../../../assests/createNewFolder.svg'
import noRPojectFound from '../../../assests/noProjectsFound.svg'
import AddFIlesToProject from './addFilesToProject';
import FolderImg from '../../../assests/folder.svg'
import FileImg from '../../../assests/file.svg';
import CreateFolderModal from '../../../components/createFolderModal/CreateFolderModal'
import BackImg from '../../../assests/back.svg';
import HistoryModal from '../../../components/historyModal';
import DuplicateFileModal from '../../../components/duplicateFileModal/DuplicateFolderModal';
import IndexingFileModal from '../../projectDetails/fileIndexingModal';
import { IS_USER_ADMIN } from '../../../utils/encryptionDecryption';
import { LStorage } from '../../../utils/localStorage.Helper';
import { TableSortLabel } from '@mui/material';
import moment from 'moment';
import { checkUserPermission } from '../../../utils/encryptionDecryption';

const ICONS_FOR_FILES = {
    file_name: FileImg,
    folder_name: FolderImg
}

function ProjectsFilesList({
    tableData,
    setTableData,
    projectsListLoading,
    fetchFilesList,
    projectId,
    fetchFolderFiles,
    setSelectedFile,
    setFetchingResult,
    projectName,
    getFolderIdAndDir,
    selectedFile,
    setHighlightedPdf,
    searchParams,
    setSearchParams,
    activeFolderName,
    setUserSelectedAnswer,
    deleteList,
    celeryStatus,
    fileUploadingStatus,
    setFileUploadingStatus
}) {
    const userData = LStorage.getUserData();
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const navigate = useNavigate();
    const [open, setOpen] = useState(false)
    const [openFolderModal, setOpenFolderModal] = useState(false)
    const [isFileHistoryOpen, setIsFileHistoryOpen] = useState(false);
    const [duplicateFileModal, setDuplicateFileModal] = useState(false);
    const [indexingModal, setIndexingModal] = useState(false)
    const [isFileDeleting, setIsFileDeleting] = useState(false)
    const getKeyFromEnumValues = (enumValue, rowData) => {
        let finalKey = {
            key: '',
            ICON: ''
        }
        enumValue.map(key => {
            if (rowData[key]) {
                finalKey.key = key
                finalKey.ICON = ICONS_FOR_FILES[key]
                return key
            }
        })
        return finalKey
    }

    const columns = [
        {
            id: ['file_name', 'folder_name'], label: 'NAME', align: 'start', width: '100%',
            formatWithIcon: (value, icon) => {
                return (
                    <div style={{ display: 'flex', justifyContent: 'center', width: 'fit-content' }} className='table-hover-tool-tip'>
                        {icon &&
                            <img src={icon} alt='notfound' style={{
                                marginRight: '0.4rem'
                            }} height='16px' width='20px' />
                        }
                        {value?.length > 50 ? value?.substring(0, 50) + '...' : value}
                        {value?.length > 50 &&
                            <span className='navbar-tooltip'>{value}</span>
                        }
                    </div>
                )
            },
        },
        {
            id: 'file_status', label: 'STATUS', align: 'start',
            minWidth: '150px',
            format: (value, row, deleteList) => {
                return row.folder_name ? <></> : <StatusIndicator value={value} deleteList={deleteList} row={row} />
            }
        },
        {
            id: 'created_at', label: 'UPLOAD DATE', align: 'start', minWidth: '180px',
            format: (value) => value?.substring(0, 10)
        },
        {
            id: 'actions', label: 'ACTIONS', align: 'start',
            minWidth: '150px',
            format: (value, row) => {
                return <ActionOptions isFromFiles={true} setIsFileHistoryOpen={setIsFileHistoryOpen} rowData={row} fetchList={fetchFolderFiles} setSelectedRow={setSelectedFile} celeryStatus={celeryStatus} />
            },
        },
    ];

    const handleSortRequest = (sortByName) => {
        if (sortByName === 'NAME') {
            setOrderBy(sortByName)
            const sortedRows = tableData.slice().sort((a, b) => {
                if (order === 'asc') {
                    setOrder('dsc')
                    return a['file_name'] ? a['file_name'].localeCompare(b['file_name'] || b['folder_name']) : a['folder_name'].localeCompare(b['folder_name'] || b['folder_name']);
                }
                setOrder('asc')
                return b['file_name'] ? b['file_name'].localeCompare(a['file_name'] || a['folder_name']) : b['folder_name'].localeCompare(a['folder_name'] || a['folder_name']);
            });
            setTableData(sortedRows)
        }
        if (sortByName === 'UPLOAD DATE') {
            setOrderBy(sortByName)
            const sortedRows = tableData.slice().sort((a, b) => {
                if (order === 'asc') {
                    setOrder('dsc')
                    return moment.utc(a['created_at']).diff(moment(b['created_at']))
                }
                setOrder('asc')
                return moment.utc(b['created_at']).diff(moment(a['created_at']));
            });
            setTableData(sortedRows)
        }
    }

    return (
        <>
            <AddFIlesToProject
                open={open}
                setOpen={setOpen}
                fetchFilesList={fetchFilesList}
                fetchFolderFilesList={fetchFolderFiles}
                projectId={projectId}
                getFolderIdAndDir={getFolderIdAndDir}
                setDuplicateFileModal={setDuplicateFileModal}
                fileUploadingStatus={fileUploadingStatus}
                setFileUploadingStatus={setFileUploadingStatus}
            />
            <DuplicateFileModal open={duplicateFileModal} setOpen={setDuplicateFileModal} title={STRINGS.DUPLICATE_FILE_TITLE} description={STRINGS.DUPLICATE_FILE_DESCRIPTION} />
            <CreateFolderModal
                openFolderModal={openFolderModal}
                setOpenFolderModal={setOpenFolderModal}
                title={"Create new folder"}
                loadDocumentsList={fetchFolderFiles}
            />
            <div className='space-between' style={{ marginBottom: "1rem" }}>
                <div style={{ display: 'flex', gap: '1rem', alignItems: 'center' }}>
                    <img src={BackImg} alt='not-found' width='20px' height='20px'
                        className='on-hover-move'
                        style={{ cursor: 'pointer', marginLeft: '-1rem' }}
                        onClick={() => {
                            const { folderCount, rowDir } = getFolderIdAndDir()
                            if (folderCount === 1) {
                                searchParams.delete("folderDir");
                                setSearchParams(searchParams)
                            } else if (folderCount > 1) {
                                searchParams.set("folderDir", rowDir);
                                setSearchParams(searchParams)
                            } else {
                                navigate('/projects')
                            }
                        }} />
                    <span style={{ marginLeft: '-0.5rem' }}>
                        {activeFolderName}
                    </span>
                    {/* <div className='search-input-projects'>
                        <img src={searchImg} alt='not-found' />
                        <TextField
                            // onChange={(event) => handleChange(event, index)}
                            placeholder='Search by file name'
                            InputProps={{
                                disableUnderline: true,
                                style: { padding: '0px', width: '250px', fontSize: '14px', paddingTop: '0.2rem' }
                            }}
                        />
                    </div> */}
                </div>
                <div className='filters-header'>
                    {checkUserPermission("UPLOAD", userData) && 
                    <DropDownButtonWithIcon
                        icon={UploadFilesImg}
                        title={STRINGS.UPLOAD_FILES}
                        onChange={() => setOpen((prev => !prev))}
                    />}
                   {checkUserPermission("CREATE", userData) &&  
                   <DropDownButtonWithIcon
                        icon={CreateNewFolderImg}
                        title={STRINGS.CREATE_FOLDER}
                        onChange={() => setOpenFolderModal((prev => !prev))}
                    />}
                </div>
            </div>
            {projectsListLoading ?
                <div className='no-projects-found'>
                    <div class="loading-dots">
                        <div class="dot"></div>
                        <div class="dot"></div>
                        <div class="dot"></div>
                    </div>
                </div> : tableData.length === 0 ?
                    <div className='no-projects-found'>
                        <img src={noRPojectFound} alt='notfound' style={{
                            borderRadius: '0.5rem',
                        }} />
                        <span style={{ fontSize: '20px', ...baseTypography, marginTop: '1rem', lineHeight: '1.5rem' }}>
                            {STRINGS.NO_FILES}
                        </span>
                    </div>
                    :
                    <TableContainer sx={{ px: '1rem', width: 'calc(100% - 2rem)', paddingBottom: '10rem' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{
                                                width: column.width,
                                                ...baseTypography,
                                                minWidth: column.minWidth,
                                                padding: "0.4rem 0.6rem",
                                                color: '#6D6D6D'
                                            }}
                                        >
                                            {['NAME', 'UPLOAD DATE'].includes(column.label) ?
                                                <TableSortLabel
                                                    active={orderBy === column.label}
                                                    direction={orderBy === column.label ? order : 'asc'}
                                                    onClick={() => handleSortRequest(column.label)}>
                                                    {column.label}
                                                </TableSortLabel> :
                                                <>{column.label}</>
                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tableData
                                    .map((row) => {
                                        return (
                                            <TableRow hover
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={row.code}
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    if (row['folder_name']) {
                                                        // localStorage.setItem('s3File', row.s3_url)
                                                        // navigate(`/search?fileid=${row.file_id}`)
                                                        const dir = row?.dir + `/${row?.folder_id}`
                                                        navigate(`/project-files/${projectId}?folderDir=${dir}&projectName=${projectName}`)
                                                    } else {
                                                        if (row?.file_status) {
                                                            searchParams.set("activeTab", 'Home');
                                                            setSearchParams(searchParams)
                                                            setUserSelectedAnswer({})
                                                            setSelectedFile(row);
                                                            setHighlightedPdf(null)
                                                            setFetchingResult(false)
                                                        }
                                                        else {
                                                            if (deleteList?.includes(row?.file_id)) {
                                                                setIsFileDeleting(true)
                                                            }

                                                            setIndexingModal(true)
                                                        }
                                                    }
                                                }}>
                                                {columns.map((column) => {
                                                    let searchKey = ''
                                                    let FINAL_ICON = ''
                                                    if (Array.isArray(column.id)) {
                                                        const { key, ICON } = getKeyFromEnumValues(column.id, row);
                                                        searchKey = key;
                                                        FINAL_ICON = ICON
                                                    } else {
                                                        searchKey = column.id
                                                    }
                                                    const value = row[searchKey];
                                                    return (
                                                        <TableCell key={column.id} align={column.align}
                                                            sx={{
                                                                position: 'relative', ...baseTypography, padding: '0.5rem',
                                                            }}>
                                                            {column.formatWithIcon ?
                                                                column.formatWithIcon(value, FINAL_ICON) :
                                                                column.format
                                                                    ? column.format(value, row, deleteList)
                                                                    : value
                                                            }
                                                        </TableCell>
                                                    );
                                                })}

                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
            }
            <HistoryModal setIsModalOpen={setIsFileHistoryOpen} isModalOpen={isFileHistoryOpen} selectedRow={selectedFile} setSearchParams={setSearchParams} />
            <IndexingFileModal open={indexingModal}
                setOpen={() => {
                    setIsFileDeleting(false)
                    setIndexingModal(false)
                }}
                title={isFileDeleting ? STRINGS.DELETE_IN_PROGRESS : STRINGS.WE_ARE_INDEXING_FILE}
                description={STRINGS.CHECK_AFTER_FEW_MINUTES} />
        </>
    )
}

export default ProjectsFilesList