const BACKEND_ENDPOINT = process.env.REACT_APP_BACKEND_ENDPOINT
const US_BACKEND_ENDPOINT = process.env.REACT_APP_US_BACKEND_ENDPOINT
const BASE_URL = BACKEND_ENDPOINT;
const US_BASE_URL = US_BACKEND_ENDPOINT;
export const USA_SERVER_VALIDATION = `${US_BACKEND_ENDPOINT}/validate-instance/`;
export const IP_INFO_URL = "https://ipinfo.io?token="
const getBaseUrl = (useUsBaseUrl) => useUsBaseUrl ? US_BASE_URL : BASE_URL;

export const getApiEndpoints = (useUsBaseUrl = false) => {
    const baseUrl = getBaseUrl(useUsBaseUrl);
    return {
        ASK_QUESTIONS: `${baseUrl}/user-questions/`,
        ASK_QUESTIONS_AUTH: `${baseUrl}/user-questions-auth/`,
        USER_SIGN_UP: `${baseUrl}/register-user/`,
        USER_LOG_IN: `${baseUrl}/login-user/`,
        VERIFY_USER: `${baseUrl}/verify-user-registration/`,
        RESEND_VERIFICATION_LINK: `${baseUrl}/resend-verification-link/`,
        GET_PROJECTS_LIST: `${baseUrl}/list-user-projects/`,
        ADD_PROJECT: `${baseUrl}/add-project/`,
        UPLOAD_FILES: `${baseUrl}/upload-file/`,
        GET_FILES_LIST: `${baseUrl}/list-uploaded-files/?project_id=`,
        REFRESH_TOKEN: `${baseUrl}/refresh-token/`,
        RESET_PASSWORD_OTP: `${baseUrl}/otp-reset-password/`,
        VERIFY_PASSWORD_OTP: `${baseUrl}/verify-reset-otp/`,
        RESET_PASSWORD: `${baseUrl}/reset-password/`,
        SUGGESTION_QUESTIONS_AUTH: `${baseUrl}/suggestion-questions-auth/`,
        SUGGESTION_QUESTIONS: `${baseUrl}/suggestion-questions/`,
        GET_HIGHLIGHTED_PDF: `${baseUrl}/highlight-pdf/`,
        LOG_OUT_USER: `${baseUrl}/logout-user/`,
        INITIAL_PDF_INDEXING: `${baseUrl}/initial-pdf-indexing/`,
        CREATE_NEW_FOLDER: `${baseUrl}/create-new-folder/`,
        FETCH_FOLDER_FILES: `${baseUrl}/list-folder-files/`,
        RENAME_PROJECT_NAME: `${baseUrl}/rename-project/`,
        RENAME_FILE_NAME: `${baseUrl}/rename-file/`,
        RENAME_FOLDER_NAME: `${baseUrl}/rename-folder/`,
        DELETE_FILE: `${baseUrl}/delete-file/`,
        INITIAL_MULTI_FILE_INDEXING: `${baseUrl}/initial-multi-pdf-indexing/`,
        FETCH_PROJECT_HISTORY: `${baseUrl}/selected-project-threads/?project_id=`,
        FETCH_FILE_HISTORY: `${baseUrl}/selected-file-threads/?file_id=`,
        FETCH_THREAD_HISTORY: `${baseUrl}/selected-thread-history/?thread_id=`,
        FILE_INDEXING_STATUS: `${baseUrl}/selected-file-status/`,
        MULTI_PDF_SEARCH: `${baseUrl}/multi-pdf-search/`,
        GET_HIGHLIGHTED_MULTIPLE_PDF: `${baseUrl}/highlight-multi-pdf/`,
        CELERY_TASK_STATUS: `${baseUrl}/celery-task-status/`,
        CELERY_TASK_PENDING_LIST: `${baseUrl}/list-pending-tasks/`,
        CELERY_PENDING_TASKS_STATUS: `${baseUrl}/celery-tasks-status/`,
        GET_PDF_FILE_DETAILS: `${baseUrl}/get-file-details/?file_id=`,
        INVITE_USER_TO_ORG: `${baseUrl}/invite-new-org-user/`,
        INVITE_USER_TO_POJECT: `${baseUrl}/invite-new-project-user/`,
        LIST_ORG_MEMBERS: `${baseUrl}/list-org-members/?org_id=`,
        LIST_PROJECT_MEMBERS: `${baseUrl}/list-project-members/?`,
        GENERATE_RFI: `${baseUrl}/generate-rfi/`,
        RFI_HISTORY: `${baseUrl}/list-rfi-history/?project_id=`,
        KINDE_AUTH_URL: `${baseUrl}/kinde-callback/`,
        DELTE_USER_FROM_ORG: `${baseUrl}/delete-org-user/`,
        DELTE_USER_FROM_PROJECT: `${baseUrl}/remove-project-user/`,
        FIND_MATERIAL_PLACE: `${baseUrl}/find-material-place/`,
        MATERIAL_SEARCH_HISTORY: `${baseUrl}/material-search-history/`,
        LIST_MATERIAL_SEARCH_HISTORY: `${baseUrl}/list-material-search-history/`,
        ORG_DETAILS:`${baseUrl}/org-details/`,
        BLACK_LIST:`${baseUrl}/mark-blacklist-supplier/`,
        UNDO_BLACK_LIST:`${baseUrl}/undo-blacklist-supplier/`,
        FAVOURITE_SUPPLIER_LIST:`${baseUrl}/list-favourite-suppliers/?type=`,
        BLACKLISTED_SUPPLIER_LIST:`${baseUrl}/list-blacklisted-suppliers/?type=`,
        ADD_TO_FAV_LIST:`${baseUrl}/mark-favourite-supplier/`,
        UNDO_FAV_LIST:`${baseUrl}/undo-favourite-supplier/`,
        SUBMIT_USER_DETAILS:`${baseUrl}/submit-form-details/`,
        GET_SEARCH_COUNT_LEFT:`${baseUrl}/user-monthly-search-count/`,
        FIND_LANDFILL_PLACE:`${baseUrl}/find-landfill-place/`,
        LANDFILL_SAVE_HISTORY:`${baseUrl}/landfill-search-history/`, 
        LANDFILL_MARK_FAVORITE:`${baseUrl}/mark-favourite-landfill/`,
        LANDFILL_MARK_BLACKLIST:`${baseUrl}/mark-blacklist-landfill/`,
        UNDO_LANDFILL_FAVORITE:`${baseUrl}/undo-favourite-landfill/`,
        UNDO_LANDFILL_BLACKLIST:`${baseUrl}/undo-blacklist-landfill/`,
        GET_LANDFILL_HISTORY:`${baseUrl}/list-landfill-search-history/`,
        GET_LANDFILL_USER_COUNT:`${baseUrl}/user-monthly-landfill-search-count/`,
        GET_LANDFILL_FAVORITE_HISTORY:`${baseUrl}/list-favourite-landfills/?type=`,
        GET_LANDFILL_BLACKLIST_HISTORY:`${baseUrl}/list-blacklisted-landfills/?type=`,
        CREATE_ASSISTANT:`${baseUrl}/create-assistant/`,
        UPDATE_ASSISTANT:`${baseUrl}/update-assistant/`,
        CREATE_GPT_THREAD:`${baseUrl}/create-thread/`,
        GPT_ADD_THREAD_MSG:`${baseUrl}/add-thread-message/`,
        DELETE_GPT_ASSISTANT_FILE:`${baseUrl}/delete-assistant-files/`,
        ADD_GPT_ASSISTANT_FILE:`${baseUrl}/add-assistant-files/`,
        LOAD_THREAD_MESSAGES:`${baseUrl}/load-thread-messages/?thread_id=`,
        LOAD_USER_ASSISTANTS:`${baseUrl}/load-user-assistants/`,
        LOAD_GPT_ASSISTANTS_THREADS:`${baseUrl}/load-assistant-threads/?assistant_id=`,
        LOAD_GPT_ASSISTANTS_FILES:`${baseUrl}/load-assistant-files/?assistant_id=`,
        MONTHLY_GPT_CHAT_COUNT:`${baseUrl}/user-monthly-chat-count/`,
        GOOGLE_MAPS_AUTO_COMPLETE: "https://places.googleapis.com/v1/places:autocomplete?",
        GOOGLE_MAPS_GET_GEOCODE: 'https://maps.googleapis.com/maps/api/geocode/json',
    };
};