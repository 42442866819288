import React, { useState } from 'react'
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import pdfImg from '../../../../assests/pdfIcon.svg'
import { CircularProgress } from '@mui/material';
import chevronLeft from '../../../../assests/chevronLeft.svg';

const truncateFileName = (fileName) => {
    if (fileName.length > 25) {
        return fileName.slice(0, 25) + '...'
    }
    return fileName
}

function FileUploadStatus({ fileUploadingStatus }) {
    const [isHideActive, setIsHideActive] = useState(false)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: "center",
            flexDirection: 'column',
            backgroundColor: "#DBDEE0",
            height: 'fit-content',
            overflowY: 'scroll',
            overflowX: 'hidden',
            marginTop: '1rem',
            borderRadius: '0.2rem',
            position: 'absolute',
            bottom: '10px',
            right: '4px',
            borderTop: "0.1rem  solid #DBDEE0",
            zIndex: 1,
            border: '1px solid #DEE2E6',
            borderRadius: '12px'
        }}>
            <Box sx={{
                backgroundColor: "white",
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: "center",
                position: "sticky",
                top: '0px',
                zIndex: '1',
                backgroundColor: '#F6F7FA',
                py: 2,
                minWidth:'300px'
            }}>
                <span style={{ color: '#6D6D6D', fontSize: '12px', marginLeft: '1.5rem' }}> <span style={{ fontWeight: 400, fontSize: '16px' , marginRight:'0.2rem'}}>Uploading</span> {fileUploadingStatus.length} files</span>
                <img src={chevronLeft}
                    alt='no'
                    height='13px'
                    style={{ transform: isHideActive ? ' rotate(90deg)' : 'rotate(270deg)', marginRight: '1.5rem', cursor: "pointer", transition: "0.5s ease-in-out" }}
                    onClick={() => {
                        if (fileUploadingStatus.length > 2) {
                            setIsHideActive(prev => !prev)
                        }
                    }}
                />
            </Box>
           {!isHideActive && <Box sx={{display:'flex' , flexDirection:"column", gap:"0.1rem",px:'1.2rem' , backgroundColor:"white" , width:'calc(100% - 2.4rem)'}} fullWidth>
                {fileUploadingStatus?.map((uploadedFile, index) => {
                    return (
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: "white",
                            width: '100%',
                            borderBottom: index !== (fileUploadingStatus.length - 1) && '2px solid #F6F7FA',
                            gap:4,
                            py:1.5,
                            
                        }} fullWidth >
                            <Box sx={{ display: 'flex', gap: '0.8rem', overflow: 'hidden' }}>
                                <img src={pdfImg} alt='imagenofound' />
                                <Typography id="modal-modal-description" sx={{
                                    textAlign: 'center',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    color: '#6D6D6D',
                                }}>
                                    {truncateFileName(uploadedFile?.file_name)}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '0.9rem' }}>
                                <CircularProgress size={24} color="success" thickness={5} />
                            </Box>
                        </Box>
                    )
                })}
            </Box>}
        </Box>
    )
}

export default FileUploadStatus