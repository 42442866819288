// AlertContext.js
import React, { createContext, useContext, useState } from "react";

// Create a context
const AlertContext = createContext();

export const useAlert = () => {
    return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
    const [alert, setAlert] = useState({ message: "", isVisible: false, buttonText: 'Done', title: '', highlightText: '' });

    const showAlert = (title, highlightText, message, buttonText) => {
        setAlert({ message, isVisible: true, title, highlightText, buttonText: buttonText ? buttonText : 'Done' });
    };

    const closeAlert = () => {
        setAlert({ message: "", isVisible: false });
    };

    return (
        <AlertContext.Provider value={{ alert, showAlert, closeAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
