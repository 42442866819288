import React from 'react';
import './tableSkeletonLoader.css'

function TableSkeletonLoader() {
    return (
        <table class="tg">
            <tr>
                {[0, 1, 2, 3, 4].map(i => {
                    return (<th class="tg-cly1">
                        <div class="line"></div>
                    </th>)
                })}
            </tr>
            {[0, 1, 2, 3, 4].map(tr => {
                return (
                    <tr>
                        {[0, 1, 2, 3, 4].map(i => {
                            return (<td class="tg-cly1">
                                <div class="line"></div>
                            </td>)
                        })}
                    </tr>
                )
            })}
        </table>
    )
}

export default TableSkeletonLoader