import React, { useEffect, useState } from 'react'
import { ReactComponent as SearchEnterImg } from '../../assests/searchEnter.svg';
import modalCross from '../../assests/modalCross.svg'
import Box from '@mui/material/Box';
import { Modal } from '@mui/material';
import { useRememberQuestionAsked } from '../../services/newThreadQuestionContext';
import AutoResizeTextarea from './AutoResizeTextarea';
import { useNavigate } from 'react-router-dom';
import STRINGS from '../../helpers/staticContentHelper';
function ConstructionGptsearchBar({ isModalOpen, setIsModalOpen }) {
    const navigate = useNavigate();
    const { lastAskedQuestion, setLastAskedQuestion } = useRememberQuestionAsked();
    const [insideText, setInsideText] = useState('');
    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            setIsModalOpen(false)
            setLastAskedQuestion(e.target.value)
            navigate('/construction-gpt-home')
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            aria-labelledby="modal-modal-title"
            onClick={(e) => e.stopPropagation()}
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: 'center',
                backdropFilter: "blur(3px)",
                border: 'none',
                outline: 'none',
            }}
            // overlayStyle={{ backgroundColor: 'red' }}
            slotProps={{ backdrop: { sx: { background: 'rgba(0,0 ,0 ,0.8 )' } } }}
        >
            <div className={`single-file-search jakarta-font`} style={{ maxWidth: '100%' }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'fixed', top: '2rem', right: '2rem' }}>
                    <img src={modalCross} alt='crossIcon'
                        style={{
                            backgroundColor: "white",
                            padding: '0.5rem',
                            borderRadius: '1rem',
                            cursor: "pointer",
                            width: '15px',
                            height: '15px'
                        }} onClick={() => setIsModalOpen(false)} />
                </Box>
                <div className='single-search-box'>
                    <p style={{ color: 'white' }}>{STRINGS.START_NEW_CHAT}</p>
                    <div className='search-box-input' style={{ backgroundColor: "white", border: '5px solid gray' , alignItems:"flex-end"}}>
                        <AutoResizeTextarea
                            _handleKeyDown={_handleKeyDown}
                            searchValue={insideText}
                            setSearchValue={setInsideText}
                        />
                        <SearchEnterImg className={`${!insideText && 'image'}`} style={{ cursor: insideText && 'pointer' }} onClick={() => {
                            if (insideText.length > 0) {
                                setLastAskedQuestion(insideText)
                            }
                        }} />
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ConstructionGptsearchBar;