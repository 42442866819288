import { useState, useEffect } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const Typewriter = ({ text, delay }) => {
    const [currentText, setCurrentText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        let timeout;

        if (currentIndex <= text?.length) {
            timeout = setTimeout(() => {
                if (text[currentIndex]) {
                    setCurrentText(prevText => prevText + text[currentIndex]);
                    setCurrentIndex(prevIndex => prevIndex + 1);
                }
            }, delay);
        }

        return () => clearTimeout(timeout);
    }, [currentIndex, delay, text]);

    return <Markdown remarkPlugins={[[remarkGfm]]} className='mark-up'>
        {currentText}
    </Markdown>;
};

export default Typewriter;