import React, { useState, useEffect, useRef } from 'react'
// import NotificationsImg from '../../../assests/notifications.svg';
import UserProfileImg from '../../../assests/userProfile.svg'
// import SettingsImg from '../../../assests/settings.svg'
import LogOutImg from '../../../assests/logout.svg';
import UseImg from '../../../assests/user.svg';
import MultiUserImg from '../../../assests/multiUser.svg';
import { Button } from '@mui/material';
import { buttonStyle, baseTypography } from '../../newProjects/components';
import STRINGS from '../../../helpers/staticContentHelper';
import ShowProfileDetails from '../../../components/showProfileDetails';
import BackImg from '../../../assests/back.svg';
import { useNavigate } from 'react-router-dom';
import { USER_LOG_OUT } from '../../../services';
import { usePostHog } from "posthog-js/react"
import { LStorage } from '../../../utils/localStorage.Helper';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { checkUserPermission } from '../../../utils/encryptionDecryption';

const USER_OPTIONS = [
    {
        title: 'Profile',
        icon: UserProfileImg
    },
    {
        title: 'Manage team',
        icon: MultiUserImg,
        requiredPermission: true
    },
    {
        title: 'Logout',
        icon: LogOutImg
    },
]

function ProjectDetailsHeader({ selectedFile, hederTitle, setIsPdfViewOpen, isPdfViewOpen, searchParams, setSearchParams, setIsInviteUserModalOpen }) {
    const [isUserDropDownActive, setIsUserDropDownActive] = useState(false);
    const [openProfileModal, setOpenProfileModal] = useState(false);
    const navigate = useNavigate()
    const userData = LStorage.getUserData();
    const posthog = usePostHog()
    const logOutUser = () => {

        const reqData = {
            refresh_token: userData.refresh_token
        }
        USER_LOG_OUT(reqData).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                posthog.reset()
                LStorage.removeUserData()
                navigate('/')
                if (parsedDecryptedData?.url) {
                    window.location.href = parsedDecryptedData.url
                }
            }
        })
    }

    const wrapperRef = useRef(null);

    useEffect(() => {
        if (isUserDropDownActive) {
            function handleClickOutside(event) {
                if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
                    setIsUserDropDownActive(false)
                }
            }
            document.addEventListener("click", handleClickOutside);
        }
    }, [isUserDropDownActive]);

    useEffect(() => {
        setIsPdfViewOpen(true)
    }, []) //eslint-disable-line

    return (
        <div className='navbar-header'>
            <ShowProfileDetails openFolderModal={openProfileModal} setOpenFolderModal={setOpenProfileModal} />
            <div className='back-to-files'>
                {hederTitle !== 'Files and folders' &&
                    <img src={BackImg} alt='not-found' width='20px' onClick={() => {
                        searchParams.set("activeTab", STRINGS.FILES_AND_FOLDERS);
                        searchParams.delete("threadId");
                        setSearchParams(searchParams)
                    }} />
                }
                <p className='records-hover-text'>
                    {selectedFile?.file_name?.length > 50 && hederTitle === 'Home' &&
                        <span className='navbar-tooltip'>{selectedFile?.file_name}</span>
                    }
                    {hederTitle === 'Home' ? selectedFile.file_name.substring(0, 50) : hederTitle}
                    {hederTitle === 'Home' && selectedFile?.file_name?.length > 50 && '...'}
                </p>
            </div>
            <div className='logo-with-user-name'>
                {hederTitle === 'Home' && !isPdfViewOpen &&
                    <Button variant="outlined" size="large"
                        sx={{ ...buttonStyle, backgroundColor: '#1B2E31', color: "white", ...baseTypography }}
                        onClick={() => setIsPdfViewOpen(prev => !prev)}
                    >View PDF</Button>
                }
                {/* <div className='nav-bar-icons' style={{ backgroundColor: '#F6F7FA' }}>
                    <img src={NotificationsImg} alt='notifications' />
                    <div className='dot-notification'>15</div>
                </div> */}
                {/* {!isPdfViewOpen && */}
                <div className='nav-bar-icons'
                    style={{ backgroundColor: '#F6F7FA' }}
                    onClick={() => setIsUserDropDownActive(prev => !prev)} ref={wrapperRef}>
                    <img src={UseImg} alt='userImg' />
                    {USER_OPTIONS.length > 0 && isUserDropDownActive &&
                        <div className='drop-down-options-list'>
                            {USER_OPTIONS.map((option, index) => {
                                 if (option.requiredPermission && !checkUserPermission("INVITE" , userData)) {
                                    return <></>
                                }
                                return (
                                    <div className='drop-down-option' key={option}
                                        onClick={() => {
                                            if (option.title === 'Manage team') {
                                                setIsInviteUserModalOpen(true)
                                            }
                                            if (option.title === 'Profile') {
                                                setOpenProfileModal(true)
                                            }
                                            if (option.title === 'Logout') {
                                                logOutUser()
                                            }
                                        }}>
                                        {option.icon &&
                                            <img src={option.icon} alt='not-found' style={{ width: '20px' }} />
                                        }
                                        {option.title}
                                    </div>
                                )
                            })}
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProjectDetailsHeader