import React from 'react';
import integrationImg from '../../../assests/sonarWebImages/integration.svg'
import STRINGS from '../../../helpers/staticContentHelper';

function IntegrationSection() {
    return (
        <div className='web-integration-section'>
            <div className='integration-information'>
                <div className='integration-details'>
                    <p className='web-section-sub-title'>{STRINGS.INTEGRATION}</p>
                    <h1 className='web-section-title-security' style={{ marginTop: "0.5rem" }}>{'Tie it all together with your favorite apps'}</h1>
                    <span className='security-single-card-information-description' style={{ marginTop: "2rem", fontSize: "23px" }}>
                        {STRINGS.GATHER_QUICKER_ANSWERS}
                    </span>
                </div>
            </div>
            <div className='integration-information'>
                <img src={integrationImg} alt='no' style={{ maxWidth: '100%', minWidth: '250px' }} />
            </div>
        </div>
    )
}

export default IntegrationSection