import * as React from 'react';
import Box from '@mui/material/Box';
import { usePostHog } from "posthog-js/react"
import STRINGS from '../../helpers/staticContentHelper';
import HeaderComponent from '../newWebPage/webComponents/HeaderComponent';
import FooterSection from '../newWebPage/webComponents/FooterSection';
import { Typography } from '@mui/material';
import { REFUND_AND_CANCELLATION_POLICY } from '../../components/refundAndCancellationPolicy';

function RefundAndCancellationPolicy() {
    const posthog = usePostHog()

    React.useEffect(() => {
        posthog.capture('refund-cancellation_page_viewed')
        posthog.startSessionRecording()
        window.scrollTo(0, 0)
    }, [])


    return (
        <div style={{ position: 'relative' }} className='satoshi-font'>
            <HeaderComponent posthog={posthog} showSignInButton={false} />
            <Box sx={{
                backgroundColor: '#1B2E31',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingY: '6rem',
                whiteSpace: 'nowrap'
            }}>
                <Typography variant='h1' sx={{
                    color: 'white',
                    fontSize: {
                        lg: '3rem',
                        md: '2.2rem',
                        xs: '1.5rem',
                    },
                    fontWeight: '600',
                    border: 'none',
                    fontFamily: "Satoshi"
                }}>{STRINGS.REFUND_AND_CANCELLATION_POLICY}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <Box sx={{
                    width: {
                        lg: '70%',
                        md: '80%',
                        xs: '90%'
                    },
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                }} dangerouslySetInnerHTML={{ __html: REFUND_AND_CANCELLATION_POLICY }}>
                </Box>
            </Box>
            <FooterSection posthog={posthog} />
        </div>
    )
}

export default RefundAndCancellationPolicy