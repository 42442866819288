import { encryptObject, decryptObject } from "./encryptionDecryption";

class LStorage {
    static setUserData = (userData = {}) => {
        if (userData) {
            const encryptedUserData = encryptObject(userData)
            localStorage.setItem("userData", encryptedUserData);
        }
        return userData;
    };

    static removeUserData = () => {
        localStorage.removeItem("userData");
        return {};
    };

    static getUserData = () => {
        let userData = localStorage.getItem("userData") || null;
        if (userData) {
            try {
                userData = decryptObject(userData)
                if (!userData?.access_token) {
                    this.removeUserData();
                    userData = null;
                }
            } catch (error) {
                this.removeUserData();
                userData = null;
            }
        }
        return userData;
    };
}

export { LStorage };