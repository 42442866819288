import React, { useEffect, useState } from 'react'
import { ReactComponent as SearchEnterImg } from '../../../../assests/searchEnter.svg';
import { useSearchParams } from 'react-router-dom';
import modalCross from '../../../../assests/modalCross.svg'
import Box from '@mui/material/Box';
import STRINGS from '../../../../helpers/staticContentHelper';
import FocusMode from '../focusMode';

function ProjectSearchModal({ searchValue, setSearchValue, projectName, setIsProjectSearchActive, setDocumentType, actAnimation, setActAnimation, isFocusModeOn, setIsFocusModeOn }) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [insideText, setInsideText] = useState('');

    const triggerEnter = (value) => {
        setSearchValue(value)
        searchParams.delete('threadId')
        searchParams.set("activeTab", 'MultiPDF');
        setSearchParams(searchParams);
        setIsProjectSearchActive(false);
    }

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            triggerEnter(e.target.value);
        }
    }

    useEffect(() => {
        document.getElementById("search-input").focus();
    }, [])

    return (
        <div className={`single-file-search jakarta-font`} style={{ maxWidth: '100%' }}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', position: 'fixed', top: '2rem', right: '2rem' }}>
                <img src={modalCross} alt='crossIcon'
                    style={{
                        backgroundColor: "white",
                        padding: '0.5rem',
                        borderRadius: '1rem',
                        cursor: "pointer",
                        width: '15px',
                        height: '15px'
                    }} onClick={() => setIsProjectSearchActive(false)} />
            </Box>
            <div className='single-search-box'>
                <p style={{ color: 'white' }}>{STRINGS.SEARCH_WITHIN_PROJECT} {projectName}</p>
                <div className='search-box-input' style={{ backgroundColor: "white", border: '5px solid gray' }}>
                    <FocusMode setIsFocusModeOn={setIsFocusModeOn} setActAnimation={setActAnimation} isFocusModeOn={isFocusModeOn} actAnimation={actAnimation} />
                    <input
                        id='search-input'
                        type='text'
                        placeholder='Search'
                        onChange={(e) => {
                            setInsideText(e.target.value)
                        }}
                        onKeyDown={_handleKeyDown}
                        autofocus
                        autocomplete="off"
                    />
                    <SearchEnterImg className={`${!insideText && 'image'}`} style={{ cursor: insideText && 'pointer' }} onClick={() => {
                        triggerEnter(insideText)
                    }} />
                </div>
            </div>
        </div>
    )
}

export default ProjectSearchModal