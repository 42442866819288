import React, { useEffect } from 'react'
import STRINGS from '../../../helpers/staticContentHelper';
import { LStorage } from '../../../utils/localStorage.Helper';
import { useNavigate } from 'react-router-dom';
import { KINDE_SIGN_UP } from '../../../services';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { ReactComponent as Customize } from '../../../assests/customize.svg';
import { ReactComponent as GptImg } from './gpt.svg';
import { ReactComponent as UploadFileImg } from '../../../assests/uploadFile.svg';
import { ReactComponent as SonarLogoImg } from '../../../assests/SonarNewLogo.svg';
import { ReactComponent as CopyImg } from './copy.svg';
import { ReactComponent as LikeImg } from './like.svg';
import { ReactComponent as DisLikeImg } from './disLike.svg';
import { ReactComponent as RewriteImg } from './rewrite.svg';
import { ReactComponent as ShareImg } from './share.svg';

function ConstructionGptHeroSection({ posthog }) {
    const userData = LStorage.getUserData();
    const navigate = useNavigate();

    const postHogRecording = () => {
        if (userData.email) {
            posthog.identify(userData.email)
        }
    }

    const signUp = () => {
        KINDE_SIGN_UP().then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData?.url) {
                    window.location.href = parsedDecryptedData.url
                }
            }
        })
    }

    return (
        <div className='web-hero-section' >
            <div className='web-hero-section-title'>
                <h1 className='web-gradient-title' style={{ maxWidth: "1150px" }}>
                    {STRINGS.BUILD_YOUR_CUSTOM}  <span className='web-gradient-title-span'>{STRINGS.CONSTRUCTION_GPT}</span> {STRINGS.IN_SECONDS_FOR_FREE}
                </h1>
            </div>

            <div style={{ textAlign: 'center', marginTop: "2rem" }}>
                <button className='button-primary-web'
                    onClick={() => {
                        userData ? postHogRecording() :
                            posthog.capture('signup_clicked_constructionGPT_heroSection');
                        (userData && userData?.access_token) ? navigate('/dashboard') : signUp();
                    }}>
                    {userData ? "Go to dashboard" : 'Sign up for free'}
                </button>
            </div>

            <div className='construction-gpt-hero-section-card-section' style={{ marginTop: "3rem" }}>

                <div className='construction-gpt-configure-card'>
                    <div className='construction-gpt-configure-card-header'>
                        <Customize style={{ height: '20px', width: "20px" }} /> {STRINGS.CONFIGURE}
                    </div>

                    <div className='construction-gpt-heading-with-description'>
                        <p>{STRINGS.NAME_YOUR_GPT}</p>
                        <div className='construction-gpt-description-with-border'>
                            {STRINGS.ESTIMATOR_GPT}
                        </div>
                    </div>


                    <div className='construction-gpt-heading-with-description'>
                        <p>{STRINGS.INSTRUCTIONS}</p>
                        <div className='construction-gpt-description-with-border'>
                            <ul>
                                <li>
                                    {STRINGS.YOU_HAVE_EXPERIENCE_IN_PRE_CONSTRUCTION}
                                </li>

                                <li>
                                    {STRINGS.CORE_KNOWLEDGE}
                                </li>
                            </ul>
                        </div>
                    </div>


                    <div className='construction-gpt-heading-with-description'>
                        <p>{STRINGS.UPLOAD_FILES_TO_PROVIDE_INSTRUCTIONS_TO_GPT}</p>
                        <div className='construction-gpt-card-upload-files'>
                            <UploadFileImg style={{ width: '34px' }} />
                            <p>{STRINGS.DRAG_AND_DROP}  <span>{STRINGS.BROWSE_FILES}</span> </p>
                        </div>
                    </div>

                </div>


                <div className='construction-gpt-configure-card'>
                    <div className='construction-gpt-configure-card-header'>
                        <GptImg /> {STRINGS.GPT_IN_ACTION}
                    </div>

                    <p className='gpt-construction-card-heading'>
                        {STRINGS.HELP_ME_CALCULATE_CREW_COMPOSITION_FOR_MASS_GRADE}
                    </p>


                    <div className='construction-gpt-configure-card-header' id='construction-gpt-configure-card-header-main-header' style={{ justifyContent: "flex-start", gap: '12px', marginTop: '30px' }}>
                        <SonarLogoImg style={{ height: '24px', width: '24px' }} /> {STRINGS.ESTIMATOR_GPT}
                    </div>

                    <div className='construction-gpt-configure-card-about-gpt'>
                        <p>
                            {STRINGS.TO_CALCULATE_CREW_COMPOSITION_FOR_A_MASS_GRADING_OPERATION}
                        </p>

                        <ul>
                            <li>
                                {STRINGS.VOLUME_OF_MATERIAL_TO_MOVE}
                            </li>
                            <li>
                                {STRINGS.SITE_CONDITIONS_SOIL_TYPE_MOISTURE_CONTENT_ROCK_CONTENT}
                            </li>
                            <li>
                                {STRINGS.EQUIPMENT_PRODUCTIVITY}
                                <li style={{ marginLeft: '15px', marginTop: '0.4rem' }}>
                                    {STRINGS.PRODUCTIVITY_RATES_OF_DOZERS_SCRAPERS_EXCAVATORS}
                                </li>
                            </li>
                        </ul>

                        <div className='construction-gpt-configure-card-about-gpt-options'>
                            <div className='icons-with-space'>
                                <CopyImg />
                                <LikeImg />
                                <DisLikeImg />
                            </div>

                            <div className='icons-with-space'>
                                <div className='icons-with-space' style={{ gap: '15px' }}>
                                    <div className='icons-with-space-and-text'>
                                        <RewriteImg /> {STRINGS.REWRITE}
                                    </div>
                                    <div className='icons-with-space-and-text'>
                                        <ShareImg /> {STRINGS.SHARE}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </div>
    )
}

export default ConstructionGptHeroSection;