import React, { useState } from 'react'
import { ReactComponent as SearchEnterImg } from '../../assests/searchEnter.svg';
import AutoResizeTextarea from './AutoResizeTextarea';
import STRINGS from '../../helpers/staticContentHelper'

function SearchGptInput({ fetchingResult, setNewThreadQuestion, availableConfiguredGPTs }) {
    const [searchValue, setSearchValue] = useState('');

    const _handleKeyDown = (e) => {
        if (e.key === 'Enter' && availableConfiguredGPTs.length > 0) {
            setNewThreadQuestion(searchValue)
        }
    }

    return (
        <div className={`single-file-search ${fetchingResult && 'hide-animate'}`}>
            <div className='single-search-box'>
                <p style={{ fontSize: '1.8rem' }}>{STRINGS.WHAT_CAN_I_HELP_YOU_WITH_TODAY}</p>
                <div className='search-box-input' style={{ alignItems: "flex-end" }}>
                        <AutoResizeTextarea
                            _handleKeyDown={_handleKeyDown}
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                        />
                    <SearchEnterImg className={`${!searchValue && 'image'}`} onClick={() => {
                        if (searchValue.length > 0 && availableConfiguredGPTs.length > 0) {
                            setNewThreadQuestion(searchValue)
                        }
                    }} />
                </div>
            </div>
        </div>
    )
}

export default SearchGptInput;