import React, { useEffect, useState } from 'react';
import './projects.styles.css';
import ProjectsHeader from '../../components/projectsHeader';
import ProjectsList from './projectsList';
import CreateProject from './createProject';
import { GET_PROJECTS_LIST } from '../../services/index';
import { LStorage } from '../../utils/localStorage.Helper';
import { decryptResponse } from '../../utils/encryptionDecryption';
import moment from 'moment';
import HistoryModal from '../../components/historyModal';

function NewProjects() {
    const created_by = LStorage.getUserData()?.created_by
    const [isProjectHistoryOpen, setIsProjectHistoryOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [projectsListLoading, setProjectsListLoading] = useState(true);
    const [tableData, setTableData] = useState([]);

    const fetchProjectsList = () => {
        GET_PROJECTS_LIST({ created_by }).then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData) {
                    // const decryptedList = decryptResponse(res.projects)
                    parsedDecryptedData.sort((left, right) => {
                        return moment.utc(right.created_at).diff(moment(left.created_at))
                    })
                    setTableData(parsedDecryptedData)
                    setProjectsListLoading(false);
                }
            }

        })
    }

    useEffect(() => {
        fetchProjectsList();
    }, []) //eslint-disable-line

    return (
        <div className='projects-main'>
            <CreateProject
                open={open}
                setOpen={setOpen}
                fetchProjectsList={fetchProjectsList}
            />
            <ProjectsHeader setOpen={setOpen} tableData={tableData} />
            <div className='projects-content'>
                <div className='table-content'>
                    <ProjectsList
                        setOpen={setOpen}
                        tableData={tableData}
                        projectsListLoading={projectsListLoading}
                        setIsProjectHistoryOpen={setIsProjectHistoryOpen}
                        isProjectHistoryOpen={isProjectHistoryOpen}

                    />
                </div>
            </div>
            {/* Modal popup for history*/}

        </div>
    )
}

export default NewProjects;