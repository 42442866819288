import React, { useState, useEffect } from 'react';
import constructionImg from '../../../assests/sonarWebImages/constructionprofessionals.svg';
import securityImg from '../../../assests/sonarWebImages/security.svg';
import playButtonImg from '../../../assests/sonarWebImages/playbutton.svg';
import STRINGS from '../../../helpers/staticContentHelper';
import heroSectionImage1 from '../../../assests/sonarWebImages/heroSectionImage1.png'
import heroSectionImage2 from '../../../assests/sonarWebImages/heroSectionImage2.png'
import demoVideoGlf from '../../../assests/videos/demo.gif';
import VideoModal from '../../../components/playVideoModal/VideoModal';
import { LStorage } from '../../../utils/localStorage.Helper';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlayButtonImg } from '../../../assests/sonarWebImages/playbutton.svg';
import { KINDE_SIGN_UP } from '../../../services';
import { decryptResponse } from '../../../utils/encryptionDecryption';

const HERO_SECTION_TESTIMONIAL_CARD_DATA = [
    {
        title: STRINGS.WATCH_SONAR_IN_ACTION,
        description: '',
        image: demoVideoGlf,
        titleImg: playButtonImg,
        buttonName: STRINGS.WATCH_SONAR_IN_ACTION_BUTTON_TEXT
    },
    {
        title: STRINGS.FOR_CONSTRUCTION_PROFESSIONALS,
        description: STRINGS.FOR_CONSTRUCTION_PROFESSIONALS_DESCRIPTION,
        image: heroSectionImage1,
        titleImg: constructionImg,
        buttonName: STRINGS.FOR_CONSTRUCTION_PROFESSIONALS_BUTTON_TEXT
    },
    {
        title: STRINGS.SECURITY_AND_ENCRYPTION,
        description: STRINGS.SECURITY_AND_ENCRYPTION_DESCRIPTION,
        image: heroSectionImage2,
        titleImg: securityImg,
        buttonName: STRINGS.SECURITY_AND_ENCRYPTION_BUTTON_TEXT
    },
]

function HeroSection({ posthog }) {
    const userData = LStorage.getUserData();
    const navigate = useNavigate();
    const [activeCard, setActiveCard] = useState(0);
    const [lastActiveCard, setLastActiveCard] = useState(0);
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)

    const postHogRecording = () => {
        if (userData.email) {
            posthog.identify(userData.email)
        }
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setActiveCard(prev => {
                setLastActiveCard(p => prev)
                return prev === 2 ? 0 : prev + 1
            })
        }, 15000);

        return () => clearInterval(intervalId);
    }, []);

    const signUp = () => {
        KINDE_SIGN_UP().then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData?.url) {
                    window.location.href = parsedDecryptedData.url
                }
            }
        })
    }

    return (
        <div className='web-hero-section'>
            <VideoModal isModalOpen={isVideoModalOpen} setIsModalOpen={setIsVideoModalOpen} videoUrl={demoVideoGlf} />
            <div className='web-hero-section-title'>
                <h1 className='web-gradient-title'>
                    Search made <span className='web-gradient-title-span'>simpler and faster</span> for construction
                </h1>
                <p className='web-gradient-sub-title'>
                    {STRINGS.AI_POWERED_DOCUMENT}
                </p>
            </div>

            <div style={{ textAlign: 'center', marginTop: "2rem" }}>
                <button className='button-primary-web'
                    onClick={() => {
                        userData ? postHogRecording() :
                            posthog.capture('signup_clicked_documentSearch_heroSection');
                        (userData && userData?.access_token) ? navigate('/dashboard') : signUp();
                    }}>
                    {userData ? "Go to dashboard" : 'Sign up for free'}
                </button>
            </div>

            <div className='web-animated-testimonial-cards'>
                {HERO_SECTION_TESTIMONIAL_CARD_DATA.map((testimonialData, index) => {
                    return (
                        <div
                            className={`web-testimonial-card 
                         ${activeCard === index && 'web-testimonial-card-active'}
                         ${index === lastActiveCard && 'web-default-active'}`}
                            onClick={() => {
                                posthog.capture(testimonialData.buttonName)
                                setLastActiveCard(prev => activeCard)
                                setActiveCard(prev => index)
                                if (index === 0) {
                                    setIsVideoModalOpen(true)
                                }
                            }}
                        >
                            <img src={testimonialData.image} alt='testimonialImage' className='web-testimonial-card-img' />
                            <div className='web-transparent-background' />

                            <div className='web-testimonial-card-title'>
                                <div className='web-testimonial-card-title-image'>
                                    <img src={testimonialData.titleImg} className='image-static' alt='no' />
                                </div>
                                <div className='web-testimonial-card-title-text'>
                                    {(testimonialData.title.length > 25 && activeCard !== index) ? testimonialData.title.substring(0, 20) + '...' : testimonialData.title}
                                </div>
                            </div>

                            <div className='web-testimonial-card-description'>
                                {testimonialData.description} {index === 1 &&
                                    <span> anytime, anywhere.</span>}
                            </div>
                            {index === 0 &&
                                <div className='web-testimonial-card-description web-testimonial-card-description-image'>
                                    <PlayButtonImg className='play-button' />
                                </div>
                            }
                        </div>
                    )
                })}
            </div>

            {/* the animated card status indicators */}

            <div className='web-testimonial-status-indicators'>
                {[0, 1, 2].map((number, index) => {
                    return (
                        <div onClick={() => {
                            setLastActiveCard(prev => activeCard)
                            setActiveCard(prev => index)
                        }}
                            className={`web-testimonial-status-indicator ${index !== activeCard && 'web-testimonial-status-indicator-inactive'}`}>
                            {index === activeCard &&
                                <div className='web-testimonial-status-indicator-active' />
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default HeroSection