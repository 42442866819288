import React, { useRef, useEffect } from 'react';

const AutoResizeTextarea = ({ searchValue, setSearchValue, _handleKeyDown }) => {
  const textareaRef = useRef(null);

  const handleInput = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height to auto to recalculate
      textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height to fit content
    }
  };

  useEffect(() => {
    // Auto-focus the textarea when the component mounts
    if (textareaRef.current) {
      textareaRef.current.focus();
      if (textareaRef.current) {
        const textarea = textareaRef.current;
        // Focus the textarea
        textarea.focus();
        // Move cursor to the end of the text
        textarea.selectionStart = textarea.value.length;
        textarea.selectionEnd = textarea.value.length;
      }
    }
  }, []);

  return (
    <textarea
      value={searchValue}
      ref={textareaRef}
      onChange={(e) => { setSearchValue(e.target.value) }}
      placeholder="Search"
      onInput={handleInput}
      onKeyDown={_handleKeyDown}
      style={{
        width: 'calc(100% - 50px)',
        minHeight: '20px',
        resize: 'none', // Prevent manual resizing
        overflow: 'hidden', // Prevent scrollbars
        boxSizing: 'border-box', // Ensure padding is included in height calculation
        padding: '0px',
        fontSize: '1rem',
        border: "none",
        outline: 'none',
        paddingLeft: "0.3rem",
        maxHeight: '300px',
        marginBottom: searchValue.length < 64 && "-20px",
        backgroundColor:"transparent",
        lineHeight:'1.5rem'
      }}
    />
  );
};

export default AutoResizeTextarea;
