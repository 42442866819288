import React, { useEffect, useState } from 'react'
import LogoWithName from '../../assests/sonar.png';
import SonarLogo from '../../assests/SonarNewLogo.svg';
import NavBarToggler from '../../assests/navbarToggler.svg';
import FileFolderActiveImg from '../../assests/fileFolderActive.svg';
import FileFolderInactiveImg from '../../assests/fileFolderInactive.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import STRINGS from '../../helpers/staticContentHelper';
import AddImg from '../../assests/add.svg';
import { ReactComponent as HomeImg } from './assests/home.svg';
import { ReactComponent as ConstomizeImg } from './assests/customize.svg';
import BackImg from '../../assests/back.svg';
import { LOAD_USER_ASSISTANTS } from '../../services/constructionGpt.services';
import { useAvailableConfiguredGPTs } from '../../services/availableConfiguredGpts';
import { decryptResponse } from '../../utils/encryptionDecryption';
import { LOAD_GPT_ASSISTANTS_THREADS } from '../../services/constructionGpt.services';
import { useFunctionContext } from '../../services/triggerLocationHistoryContext'
import { useSearchParams } from 'react-router-dom';

function ConstructionGptSideBar({ isTogglerOn, setIsTogglerOn, setIsModalOpen }) {
    const { value } = useFunctionContext();
    const { availableConfiguredGPTs, setAvailableConfiguredGPTs } = useAvailableConfiguredGPTs();
    const [searchParams, setSearchParams] = useSearchParams();
    let threadId = searchParams.get('threadId')
    const [threads, setThreads] = useState([]);
    const location = useLocation();
    const path = location.pathname;
    const navigate = useNavigate();

    const getGptThreadHistory = async (assistantId) => {
        const res = await LOAD_GPT_ASSISTANTS_THREADS(assistantId);
        if (res) {
            const decryptedResponse = JSON.parse(decryptResponse(res));
            console.log(decryptedResponse, 'decryptedResponse__ sidebar')
            setThreads(decryptedResponse)
        }
    }

    const getUserAssistants = async () => {
        const res = await LOAD_USER_ASSISTANTS();
        if (res) {
            try {
                const decryptedResponse = JSON.parse(decryptResponse(res))
                setAvailableConfiguredGPTs(decryptedResponse)
                if (decryptResponse.length > 0) {
                    getGptThreadHistory(decryptedResponse[0].assistant_id)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    useEffect(() => {
        getUserAssistants();
    }, [])

    useEffect(() => {
        if (value && availableConfiguredGPTs[0]?.assistant_id) {
            getGptThreadHistory(availableConfiguredGPTs[0].assistant_id)
        }
    }, [value])

    return (
        <div className={`project-details-navbar-main  ${!isTogglerOn && 'toggler-off'}`}>
            <div className='project-details-navbar'>
                <div className='navbar-header'>
                    {isTogglerOn ?
                        <>
                            <img src={LogoWithName} alt='not-found' height={'32px'} />
                            <img src={NavBarToggler}
                                alt='not-found'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                                className='toggler-icon'
                            />
                        </> :
                        <>
                            <img src={SonarLogo} alt='not-found' className='oh-hover-hide' />
                            <div className='navbar-option on-hover-show'
                                onClick={() => setIsTogglerOn(prev => !prev)}
                            >
                                <img src={NavBarToggler}
                                    alt='not-found'
                                />
                            </div>
                        </>
                    }
                </div>
                <div className='divider-hor'></div>
                <div className='new-thread' onClick={() => setIsModalOpen(true)}>
                    <img src={AddImg} alt='new-thread' className='thread-icon rotate-icon' height='18px' />
                    <span>{STRINGS.START_NEW_CHAT}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.START_NEW_CHAT}</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/construction-gpt-home' && 'active-tab'}`}
                    onClick={() => navigate('/construction-gpt-home')}>
                    <HomeImg className={`${path !== '/construction-gpt-home' && 'add-store-remove-fill'}`} />
                    <span>{STRINGS.HOME}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.HOME}</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/configure-gpt' && 'active-tab'}`}
                    onClick={() => navigate('/configure-gpt')}>
                    <ConstomizeImg />
                    <span>{STRINGS.CONFIGURE}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.CONFIGURE}</p>
                    }
                </div>
                <div className={`navbar-option ${path === '/construction-gpt-history' && 'active-tab'}`}
                    onClick={() => navigate('/construction-gpt-history')}>
                    <img src={`${path === '/construction-gpt-history' ? FileFolderActiveImg : FileFolderInactiveImg}`} alt='not-found' width='20px' height='20px' /> <span>{STRINGS.HISTORY}</span>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.HISTORY}</p>
                    }
                </div>
                {isTogglerOn &&
                    <div style={{ marginLeft: '1.2rem', display: 'flex', gap: '0.1rem', flexDirection: "column", borderLeft: "1.5px solid #c9c6c6", paddingLeft: '0.2rem' }}>
                        {threads.length > 0 &&
                            threads.slice(0, 5).map(threadData => {
                                return (
                                    <div key={threadData.id} className='navbar-option'
                                        style={{ padding: '4px 5px', fontSize: '0.75rem', overflow: "hidden", color: 'gray', backgroundColor: threadId === threadData.thread_id && "#ECF0F3" }}
                                        onClick={() => {
                                            navigate(`/construction-gpt-home?threadId=${threadData.thread_id}`)
                                        }}
                                    >
                                        {threadData.thread_name.substring(0, 32)}
                                        {threadData.thread_name.length > 32 && '...'}
                                    </div>
                                )
                            })}
                    </div>
                }
            </div>
            {/* navbar button  */}
            <div className='project-details-navbar'>
                <div className='project-info' onClick={() => navigate('/dashboard')}>
                    <img src={BackImg} alt='not-found' className='thread-icon on-hover-move' width='20px' />
                    <div className='navbar-option-no-hover'>
                        <img src={BackImg} alt='not-found' width='20px' height='20px' className='on-hover-move' />
                        <span className='back-to-project'>
                            {STRINGS.BACK_TO_DASHBOARD}
                        </span>
                    </div>
                    {!isTogglerOn &&
                        <p className='navbar-tooltip'>{STRINGS.BACK_TO_DASHBOARD}</p>
                    }
                </div>
            </div>
        </div>
    )
}

export default ConstructionGptSideBar;