import React from 'react';
import heroBannerimg from '../components/heroBanner.png';
import { decryptResponse } from '../../../utils/encryptionDecryption';
import { LStorage } from '../../../utils/localStorage.Helper';
import { usePostHog } from "posthog-js/react";
import { useNavigate } from 'react-router-dom';
import { KINDE_SIGN_UP } from '../../../services';
import STRINGS from '../../../helpers/staticContentHelper';

function MaterialSearchHeroBanner() {
    const posthog = usePostHog()
    const userData = LStorage.getUserData();
    const navigate = useNavigate();
    const signUp = () => {
        KINDE_SIGN_UP().then(res => {
            if (res) {
                const decryptedData = decryptResponse(res)
                const parsedDecryptedData = JSON.parse(decryptedData)
                if (parsedDecryptedData?.url) {
                    window.location.href = parsedDecryptedData.url
                }
            }
        })
    }
    const postHogRecording = () => {
        if (userData.email) {
            posthog.identify(userData.email)
        }
    }

    return (
        <div className='web-integration-section material-search-hero-section'>
            <img className='ab-image find-answer-section-img' src={heroBannerimg} width='100%' height='100%' style={{ top: "-2px", filter: ' brightness(0.4' }} />
            <div className='material-search-hero-banner'>
                <div className='material-serach-hero-banner-text'>
                    {/* <span>Search and connect with vendors in seconds</span> */}
                    <h3 className='footer-top-section-title' style={{ textAlign: 'center', color: 'white' }}>
                        {STRINGS.FIND_CONSTRUCTION_MATERIALS_AND_SUPPLIERS}
                    </h3>
                    <button className='button-secondary-web'
                        style={{ marginTop: '2rem' }}
                        onClick={() => {
                            userData ? postHogRecording()
                                :
                                posthog.capture('signup_clicked_materialSearch_heroSection');
                            (userData && userData?.access_token) ? navigate('/dashboard') : signUp();
                        }}>
                        {userData ? "Go to dashboard" : 'Sign up for free'}
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MaterialSearchHeroBanner