import React from 'react';
import { Checkbox } from '@mui/material';
import { MATERIALS_LIST } from '../../../helpers/staticContentHelper';

const LANDFILLS_LIST = ['Asphalt', 'Concrete', 'Sand', 'Dirt', 'Construction Debris']

function CheckboxDropdown({ setApiRequestPayload, apiRequestPayload, isFromLandFills = false, materialKey }) {
    let DATA = isFromLandFills ? LANDFILLS_LIST : MATERIALS_LIST
    return (
        <div className="grid-container">
            {DATA.map(category => {
                return (
                    <div className="grid-item" onClick={(e) => {
                        e.stopPropagation()
                        if (apiRequestPayload[materialKey ? materialKey : 'materials'].includes(category)) {
                            const filteredList = apiRequestPayload[materialKey ? materialKey : 'materials'].filter(i => i !== category);
                            setApiRequestPayload(prev => {
                                return { ...prev, [materialKey ? materialKey : 'materials']: filteredList }
                            })
                        } else {
                            setApiRequestPayload(prev => {
                                return { ...prev, [materialKey ? materialKey : 'materials']: [...prev[materialKey ? materialKey : 'materials'], category] }
                            })
                        }
                    }}>
                        <Checkbox
                            sx={{
                                color: '#1F5D5E',
                                '&.Mui-checked': {
                                    color: '#1F5D5E',
                                },
                                padding: '0px',
                            }}
                            id="myCheckbox"
                            checked={apiRequestPayload[materialKey ? materialKey : 'materials'].includes(category)}
                            onChange={() => { }}
                        />
                        <span className="filter-sub-text" for="myCheckbox" style={{ marginLeft: "0.4rem" }}>{category}</span>
                    </div>
                )
            })}
        </div>
    )
}

export default CheckboxDropdown