import React from 'react'
import HeaderComponent from '../newWebPage/webComponents/HeaderComponent';
import FooterSection from '../newWebPage/webComponents/FooterSection';
import { usePostHog } from "posthog-js/react";
import ConstructionGptHeroSection from './components/constructionGptHeroSection';
import SetUpYourGpt from './components/setUpYourGpt';
import YourDataSafe from './components/yourDataSafe';
import UserFormMain from '../formPage/userFormMain';
function ConstructionGPT() {
    const posthog = usePostHog()
    return (
        <div className='web-main-container'>
            <HeaderComponent posthog={posthog} />
            <ConstructionGptHeroSection posthog={posthog}  />
            <SetUpYourGpt/>
            <YourDataSafe />
            <UserFormMain />
            <FooterSection posthog={posthog} />
        </div>
    )
}

export default ConstructionGPT;