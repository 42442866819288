import React, { useState } from 'react';
import MaterialSideBar from './materialSidebar';

function MaterialLayout({ children }) {
    const [isTogglerOn, setIsTogglerOn] = useState(true);
    return (
        <div className='projects-details-main'>
            {/* left nav bar  */}
            <MaterialSideBar isTogglerOn={isTogglerOn} setIsTogglerOn={setIsTogglerOn} />
            {/* material search chils compoenet */}
            <div className='project-files-details' style={{ padding: "0px", maxWidth: 'calc(100% - 240px)', minWidth: isTogglerOn ? 'calc(100% - 240px)' : 'calc(100% - 80px)' }}>
                {children}
            </div>
        </div>
    )
}

export default MaterialLayout;