import React, { useState } from 'react';
import MaterialSearchHistory from './materialSearchHistory';
import LandFillsSearchHistory from '../landFills/landfillsSearchHistory';
import { ReactComponent as MaterialImg } from './assets/materialSearch.svg';
import { ReactComponent as MaterialSearchActiveImg } from './assets/materislSearchActive.svg';
import { ReactComponent as StartImg } from './assets/start.svg';
import { ReactComponent as LandfillFilledImg } from '../materialSearch/assets/landfillsFilled.svg';
import STRINGS from '../../helpers/staticContentHelper';

function HistoryForMaterialSearch() {
    const [selectedOption, setSelectedOption] = useState('Material search');
    return (
        <>
            <div className='tab-bar-with-underline' style={{ justifyContent: 'space-between', gap: '0.5rem', width: "calc(100% - 3.1rem)", marginLeft: '1rem', padding: '0.5rem' }}>
                <div className='personal-and-company-option-button'>
                    <div className={`${selectedOption === 'Material search' && 'new-option-for-landfil-material-active'} new-option-for-landfil-material`}
                        onClick={() => setSelectedOption('Material search')}>
                        {selectedOption === 'Material search' ?
                            <MaterialSearchActiveImg className='green-filled' /> :
                            <MaterialImg />
                        }
                        {STRINGS.MATERIALS}
                    </div>
                    <div className={`${selectedOption === 'LandFills' && 'new-option-for-landfil-material-active'} new-option-for-landfil-material`}
                        onClick={() => setSelectedOption('LandFills')}>
                        {selectedOption === 'LandFills' ?
                            <LandfillFilledImg className='green-filled' /> :
                            <StartImg  />
                        }
                        {STRINGS.LANDFILLS}
                    </div>
                </div>
            </div>

            {selectedOption === STRINGS.MATERIAL_SEARCH ?
                <MaterialSearchHistory /> : <LandFillsSearchHistory />}

        </>
    )
}

export default HistoryForMaterialSearch;