import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import NativeSelect from '@mui/material/NativeSelect';
import { SUBMIT_USER_DETAILS } from '../../services';
import { ReactComponent as RightImg } from '../../assests/successCorrect.svg'
import { decryptResponse } from '../../utils/encryptionDecryption';
import STRINGS from '../../helpers/staticContentHelper';

const LIST_OF_STATES = [
    "Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado",
    "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois",
    "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland",
    "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana",
    "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York",
    "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania",
    "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah",
    "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming",
    "Washington D.C.", "Alberta", "British Columbia", "Manitoba", "New Brunswick",
    "Newfoundland", "Nova Scotia", "Nunavut", "Ontario", "Prince Edward Island",
    "Quebec", "Saskatchewan", "Yukon", "Other"
]

const DROPDOWN_INPUT_STYLE = {
    fontFamily: 'Satoshi',
    color: 'white', // Text color
    backgroundColor: 'transparent', // Transparent background
    borderBottom: '1px solid gray', // Underline color
    '&:hover:not(.Mui-disabled):before': {
        borderBottom: '1x solid gray', // Hover underline color
    },
    '&:after': {
        borderBottom: '0px solid gray', // Focused underline color
    },
    '& .MuiNativeSelect-icon': {
        color: 'gray', // Arrow icon color
    },
}
const BASE_INPUT_STYLE = {
    "& .MuiInputBase-root": {
        color: "white", // Text color
        backgroundColor: "transparent" // Background color
    },
    "& .MuiInputBase-root.Mui-disabled": {
        color: "grey", // Text color for disabled state
        backgroundColor: "transparent" // Background color for disabled state
    },
    "& .MuiFilledInput-underline:before": {
        borderBottomColor: "gray", // Default underline color
    },
    "& .MuiFilledInput-underline:hover:not(.Mui-disabled):before": {
        borderBottomColor: "gray", // Hover underline color
    },
    "& .MuiFilledInput-underline:after": {
        borderBottomColor: "transparent", // Focused underline color
    },
}

export const handleValidation = (data) => {
    const errorValue = {
        isError: false,
        first_name: '',
        last_name: '',
        email: '',
        business_name: '',
        phone: '',
        state: '',
    }
    Object.entries(data).forEach(([key, value]) => {
        if (!value) {
            errorValue[key] = 'filed is required'
            errorValue.isError = true
        } else if (key === 'email') {
            const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
            if (!re.test(value)) {
                errorValue[key] = 'please enter valid email address';
                errorValue.isError = true
            }
        } else if (key === 'phone' && value.length !== 11) {
            errorValue[key] = 'please enter valid phone number';
            errorValue.isError = true
        }
    });
    return errorValue
}

function UserForm({ isTriggeredFromMain = true }) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [formData, setFormData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        business_name: '',
        phone: '',
        state: ''
    })
    const [error, setError] = useState({
        isError: false,
        first_name: '',
        last_name: '',
        email: '',
        business_name: '',
        phone: '',
        state: ''
    })

    const onChangeFormData = (name, value) => {
        setFormData(prev => {
            return { ...prev, [name]: value }
        })
        if (value) {
            setError(prev => {
                return { ...prev, [name]: '' }
            })
        } else {
            setError(prev => {
                return { ...prev, [name]: 'field is required' }
            })
        }
    }

    const handleOrgFormSubmit = async () => {
        const errorValues = handleValidation(formData)
        setError(errorValues)
        if (!errorValues.isError) {
            setIsLoading(true)
            const res = await SUBMIT_USER_DETAILS({ ...formData, triggered_from: isTriggeredFromMain ? 'MAIN' : 'LIMIT REACHED' });
            const decryptedData = JSON.parse(decryptResponse(res))
            console.log(decryptedData)
            setIsLoading(false)
            setIsSuccess(true)
        }
    }

    return (
        <>
            {!isSuccess ?
                <div className='pricing-form-details'>
                    <div className='flex-input-box-between'>
                        <div style={{ flex: 1 }} >
                            <Typography className='input-label'>
                                First name*
                            </Typography>
                            <TextField
                                autoComplete="off"
                                hiddenLabel
                                id="filled-hidden-label-small"
                                defaultValue=""
                                variant="filled"
                                size="small"
                                placeholder='e.g. John'
                                fullWidth
                                sx={BASE_INPUT_STYLE}
                                onChange={(e) => onChangeFormData('first_name', e.target.value)}
                            />
                            {(error.isError && error.first_name) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.first_name}
                                </span>
                            }
                        </div>
                        <div style={{ flex: 1 }}>
                            <Typography className='input-label'>
                                Last name*
                            </Typography>
                            <TextField
                                autoComplete="off"
                                hiddenLabel
                                id="filled-hidden-label-small"
                                defaultValue=""
                                variant="filled"
                                size="small"
                                placeholder='e.g. Doe'
                                fullWidth
                                sx={BASE_INPUT_STYLE}
                                onChange={(e) => onChangeFormData('last_name', e.target.value)}
                            />
                            {(error.isError && error.last_name) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.last_name}
                                </span>
                            }
                        </div>
                    </div>

                    <div style={{ marginTop: '2rem' }} className='flex-input-box-between'>
                        <div style={{ flex: 1 }} >
                            <Typography className='input-label'>
                                Business name*
                            </Typography>
                            <TextField
                                autoComplete="off"
                                hiddenLabel
                                id="filled-hidden-label-small"
                                defaultValue=""
                                variant="filled"
                                size="small"
                                placeholder='e.g. Acme Co'
                                fullWidth
                                sx={BASE_INPUT_STYLE}
                                onChange={(e) => onChangeFormData('business_name', e.target.value)}
                            />
                            {(error.isError && error.business_name) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.business_name}
                                </span>
                            }
                        </div>
                    </div>

                    <div style={{ marginTop: '2rem' }} className='flex-input-box-between'>
                        <div style={{ flex: 1 }} >
                            <Typography className='input-label'>
                                Email*
                            </Typography>
                            <TextField
                                autoComplete="off"
                                hiddenLabel
                                id="filled-hidden-label-small"
                                defaultValue=""
                                variant="filled"
                                size="small"
                                placeholder='e.g. john@acme.co'
                                fullWidth
                                sx={BASE_INPUT_STYLE}
                                onChange={(e) => onChangeFormData('email', e.target.value)}
                            />
                            {(error.isError && error.email) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.email}
                                </span>
                            }
                        </div>
                    </div>
                    <div style={{ marginTop: '2rem' }} className='flex-input-box-between'>
                        <div style={{ flex: 1 }} >
                            <Typography className='input-label'>
                                State*
                            </Typography>
                            <NativeSelect
                                inputProps={{
                                    name: 'age',
                                    id: 'uncontrolled-native',
                                }}
                                fullWidth
                                sx={DROPDOWN_INPUT_STYLE}
                                value={formData.state}
                                onChange={(e) => {
                                    onChangeFormData('state', e.target.value)
                                }}
                            >
                                <option value="" disabled>
                                    Select one...
                                </option>
                                {LIST_OF_STATES.map(stateName => {
                                    return <option value={stateName}>{stateName}</option>
                                })}

                            </NativeSelect>
                            {(error.isError && error.state) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.state}
                                </span>
                            }
                        </div>
                    </div>

                    <div style={{ marginTop: '2rem' }} className='flex-input-box-between'>
                        <div style={{ flex: 1 }} >
                            <Typography className='input-label'>
                                Phone number*
                            </Typography>
                            <PhoneInput
                                country={'us'}
                                // value={}
                                onChange={phone => {
                                    onChangeFormData('phone', phone)
                                }}
                                onlyCountries={['us']}
                                inputStyle={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    border: 'none',
                                    borderBottom: '1.5px solid gray',
                                    outline: 'none',
                                    borderRadius: '0px',
                                    width: '100%'
                                }}
                                buttonStyle={{
                                    backgroundColor: 'transparent',
                                    color: 'white',
                                    border: 'none',
                                }}
                            />

                            {(error.isError && error.phone) &&
                                <span style={{ fontSize: '12px', color: 'red', display: "flex", justifyContent: "start", padding: "0.3rem 0rem" }}>
                                    {error.phone}
                                </span>
                            }
                        </div>
                    </div>

                    <div style={{ flex: 1, display: 'flex', justifyContent: 'space-between', alignItems: "center", marginTop: '20px', marginTop: "2rem" }}>
                        <button disabled={isLoading} onClick={() => handleOrgFormSubmit()} className='button-secondary-web' style={{ height: "45px", width: '150px', borderRadius: "0.3rem" }}>
                            {isLoading ? 'Please wait...' : 'Submit'}
                        </button>
                    </div>
                </div>
                :
                <div style={{ display: 'flex', flex: 1, justifyContent: "center", minHeight: "500px", alignItems: 'center', height: "100%", flexDirection: 'column' }}>
                    <RightImg height={'120px'} />
                    <p style={{ fontWeight: 700, margin: '0px', fontSize: "2.2rem", color: "white", marginBottom: "0.3rem" }}>{STRINGS.THANK_YOU}</p>
                    <span style={{ color: "white", fontWeight: 300, lineHeight: '1.8rem' }}>{STRINGS.YOUR_MESSAGE_HAS_BEEN_SUBMITTED}</span>
                    <span style={{ color: "white", fontWeight: 300, lineHeight: '1.1rem' }}>{STRINGS.WE_WILL_GET_BACK_TO_YOU_SOON}</span>
                </div>
            }
        </>
    )
}

export default UserForm;