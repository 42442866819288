import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment/moment';
import { decryptResponse } from '../../../utils/encryptionDecryption.js';
import TableSkeletonLoader from '../../materialSearch/components/tableSkeletonLoader.js';
import { GET_BLACKLISTED_LANDFILL_LIST } from '../../../services/user.services.js';
import LandfillUndoList from './undoBlackList.js';
import { ReactComponent as RIghtArrow } from '../../../assests/right.svg';
import { ReactComponent as BlockListedMaterial } from "../../materialSearch/assets/blocked.svg";
import { LStorage } from '../../../utils/localStorage.Helper.js';
import DetailModal from '../../materialSearch/components/detailModal.js';
import STRINGS from '../../../helpers/staticContentHelper.js';

function LandfillBlackList({ type }) {
    const userData = LStorage.getUserData()
    const [isLoading, setIsLoading] = useState(true);
    const [tableData, setTableData] = useState([]);
    const [selectedCarddata, setSelectedCardData] = useState({});
    const [openUndoPopUp, setOpenUndoPopUp] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

    const getListOfHistroty = async () => {
        try {
            setIsLoading(true)
            let res = await GET_BLACKLISTED_LANDFILL_LIST(!userData.org_id ? 'individual' : type);
            const parsedResponse = JSON.parse(decryptResponse(res))
            setIsLoading(false)
            setTableData(prev => {
                return parsedResponse
            })
            console.log(parsedResponse)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getListOfHistroty()
    }, [type])

    const columns = [
        {
            id: 'created_at', label: 'DATE', align: 'start', minWidth: "110px", format: (value, row) => {
                return <span style={{ fontSize: '14px', fontWeight: "500", fontFamily: 'Plus Jakarta Sans' }}>{moment(value).format('DD/MM/YYYY')}</span>
            }
        },
        {
            id: 'landfill_name', label: 'LANDFILL NAME', align: 'start', minWidth: "110px", format: (value, row) => {
                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div className='material-search-address-table-view'>
                            <p>{value?.substring(0, 20)} {value?.length > 20 && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'address', label: 'ADDRESS', align: 'start', format: (value, row) => {
                let supplier = row?.landfill_address;
                return (
                    <div style={{ display: 'flex', gap: '1rem' }}>
                        <div className='material-search-address-table-view'>
                            <span>Supplier:</span>
                            <p>{supplier?.substring(0, 25)} {supplier?.length > 25 && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'Vendor_marked_blcklisted_by', label: 'MARKED BY', align: 'start', format: (value, row) => {
                return (
                    <div className='material-search-address-table-view'>
                        <p>{row.user.email}</p>
                    </div>
                )
            }
        },
        {
            id: 'materials', label: 'MATERIALS', align: 'start',
            format: (value, row) => {
                const stringToRender = row?.materials_accepted?.slice(0, 2).join(", ");
                let remainingFilters = row?.materials_accepted?.length;

                if (stringToRender?.length > 22) {
                    remainingFilters++
                }

                if (row.blacklist_facility) {
                    return (
                        <div style={{ display: 'flex', flexDirection: "row", gap: "0.5rem" }}>
                            <div className='material-search-address-table-view'>
                                <p>All materials</p>
                            </div>
                        </div>
                    )
                }

                return (
                    <div style={{ display: 'flex', flexDirection: "row", gap: "0.5rem" }}>
                        <div className='material-search-address-table-view'>
                            <p>{stringToRender?.substring(0, 22)} {(stringToRender?.length > 22 || remainingFilters > 2) && '...'}</p>
                        </div>
                        {remainingFilters > 2 &&
                            <div className='materials-more-chip'>+{remainingFilters - 2}</div>
                        }
                    </div>
                )
            }
        },
        {
            id: 'remarks', label: 'REASON', align: 'start', format: (value, row) => {
                return (
                    <div style={{ display: 'flex', flexDirection: "row", gap: "0.5rem" }}>
                        <div className='material-search-address-table-view'>
                            <p>{value?.substring(0, 22)} {(value?.length > 22) && '...'}</p>
                        </div>
                    </div>
                )
            }
        },
        {
            id: 'action', label: '', align: 'start',
            format: (value, row) => {
                return <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: '1.2rem' }}>
                    {userData.email === row.user.email &&
                        <div style={{ textDecoration: "underline", color: "#006D6B" }} onClick={(e) => {
                            e.stopPropagation()
                            setOpenUndoPopUp(true)
                            setSelectedCardData(row)
                        }}>
                            <a>{STRINGS.EDIT}</a>
                        </div>
                    }
                    <div className='location-card-options'
                        style={{ backgroundColor: "#FFDDD5", display: "inline-flex" }} >
                        <BlockListedMaterial />
                    </div>
                    <RIghtArrow />
                </div>
            }
        },
    ];

    return (
        <>
            <DetailModal isBlackList={true} setIsModalOpen={setIsDetailsModalOpen} isModalOpen={isDetailsModalOpen} carddata={selectedCarddata} />
            {openUndoPopUp && <LandfillUndoList type={type} fetchList={getListOfHistroty} open={openUndoPopUp} setOpen={setOpenUndoPopUp} cardData={selectedCarddata} isBlackListPopup={true} />}
            {isLoading ? <TableSkeletonLoader /> :
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{
                                            width: column.width,
                                            minWidth: column.minWidth,
                                            padding: "0.4rem 0.1rem",
                                            color: '#6D6D6D',
                                            fontFamily: 'Plus Jakarta Sans'
                                        }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData.length > 0 && tableData.map((row) => {
                                return (
                                    <TableRow hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.code}
                                        style={{ cursor: "pointer", height: "62px" }}
                                        onClick={() => {
                                            setIsDetailsModalOpen(prev => !prev)
                                            setSelectedCardData(row)
                                        }}
                                    >
                                        {columns.map((column) => {
                                            const value = row[column.id];
                                            return (
                                                <TableCell key={column.id} align={column.align}
                                                    sx={{
                                                        position: 'relative', padding: '0.5rem 0.1px',
                                                        paddingRight: '1rem',
                                                        fontFamily: 'Plus Jakarta Sans',
                                                        maxWidth: column.maxWidth && column.maxWidth
                                                    }}>
                                                    {column.format
                                                        ? column.format(value, row)
                                                        : value}

                                                </TableCell>

                                            );
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            }
        </>
    )
}

export default LandfillBlackList

